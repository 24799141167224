import {Item} from '@PosterWhiteboard/items/item/item.class';
import QRCode from 'qrcode';
import type {RGBA} from '@Utils/color.util';
import {rgbaToHexString} from '@Utils/color.util';
import {ElementDataType} from '@Libraries/add-media-library';
import {openPosterEditorQRContentModal} from '@Modals/poster-editor-qr-content-modal';
import type {FabricObject, Group} from '@postermywall/fabricjs-2';
import {loadSVGFromString, util} from '@postermywall/fabricjs-2';
import type {BaseItemObject, InitItemOpts} from './item/item.types';
import {ITEM_TYPE} from './item/item.types';
import type {
  CopyableItemStylesAndProperties,
  QRCodeItemStyles,
} from '@Components/poster-editor/components/poster-editing-side-panel/components/poster-item-controls/poster-item-controls.types';
import {pasteStylesForQrCodeItem} from '@PosterWhiteboard/libraries/paste-styles.library';

export interface QRCodeItemObject extends BaseItemObject {
  message: string;
  qrForegroundColor: RGBA;
  qrBackgroundColor: RGBA;
  isBackgroundTransparent: boolean;
}

export const DEFAULT_QR_MESSAGE = 'https://www.postermywall.com';
export const DEFAULT_QR_MAX_LENGTH = 1400;

export class QRCodeItem extends Item {
  public message = DEFAULT_QR_MESSAGE;
  public qrForegroundColor: RGBA = [0, 0, 0, 1];
  public qrBackgroundColor: RGBA = [255, 255, 255, 1];
  public isBackgroundTransparent = true;
  public gitype: ITEM_TYPE.QR_CODE = ITEM_TYPE.QR_CODE;

  protected itemObjectHasDestructiveChanges = (oldItemObject: QRCodeItemObject): boolean => {
    return (
      oldItemObject.message !== this.message ||
      oldItemObject.qrForegroundColor !== this.qrForegroundColor ||
      oldItemObject.isBackgroundTransparent !== this.isBackgroundTransparent ||
      oldItemObject.qrBackgroundColor !== this.qrBackgroundColor
    );
  };

  public getCopyableStyles(): CopyableItemStylesAndProperties {
    return {
      ...super.getCopyableStyles(),
      qrForegroundColor: this.qrForegroundColor,
      qrBackgroundColor: this.qrBackgroundColor,
      isBackgroundTransparent: this.isBackgroundTransparent,
    } as QRCodeItemStyles;
  }
  public async pasteStyles(copiedProperties: CopyableItemStylesAndProperties): Promise<void> {
    await pasteStylesForQrCodeItem(copiedProperties, this);
  }

  public async isPDFSafe(): Promise<boolean> {
    return true;
  }

  public hasClickableLink(): boolean {
    return false;
  }

  public toObject(): QRCodeItemObject {
    return {
      ...super.toObject(),
      message: this.message,
      qrForegroundColor: this.qrForegroundColor,
      qrBackgroundColor: this.qrBackgroundColor,
      isBackgroundTransparent: this.isBackgroundTransparent,
    };
  }

  public updateForegroundColor = (color: RGBA, undoable = true): void => {
    void this.updateFromObject(
      {
        qrForegroundColor: color,
      },
      {
        undoable,
      }
    );
  };

  public updateMessageContent = (updatedValue: string, undoable = true): void => {
    void this.updateFromObject(
      {
        message: updatedValue,
      },
      {
        undoable,
      }
    );
  };

  public toggleBackgroundStateForQRItem = (): void => {
    void this.updateFromObject({
      isBackgroundTransparent: !this.isBackgroundTransparent,
    });
  };

  public updateQRBackgroundColor = (color: RGBA, undoable = true): void => {
    void this.updateFromObject(
      {
        qrBackgroundColor: color,
      },
      {
        undoable,
      }
    );
  };

  protected async getFabricObjectForItem(opts: InitItemOpts = {}): Promise<Group | FabricObject> {
    const qrCodeString = await QRCode.toString(this.message, {
      type: 'svg',
      width: 256,
      errorCorrectionLevel: 'medium',
      margin: 0,
      color: {
        light: this.isBackgroundTransparent ? '#FFFFFF01' : rgbaToHexString(this.qrBackgroundColor),
        dark: rgbaToHexString(this.qrForegroundColor),
      },
    });
    const svgParsed = await loadSVGFromString(qrCodeString);
    if (!svgParsed.objects) {
      throw new Error(`Failed to load svg for string ${this.message}`);
    }

    const objectsWithoutNulls = svgParsed.objects.filter((item) => {
      return item !== null;
    });
    const obj = util.groupSVGElements(objectsWithoutNulls, svgParsed.options);
    obj.set({
      ...this.getCommonOptions(),
      scaleX: opts?.scaleX ?? 1,
      scaleY: opts?.scaleY ?? 1,
    });
    return obj;
  }

  protected onItemDoubleTapped(): void {
    openPosterEditorQRContentModal();
  }
}

export const addQrCodeToPage = (): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }

  const opts = {message: window.posterEditor?.config?.businessProfileUrl ?? DEFAULT_QR_MESSAGE};
  void currentPage.items.addItems.addQRItem(
    {
      type: ElementDataType.QR_CODE,
    },
    opts
  );
};
