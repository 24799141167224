import type {ReactElement} from 'react';
import React from 'react';
import {useSingleActivePosterItem} from '@Hooks/poster-editor/useSingleActivePosterItem';
import {CustomItemControlMt} from '@Components/poster-editor/components/custom-item-control-mt/custom-item-control-mt';
import {CustomItemControlMl} from '@Components/poster-editor/components/custom-item-control-ml/custom-item-control-ml';
import {CustomItemControlMr} from '@Components/poster-editor/components/custom-item-control-mr/custom-item-control-mr';
import {CustomItemControlMb} from '@Components/poster-editor/components/custom-item-control-mb/custom-item-control-mb';
import {CustomSlideshowControlsWrapper} from '@Components/poster-editor/components/custom-slideshow-controls-wrapper/custom-slideshow-controls-wrapper';
import {CustomReplaceControl} from '@Components/poster-editor/components/custom-replace-control/custom-replace-control';
import {CustomEditControl} from '@Components/poster-editor/components/custom-edit-control/custom-edit-control';

export function SingleItemCustomControls(): ReactElement {
  const activeItem = useSingleActivePosterItem();

  const getSingleItemControls = (): ReactElement | null => {
    if (activeItem && !activeItem.isLocked()) {
      return (
        <>
          <CustomItemControlMt />
          <CustomItemControlMl />
          <CustomItemControlMr />
          <CustomItemControlMb />

          {activeItem.isSlideshow() ? <CustomSlideshowControlsWrapper /> : null}
          {activeItem.isImage() ? <CustomReplaceControl /> : null}
          {activeItem.isTable() || activeItem.isMenu() ? <CustomEditControl /> : null}
        </>
      );
    } else {
      if (activeItem?.isLocked()) {
        if (activeItem.isSlideshow()) {
          return <CustomSlideshowControlsWrapper />;
        }
        if (activeItem.isImage()) {
          return <CustomReplaceControl />;
        }
        if (activeItem.isTable() || activeItem.isMenu()) {
          return <CustomEditControl />;
        }
      }
      return null;
    }
  };

  return <>{getSingleItemControls()}</>;
}
