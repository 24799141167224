import type {ItemType} from '@PosterWhiteboard/items/item/item.types';
import {degreesToRadians, radiansToDegrees} from '@Utils/math.util';
import type {FabricObject} from '@postermywall/fabricjs-2';
import {ActiveSelection} from '@postermywall/fabricjs-2';
import type {Page} from '@PosterWhiteboard/page/page.class';
import {CUSTOM_CONTROLS_WRAPPER} from '@Components/poster-editor/components/custom-item-controls/custom-item-controls.types';

export enum CustomCornerControl {
  TL = 'tl',
  TR = 'tr',
  BL = 'bl',
  BR = 'br',
}

export const hideCustomControls = (): void => {
  const controlsWrapper = document.getElementById(CUSTOM_CONTROLS_WRAPPER);
  if (!controlsWrapper) {
    return;
  }

  controlsWrapper.style.visibility = 'hidden';

  controlsWrapper.querySelectorAll('*').forEach((element) => {
    (element as HTMLElement).style.visibility = 'hidden';
  });
};

export const unhideCustomControls = (): void => {
  const controlsWrapper = document.getElementById(CUSTOM_CONTROLS_WRAPPER);
  if (!controlsWrapper) {
    return;
  }

  controlsWrapper.style.visibility = 'visible';

  controlsWrapper.querySelectorAll('*').forEach((element) => {
    (element as HTMLElement).style.visibility = 'visible';
  });
};

export const doesSelectedItemHaveCustomTopBottomHandlesResizing = (selectedItem: ItemType): boolean => {
  return selectedItem.isText() || selectedItem.isSlideshow();
};

export const doesSelectedItemHaveCustomLeftRightHandlesResizing = (selectedItem: ItemType): boolean => {
  return selectedItem.isText() || selectedItem.isTranscript() || selectedItem.isSlideshow() || selectedItem.isMenu();
};

export const doesSelectedItemHaveLeftRightResizeControls = (selectedItem: ItemType): boolean => {
  return !(selectedItem.isQRItem() || selectedItem.isTable() || selectedItem.isTab() || selectedItem.isSchedule() || selectedItem.isFancyText());
};

export const doesSelectedItemHaveTopBottomResizeControls = (selectedItem: ItemType): boolean => {
  return !(
    selectedItem.isQRItem() ||
    selectedItem.isTable() ||
    selectedItem.isTab() ||
    selectedItem.isMenu() ||
    selectedItem.isSchedule() ||
    selectedItem.isTranscript() ||
    selectedItem.isFancyText()
  );
};

export const getAngleBetweenFabricObjectCenterAndCursor = (
  e: globalThis.PointerEvent,
  fabricObject: FabricObject,
  currentPage: Page,
  horizontalScroll: number,
  verticalScroll: number
): number => {
  const currentCursorCanvasX = e.clientX - (fabricObject.canvas?._offset.left ?? 0) - horizontalScroll;
  const currentCursorCanvasY = e.clientY - (fabricObject.canvas?._offset.top ?? 0) - verticalScroll;

  const fabricObjectCenterX = fabricObject.getCenterPoint().x * currentPage.poster.scaling.scale;
  const fabricObjectCenterY = fabricObject.getCenterPoint().y * currentPage.poster.scaling.scale;

  return -1 * radiansToDegrees(Math.atan2(fabricObjectCenterX - currentCursorCanvasX, fabricObjectCenterY - currentCursorCanvasY));
};

export const scaleFromCorner = (
  e: globalThis.PointerEvent,
  oldX: number,
  oldY: number,
  currentPage: Page,
  activeSelection: FabricObject,
  cornerControl: CustomCornerControl
): boolean => {
  let scaleProportionally = true;
  let hasUniformStroke = true;

  if (!(activeSelection instanceof ActiveSelection)) {
    const item = currentPage.items.getItemForFabricObject(activeSelection);
    if (item?.isRectangle()) {
      scaleProportionally = e.shiftKey;
    } else if (item?.fabricObject.strokeUniform === false) {
      hasUniformStroke = false;
    }
  }

  const {deltaWidth, deltaHeight} = getDeltaWidthAndHeightForCornerControl(e, oldX, oldY, currentPage, activeSelection, cornerControl, scaleProportionally);

  const initialScaledWidth = activeSelection.getScaledWidth() - activeSelection.strokeWidth * (!hasUniformStroke ? activeSelection.scaleX : 1);
  const initialScaledHeight = activeSelection.getScaledHeight() - activeSelection.strokeWidth * (!hasUniformStroke ? activeSelection.scaleY : 1);

  const finalWidth = initialScaledWidth + deltaWidth;
  const finalHeight = initialScaledHeight + deltaHeight;

  if (finalWidth <= 0 || finalHeight <= 0) {
    return false;
  }

  const finalScaleX = finalWidth / activeSelection.width;
  const finalScaleY = finalHeight / activeSelection.height;

  if (cornerControl === CustomCornerControl.BR) {
    activeSelection.set({
      scaleX: finalScaleX,
      scaleY: finalScaleY,
    });
  } else {
    const {left, top} = getNewLeftAndTopAfterScalingWithCornerControl(activeSelection, deltaWidth, deltaHeight, cornerControl);
    activeSelection.set({
      top: top,
      left: left,
      scaleX: finalScaleX,
      scaleY: finalScaleY,
    });
  }

  activeSelection.setCoords();

  if (activeSelection instanceof ActiveSelection) {
    const fabricObjects = activeSelection.getObjects();
    for (const fabricObject of fabricObjects) {
      const item = currentPage.items.getItemForFabricObject(fabricObject);
      if (item) {
        item.onScaling();
      }
    }
  } else {
    const item = currentPage.items.getItemForFabricObject(activeSelection);
    if (item) {
      item.onScaling();
    }
  }

  currentPage.fabricCanvas.requestRenderAll();

  return true;
};

export const defaultOnSingleItemResizeHandleDragEnded = async (activeItem: ItemType): Promise<void> => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }

  await activeItem.updateFromObject(
    {
      x: activeItem.fabricObject.getX(),
      y: activeItem.fabricObject.getY(),
      scaleX: activeItem.fabricObject.getObjectScaling().x,
      scaleY: activeItem.fabricObject.getObjectScaling().y,
    },
    {undoable: false}
  );

  currentPage.poster.history.addPosterHistory();
};

export const onScaleFromCornerEnded = async (): Promise<void> => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }

  const activeSelection = currentPage.activeSelection.getActiveObject();
  if (!activeSelection) {
    return;
  }

  const updateFromObjectPromises = [];

  if (activeSelection instanceof ActiveSelection) {
    const fabricObjects = activeSelection.getObjects();
    for (const fabricObject of fabricObjects) {
      const item = currentPage.items.getItemForFabricObject(fabricObject);
      if (item) {
        updateFromObjectPromises.push(
          item.updateFromObject(
            {
              x: item.fabricObject.left,
              y: item.fabricObject.top,
              scaleX: item.fabricObject.scaleX,
              scaleY: item.fabricObject.scaleY,
            },
            {undoable: false}
          )
        );
      }
    }
  } else {
    const item = currentPage.items.getItemForFabricObject(activeSelection);
    if (item) {
      updateFromObjectPromises.push(
        item.updateFromObject(
          {
            x: item.fabricObject.getX(),
            y: item.fabricObject.getY(),
            scaleX: item.fabricObject.getObjectScaling().x,
            scaleY: item.fabricObject.getObjectScaling().y,
          },
          {undoable: false}
        )
      );
    }
  }

  await Promise.all(updateFromObjectPromises);

  currentPage.poster.history.addPosterHistory();
};

//CodeReviewTaimurDone: ESlint erros
export function getDeltaWidthForMiddleControl(newX: number, oldX: number, newY: number, oldY: number, pageScale: number, angleRadians: number): number {
  const deltaX = newX - oldX;
  const deltaY = newY - oldY;

  const deltaWx = (deltaX * Math.cos(angleRadians)) / pageScale;
  const deltaWy = (deltaY * Math.sin(angleRadians)) / pageScale;

  return deltaWx + deltaWy;
}

export function getDeltaHeightForMiddleControl(newX: number, oldX: number, newY: number, oldY: number, pageScale: number, angleRadians: number): number {
  const deltaX = newX - oldX;
  const deltaY = newY - oldY;

  const deltaHx = (deltaX * Math.cos(angleRadians + degreesToRadians(90))) / pageScale;
  const deltaHy = (deltaY * Math.sin(angleRadians + degreesToRadians(90))) / pageScale;

  return deltaHx + deltaHy;
}

export function getDeltaWidthAndHeightForCornerScalingControl(
  newX: number,
  oldX: number,
  newY: number,
  oldY: number,
  pageScale: number,
  angleDegrees: number,
  scaledWidth: number,
  scaledHeight: number,
  directionX: 1 | -1 = 1,
  directionY: 1 | -1 = 1,
  scaleProportionally = true
): {deltaWidth: number; deltaHeight: number} {
  let xFactor = Math.cos(degreesToRadians(angleDegrees)) - Math.sin(degreesToRadians(angleDegrees));
  let yFactor = Math.sin(degreesToRadians(90 + angleDegrees)) - Math.cos(degreesToRadians(90 + angleDegrees));

  if (directionY === -1 && directionX === 1) {
    xFactor = Math.sin(degreesToRadians(90 + angleDegrees)) - Math.cos(degreesToRadians(90 + angleDegrees));
    yFactor = Math.cos(degreesToRadians(angleDegrees)) - Math.sin(degreesToRadians(angleDegrees));
  }

  if (directionX === -1 && directionY === 1) {
    xFactor = Math.sin(degreesToRadians(90 + angleDegrees)) - Math.cos(degreesToRadians(90 + angleDegrees));
    yFactor = Math.cos(degreesToRadians(angleDegrees)) - Math.sin(degreesToRadians(angleDegrees));
  }

  const deltaX = ((newX - oldX) / pageScale) * directionX;
  const deltaY = ((newY - oldY) / pageScale) * directionY;

  if (!scaleProportionally) {
    const newWidthDueToDeltaX = scaledWidth - deltaX * xFactor;
    const deltaWidthX = newWidthDueToDeltaX - scaledWidth;

    const newHeightDueToDeltaY = scaledHeight - deltaY * yFactor;
    const deltaHeightY = newHeightDueToDeltaY - scaledHeight;

    return {
      deltaWidth: deltaWidthX,
      deltaHeight: deltaHeightY,
    };
  }

  const aspectRatio = scaledWidth / scaledHeight;
  const baseScaleFactor = aspectRatio / (aspectRatio + 1);

  const newWidthDueToDeltaX = scaledWidth - deltaX * baseScaleFactor * xFactor;
  const newHeightDueToDeltaX = newWidthDueToDeltaX / aspectRatio;
  const deltaWidthX = newWidthDueToDeltaX - scaledWidth;
  const deltaHeightX = newHeightDueToDeltaX - scaledHeight;

  const newWidthDueToDeltaY = scaledWidth - deltaY * baseScaleFactor * yFactor;
  const newHeightDueToDeltaY = newWidthDueToDeltaY / aspectRatio;
  const deltaWidthY = newWidthDueToDeltaY - scaledWidth;
  const deltaHeightY = newHeightDueToDeltaY - scaledHeight;

  const deltaWidthTotal = deltaWidthX + deltaWidthY;
  const deltaHeightTotal = deltaHeightX + deltaHeightY;

  return {
    deltaWidth: deltaWidthTotal,
    deltaHeight: deltaHeightTotal,
  };
}

const getDeltaWidthAndHeightForCornerControl = (
  e: globalThis.PointerEvent,
  oldX: number,
  oldY: number,
  currentPage: Page,
  activeSelection: FabricObject,
  cornerControl: CustomCornerControl,
  scaleProportionally = true
): {deltaWidth: number; deltaHeight: number} => {
  switch (cornerControl) {
    case CustomCornerControl.BL:
      return getDeltaWidthAndHeightForCornerScalingControl(
        e.clientX,
        oldX,
        e.clientY,
        oldY,
        currentPage.poster.scaling.scale,
        activeSelection.getTotalAngle(),
        activeSelection.getScaledWidth(),
        activeSelection.getScaledHeight(),
        1,
        -1,
        scaleProportionally
      );
    case CustomCornerControl.BR: {
      return getDeltaWidthAndHeightForCornerScalingControl(
        e.clientX,
        oldX,
        e.clientY,
        oldY,
        currentPage.poster.scaling.scale,
        activeSelection.getTotalAngle(),
        activeSelection.getScaledWidth(),
        activeSelection.getScaledHeight(),
        -1,
        -1,
        scaleProportionally
      );
    }
    case CustomCornerControl.TL:
      return getDeltaWidthAndHeightForCornerScalingControl(
        e.clientX,
        oldX,
        e.clientY,
        oldY,
        currentPage.poster.scaling.scale,
        activeSelection.getTotalAngle(),
        activeSelection.getScaledWidth(),
        activeSelection.getScaledHeight(),
        1,
        1,
        scaleProportionally
      );
    case CustomCornerControl.TR:
      return getDeltaWidthAndHeightForCornerScalingControl(
        e.clientX,
        oldX,
        e.clientY,
        oldY,
        currentPage.poster.scaling.scale,
        activeSelection.getTotalAngle(),
        activeSelection.getScaledWidth(),
        activeSelection.getScaledHeight(),
        -1,
        1,
        scaleProportionally
      );
    default:
      throw new Error(`Unhandled corner control ${cornerControl}`);
  }
};

const getNewLeftAndTopAfterScalingWithCornerControl = (
  activeSelection: FabricObject,
  deltaWidth: number,
  deltaHeight: number,
  cornerControl: CustomCornerControl
): {left: number; top: number} => {
  switch (cornerControl) {
    case CustomCornerControl.TR: {
      const finalLeft = activeSelection.getX() + deltaHeight * Math.sin(degreesToRadians(activeSelection.getTotalAngle()));

      const finalTop = activeSelection.getY() - deltaHeight * Math.cos(degreesToRadians(activeSelection.getTotalAngle()));

      return {left: finalLeft, top: finalTop};
    }
    case CustomCornerControl.TL: {
      const finalLeft =
        activeSelection.getX() -
        deltaWidth * Math.cos(degreesToRadians(activeSelection.getTotalAngle())) +
        deltaHeight * Math.sin(degreesToRadians(activeSelection.getTotalAngle()));

      const finalTop =
        activeSelection.getY() -
        deltaHeight * Math.cos(degreesToRadians(activeSelection.getTotalAngle())) -
        deltaWidth * Math.sin(degreesToRadians(activeSelection.getTotalAngle()));

      return {left: finalLeft, top: finalTop};
    }
    case CustomCornerControl.BL: {
      const finalLeft = activeSelection.getX() - deltaWidth * Math.cos(degreesToRadians(activeSelection.getTotalAngle()));

      const finalTop = activeSelection.getY() - deltaWidth * Math.sin(degreesToRadians(activeSelection.getTotalAngle()));

      return {left: finalLeft, top: finalTop};
    }
    default:
      throw new Error(`Unhandled corner control ${cornerControl}`);
  }
};
