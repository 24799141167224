import type {ReactElement} from 'react';
import React from 'react';
import {CUSTOM_EDIT_CONTROL_ID, CUSTOM_EDIT_CONTROL_MARGIN, EDIT_CONTROL_HEIGHT, EDIT_CONTROL_WIDTH} from './custom-edit-control.types';
import {useSingleActivePosterItem} from '@Hooks/poster-editor/useSingleActivePosterItem';
import {useAppSelector} from '@/hooks';
import type {FabricObject} from '@postermywall/fabricjs-2';
import type {CustomItemControlPositions} from '@Components/poster-editor/components/custom-item-controls/components/single-item-custom-controls/single-item-custom-controls.types';
import {degreesToRadians} from '@Utils/math.util';
import {BaseCustomItemControl} from '@Components/poster-editor/components/custom-item-controls/components/base-custom-item-control/base-custom-item-control';
import {ControlType} from '@Components/poster-editor/components/custom-item-controls/components/base-custom-item-control/base-custom-item-control.types';

export function CustomEditControl(): ReactElement | null {
  const activeItem = useSingleActivePosterItem();

  const arePosterItemsMoving = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsMoving;
  });
  const arePosterItemsScaling = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsScaling;
  });
  const arePosterItemsRotating = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsRotating;
  });

  if (!activeItem) {
    return null;
  }

  if (!(activeItem.isTable() || activeItem.isMenu())) {
    return null;
  }

  const getEditButtonPosition = (fabricObject: FabricObject): CustomItemControlPositions => {
    const itemHeight = activeItem.fabricObject.getScaledHeight() * activeItem.page.poster.scaling.scale;
    const itemWidth = activeItem.fabricObject.getScaledWidth() * activeItem.page.poster.scaling.scale;

    const leftInitial = activeItem.fabricObject.getX() * activeItem.page.poster.scaling.scale;
    const topInitial = activeItem.fabricObject.getY() * activeItem.page.poster.scaling.scale;

    const xCorrectionDueToItemHeight = itemHeight * Math.sin(degreesToRadians(activeItem.fabricObject.getTotalAngle()));
    const xCorrectionDueToItemWidth = (itemWidth / 2) * Math.cos(degreesToRadians(activeItem.fabricObject.getTotalAngle()));

    const yCorrectionDueToItemHeight = itemHeight * Math.cos(degreesToRadians(activeItem.fabricObject.getTotalAngle()));
    const yCorrectionDueToItemWidth = (itemWidth / 2) * Math.sin(degreesToRadians(activeItem.fabricObject.getTotalAngle()));

    const marginX = CUSTOM_EDIT_CONTROL_MARGIN * Math.sin(degreesToRadians(fabricObject.getTotalAngle()));
    const marginY = CUSTOM_EDIT_CONTROL_MARGIN * Math.cos(degreesToRadians(fabricObject.getTotalAngle()));

    const angle = activeItem.fabricObject.getTotalAngle();
    const left = leftInitial - xCorrectionDueToItemHeight + xCorrectionDueToItemWidth - Math.floor(EDIT_CONTROL_WIDTH / 2) - marginX;
    const top = topInitial + yCorrectionDueToItemHeight + yCorrectionDueToItemWidth - Math.floor(EDIT_CONTROL_HEIGHT / 2) + marginY;

    return {left, top, angle};
  };

  const getEditButton = (): ReactElement | null => {
    const position = getEditButtonPosition(activeItem.fabricObject);

    return (
      <BaseCustomItemControl
        icon={'icon-pencil'}
        text={window.i18next.t('pmwjs_edit')}
        controlId={CUSTOM_EDIT_CONTROL_ID}
        top={position.top}
        left={position.left}
        angle={position.angle}
        borderRadius={'6px'}
        isVisible={!arePosterItemsMoving && !arePosterItemsRotating && !arePosterItemsScaling}
        onPointerDown={() => {
          activeItem.editItem();
        }}
        controlType={ControlType.CLICK}
      />
    );
  };

  if (arePosterItemsMoving || arePosterItemsRotating || arePosterItemsScaling) {
    return null;
  }

  return getEditButton();
}
