import type {CSSProperties, ReactElement} from 'react';
import React from 'react';
import type {PosterChangingOverlayProps} from '@Components/poster-whiteboard/components/poster-changing-overlay/poster-changing-overlay.types';
import style from './poster-changing-overlay.module.scss';

export default function PosterChangingOverlay({...props}: PosterChangingOverlayProps): ReactElement {
  const styles: CSSProperties = {};
  if (props.width) {
    styles.width = `${props.width}px`;
  }
  if (props.height) {
    styles.height = `${props.height}px`;
  }
  const getBackdrop = (): ReactElement | null => {
    if (props.backdropImage) {
      return (
        <div className="_full-width _full-height">
          <img className="_full-width _full-height" alt="Poster" src={props.backdropImage} />
        </div>
      );
    }
    return null;
  };
  return (
    <div className={props.className ?? ''} style={styles}>
      {props.backdropImage ? getBackdrop() : null}
      <div className={`${style.animation} _full-width _full-height`} />
    </div>
  );
}
