import type {PointerEvent} from 'react';
import type React from 'react';
import type {CustomControlCursor} from '@Components/poster-editor/components/custom-item-controls/custom-item-controls.types';

export const CUSTOM_CONTROL_MOBILE_TOUCH_AREA = 15;

export interface BaseCustomItemControlProps {
  controlId: string;
  top: number;
  left: number;
  width?: number;
  height?: number;
  angle: number;
  isVisible: boolean;
  cursor?: CustomControlCursor;
  borderRadius?: string;
  icon?: string;
  isDisabled?: boolean;
  text?: string;
  adjustForPosterScroll?: boolean;
  controlType?: ControlType;

  onPointerDown(e: PointerEvent | React.MouseEvent<HTMLDivElement>): void;
}

export enum ControlType {
  DRAG = 'drag',
  CLICK = 'click',
}
