import type {ReactElement} from 'react';
import React from 'react';
import {CUSTOM_SLIDESHOW_CONTROLS_WRAPPER_LEFT_OFFSET, CUSTOM_SLIDESHOW_CONTROLS_WRAPPER_TOP_OFFSET} from './custom-slideshow-controls-wrapper.types';
import styles from './custom-slideshow-controls-wrapper.module.scss';
import {useGetSlideShowItemWithSelectedSlide} from '@Hooks/poster-editor/useGetSlideshowItemWithSelectedSlide';
import type {FabricObject} from '@postermywall/fabricjs-2';
import type {CustomItemControlPositions} from '@Components/poster-editor/components/custom-item-controls/components/single-item-custom-controls/single-item-custom-controls.types';
import {degreesToRadians} from '@Utils/math.util';
import {CUSTOM_ITEM_CONTROL_WITH_ICON_SIDE} from '@Components/poster-editor/components/custom-item-controls/custom-item-controls.types';
import {BaseCustomItemControl} from '@Components/poster-editor/components/custom-item-controls/components/base-custom-item-control/base-custom-item-control';
import {useAppSelector} from '@/hooks';
import {ControlType} from '@Components/poster-editor/components/custom-item-controls/components/base-custom-item-control/base-custom-item-control.types';

const CUSTOM_PREVIOUS_SLIDE_CONTROL_ID = 'custom-previous-slide';
const CUSTOM_NEXT_SLIDE_CONTROL_ID = 'custom-next-slide';

export function CustomSlideshowControlsWrapper(): ReactElement | null {
  const {activeSlide, activeItem} = useGetSlideShowItemWithSelectedSlide();
  const arePosterItemsMoving = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsMoving;
  });
  const arePosterItemsScaling = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsScaling;
  });
  const arePosterItemsRotating = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsRotating;
  });
  const verticalScroll = useAppSelector((state) => {
    return state.posterEditor.scrollState.verticalScroll;
  });
  const horizontalScroll = useAppSelector((state) => {
    return state.posterEditor.scrollState.horizontalScroll;
  });

  if (!activeItem || !activeSlide) {
    return null;
  }

  const getSlideshowControlsWrapperPosition = (fabricObject: FabricObject, posterScale: number): CustomItemControlPositions => {
    const leftInitial = (fabricObject.getX() - fabricObject.getScaledHeight() * Math.sin(degreesToRadians(fabricObject.getTotalAngle()))) * posterScale;
    const topInitial = (fabricObject.getY() + fabricObject.getScaledHeight() * Math.cos(degreesToRadians(fabricObject.getTotalAngle()))) * posterScale;

    const angle = fabricObject.getTotalAngle();
    const left =
      leftInitial -
      CUSTOM_SLIDESHOW_CONTROLS_WRAPPER_LEFT_OFFSET * Math.cos(degreesToRadians(fabricObject.getTotalAngle())) -
      CUSTOM_SLIDESHOW_CONTROLS_WRAPPER_TOP_OFFSET * Math.sin(degreesToRadians(fabricObject.getTotalAngle()));
    const top =
      topInitial +
      CUSTOM_SLIDESHOW_CONTROLS_WRAPPER_TOP_OFFSET * Math.cos(degreesToRadians(fabricObject.getTotalAngle())) -
      CUSTOM_SLIDESHOW_CONTROLS_WRAPPER_LEFT_OFFSET * Math.sin(degreesToRadians(fabricObject.getTotalAngle()));

    return {left, top, angle};
  };

  const getSlideshowControlsWeapper = (): ReactElement | null => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return null;
    }

    const activeSelection = currentPage.activeSelection.getActiveObject();
    if (!activeSelection) {
      return null;
    }

    const position = getSlideshowControlsWrapperPosition(activeItem.fabricObject, currentPage.poster.scaling.scale);

    return (
      <div
        style={{
          top: `${position.top + verticalScroll}px`,
          left: `${position.left + horizontalScroll}px`,
          transformOrigin: `0 0`,
          rotate: `${position.angle}deg`,
        }}
        className={`flexbox ${styles.slideShowControlWrapper} _position-absolute`}
      >
        <BaseCustomItemControl
          adjustForPosterScroll={false}
          isDisabled={!activeItem.slides.hasPreviousSlide()}
          icon={'icon-slide-prev'}
          controlId={CUSTOM_PREVIOUS_SLIDE_CONTROL_ID}
          top={0}
          left={0}
          width={CUSTOM_ITEM_CONTROL_WITH_ICON_SIDE}
          height={CUSTOM_ITEM_CONTROL_WITH_ICON_SIDE}
          angle={0}
          borderRadius={'50%'}
          isVisible={!arePosterItemsMoving && !arePosterItemsRotating && !arePosterItemsScaling}
          onPointerDown={() => {
            activeItem.selectPreviousSlide();
          }}
          controlType={ControlType.CLICK}
        />
        <BaseCustomItemControl
          adjustForPosterScroll={false}
          isDisabled={!activeItem.slides.hasNextSlide()}
          icon={'icon-slide-next'}
          controlId={CUSTOM_NEXT_SLIDE_CONTROL_ID}
          top={0}
          left={35}
          width={CUSTOM_ITEM_CONTROL_WITH_ICON_SIDE}
          height={CUSTOM_ITEM_CONTROL_WITH_ICON_SIDE}
          angle={0}
          borderRadius={'50%'}
          isVisible={!arePosterItemsMoving && !arePosterItemsRotating && !arePosterItemsScaling}
          onPointerDown={() => {
            activeItem.selectNextSlide();
          }}
          controlType={ControlType.CLICK}
        />
      </div>
    );
  };

  if (arePosterItemsMoving || arePosterItemsRotating || arePosterItemsScaling) {
    return null;
  }

  return getSlideshowControlsWeapper();
}
