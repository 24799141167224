import type {ReactElement} from 'react';
import React, {type PointerEvent, useEffect, useRef, useState} from 'react';
import {CUSTOM_MIDDLE_LEFT_CONTROL} from './custom-item-control-ml.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import {degreesToRadians} from '@Utils/math.util';
import {
  defaultOnSingleItemResizeHandleDragEnded,
  doesSelectedItemHaveCustomLeftRightHandlesResizing,
  doesSelectedItemHaveLeftRightResizeControls,
  getDeltaWidthForMiddleControl,
} from '@PosterWhiteboard/libraries/custom-item-controls.library';
import type {TextItem} from '@PosterWhiteboard/items/text-item/text-item.class';
import type {TranscriptItem} from '@PosterWhiteboard/items/transcript-item/transcript-item';
import type {SlideshowItem} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import {SLIDESHOW_MIN_SCALED_DIMENSION} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.types';
import type {MenuItem} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import type {CustomItemControlPositions} from '@Components/poster-editor/components/custom-item-controls/components/single-item-custom-controls/single-item-custom-controls.types';
import {
  CUSTOM_ITEM_CONTROL_BORDER,
  CUSTOM_ITEM_DIMENSIONS_CONTROL_LONGER_SIDE,
  CUSTOM_ITEM_DIMENSIONS_CONTROL_SHORTER_SIDE,
  CustomControlCursor,
} from '@Components/poster-editor/components/custom-item-controls/custom-item-controls.types';
import {useSingleActivePosterItem} from '@Hooks/poster-editor/useSingleActivePosterItem';
import {BaseCustomItemControl} from '@Components/poster-editor/components/custom-item-controls/components/base-custom-item-control/base-custom-item-control';
import {useAppSelector} from '@/hooks';
import {CUSTOM_ITEM_OUTLINE_THICKNESS} from '@Components/poster-editor/components/custom-item-controls/components/custom-outline-boxes/custom-outline-boxes.types';

export function CustomItemControlMl(): ReactElement | null {
  const activeItem = useSingleActivePosterItem();
  const arePosterItemsMoving = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsMoving;
  });
  const arePosterItemsScaling = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsScaling;
  });
  const arePosterItemsRotating = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsRotating;
  });
  const [isActive, setIsActive] = useState(false);
  const initialClientX = useRef<number | null>(null);
  const initialClientY = useRef<number | null>(null);

  const handlePointerMove = (e: globalThis.PointerEvent): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    if (!isActive) {
      return;
    }

    if (initialClientX.current === null) {
      return;
    }

    onItemResizedWithLeftHandle(e);
  };

  const handlePointerUp = (): void => {
    if (!activeItem) {
      return;
    }

    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    if (!isActive) {
      return;
    }

    setIsActive(false);

    currentPage.poster.redux.setPosterItemsModificationState(false);

    if (doesSelectedItemHaveCustomLeftRightHandlesResizing(activeItem)) {
      customOnItemLeftHandleDragEnded();
    } else {
      void defaultOnSingleItemResizeHandleDragEnded(activeItem);
    }

    initialClientX.current = null;
    initialClientY.current = null;
  };

  useEffect(() => {
    if (isActive) {
      window.addEventListener('pointerup', handlePointerUp);
      window.addEventListener('pointermove', handlePointerMove);
    }

    return (): void => {
      window.removeEventListener('pointermove', handlePointerMove);
      window.removeEventListener('pointerup', handlePointerUp);
    };
  }, [isActive]);

  if (!activeItem) {
    return null;
  }

  if (!doesSelectedItemHaveLeftRightResizeControls(activeItem)) {
    return null;
  }

  const handlePointerDown = (e: PointerEvent<HTMLElement>): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    const target = e.target as HTMLElement;
    if (target.id === CUSTOM_MIDDLE_LEFT_CONTROL) {
      setIsActive(true);

      initialClientX.current = e.clientX;
      initialClientY.current = e.clientY;

      currentPage.poster.redux.setPosterItemsModificationState('scaling');
    }
  };

  const defaultOnItemLeftHandleDragged = (e: globalThis.PointerEvent, deltaW: number): void => {
    const originalScaledWidth = activeItem.getScaledWidth();
    const changedScaledWidth = originalScaledWidth - deltaW;
    if (changedScaledWidth <= 0) {
      return;
    }

    const newScaleX = changedScaledWidth / activeItem.fabricObject.width;

    activeItem.fabricObject.set({
      scaleX: newScaleX,
      left: activeItem.getX() + deltaW * Math.cos(degreesToRadians(activeItem.fabricObject.angle)),
      top: activeItem.getY() + deltaW * Math.sin(degreesToRadians(activeItem.fabricObject.angle)),
    });

    activeItem.onScaling();

    updateInitialRefs(e);
    refreshFabricObject();
  };

  const customOnItemLeftHandleDragged = (e: globalThis.PointerEvent, deltaW: number): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    switch (activeItem.gitype) {
      case ITEM_TYPE.TEXT:
        onTextItemLeftHandleDragged(e, deltaW, activeItem as TextItem);
        break;
      case ITEM_TYPE.TRANSCRIPT:
        onTranscriptItemLeftHandleDragged(e, deltaW, activeItem as TranscriptItem);
        break;
      case ITEM_TYPE.SLIDESHOW:
        onSlideshowItemLeftHandleDragged(e, deltaW, activeItem as SlideshowItem);
        break;
      case ITEM_TYPE.MENU:
        void onMenuItemLeftHandleDragged(e, deltaW, activeItem as MenuItem);
        break;
      default:
        throw new Error(`Unhandled ITEM_TYPE ${activeItem.gitype} in customOnItemLeftHandleDragged`);
    }

    currentPage.poster.redux.updateReduxItemData(currentPage.hashedID, activeItem.uid, {
      x: activeItem.getX(),
      y: activeItem.getY(),
      scaleX: activeItem.getScaleX(),
      scaleY: activeItem.getScaleY(),
    });
  };

  const onMenuItemLeftHandleDragged = async (e: globalThis.PointerEvent, deltaW: number, selectedMenuGraphicItem: MenuItem): Promise<void> => {
    const offset = -deltaW / selectedMenuGraphicItem.fabricObject.scaleX;

    if (selectedMenuGraphicItem.xSpacing + offset < 0) {
      return;
    }

    void selectedMenuGraphicItem.updateFromObject(
      {
        xSpacing: selectedMenuGraphicItem.xSpacing + offset,
      },
      {undoable: false}
    );

    void selectedMenuGraphicItem.refreshView();

    selectedMenuGraphicItem.fabricObject.set({
      left: selectedMenuGraphicItem.fabricObject.left + deltaW * Math.cos(degreesToRadians(selectedMenuGraphicItem.fabricObject.angle)),
      top: selectedMenuGraphicItem.fabricObject.top + deltaW * Math.sin(degreesToRadians(selectedMenuGraphicItem.fabricObject.angle)),
    });

    updateInitialRefs(e);
    refreshFabricObject();
  };

  const onTranscriptItemLeftHandleDragged = (e: globalThis.PointerEvent, deltaW: number, selectedTranscriptGraphicItem: TranscriptItem): void => {
    const newWidth = selectedTranscriptGraphicItem.fabricObject.width - deltaW / selectedTranscriptGraphicItem.fabricObject.scaleX;
    if (newWidth < selectedTranscriptGraphicItem.getSmallestWidthNeededBySubtitleFabricTextBox()) {
      return;
    }

    selectedTranscriptGraphicItem.fabricObject.set({
      left: selectedTranscriptGraphicItem.fabricObject.left + deltaW * Math.cos(degreesToRadians(selectedTranscriptGraphicItem.fabricObject.angle)),
      top: selectedTranscriptGraphicItem.fabricObject.top + deltaW * Math.sin(degreesToRadians(selectedTranscriptGraphicItem.fabricObject.angle)),
    });

    selectedTranscriptGraphicItem.setTranscriptItemAndFabricGroupWidth(newWidth);

    updateInitialRefs(e);
    refreshFabricObject();
  };

  const onSlideshowItemLeftHandleDragged = (e: globalThis.PointerEvent, deltaW: number, selectedSlideshowGraphicItem: SlideshowItem): void => {
    const newWidth = selectedSlideshowGraphicItem.fabricObject.width - deltaW / selectedSlideshowGraphicItem.fabricObject.scaleX;
    if (newWidth < selectedSlideshowGraphicItem.getMinWidth() || selectedSlideshowGraphicItem.fabricObject.getScaledWidth() - deltaW < SLIDESHOW_MIN_SCALED_DIMENSION) {
      return;
    }

    selectedSlideshowGraphicItem.fabricObject.set({
      width: newWidth,
      left: selectedSlideshowGraphicItem.fabricObject.left + deltaW * Math.cos(degreesToRadians(selectedSlideshowGraphicItem.fabricObject.angle)),
      top: selectedSlideshowGraphicItem.fabricObject.top + deltaW * Math.sin(degreesToRadians(selectedSlideshowGraphicItem.fabricObject.angle)),
    });

    selectedSlideshowGraphicItem.resizeGroupItemsWidth(newWidth);

    updateInitialRefs(e);
    refreshFabricObject();
  };

  const onTextItemLeftHandleDragged = (e: globalThis.PointerEvent, deltaW: number, selectedTextGraphicItem: TextItem): void => {
    const newWidth = selectedTextGraphicItem.fabricObject.width - deltaW / selectedTextGraphicItem.fabricObject.scaleX;
    if (newWidth < selectedTextGraphicItem.getMinWidth()) {
      return;
    }

    selectedTextGraphicItem.fabricObject.set({
      width: newWidth,
      left: selectedTextGraphicItem.fabricObject.left + deltaW * Math.cos(degreesToRadians(selectedTextGraphicItem.fabricObject.angle)),
      top: selectedTextGraphicItem.fabricObject.top + deltaW * Math.sin(degreesToRadians(selectedTextGraphicItem.fabricObject.angle)),
    });
    selectedTextGraphicItem.onModifyWidth();

    updateInitialRefs(e);
    refreshFabricObject();
  };

  const customOnItemLeftHandleDragEnded = (): void => {
    switch (activeItem.gitype) {
      case ITEM_TYPE.TEXT:
        onTextItemLeftHandleDragEnded(activeItem as TextItem);
        return;
      case ITEM_TYPE.TRANSCRIPT:
        onTranscriptItemLeftHandleDragEnded(activeItem as TranscriptItem);
        return;
      case ITEM_TYPE.SLIDESHOW:
        onSlideshowItemLeftHandleDragEnded(activeItem as SlideshowItem);
        return;
      case ITEM_TYPE.MENU:
        onMenuItemLeftHandleDragEnded(activeItem as MenuItem);
        return;
      default:
        throw new Error(`Unhandled ITEM_TYPE ${activeItem.gitype} in customOnItemLeftHandleDragEnded`);
    }
  };

  const onMenuItemLeftHandleDragEnded = (selectedMenuGraphicItem: MenuItem): void => {
    void selectedMenuGraphicItem.updateFromObject({
      x: selectedMenuGraphicItem.fabricObject.left,
      y: selectedMenuGraphicItem.fabricObject.top,
    });
  };

  const onSlideshowItemLeftHandleDragEnded = (selectedSlideshowGraphicItem: SlideshowItem): void => {
    void selectedSlideshowGraphicItem.updateFromObject({
      width: selectedSlideshowGraphicItem.fabricObject.width,
      x: selectedSlideshowGraphicItem.fabricObject.left,
      y: selectedSlideshowGraphicItem.fabricObject.top,
    });

    if (selectedSlideshowGraphicItem.slides.hasTextSlide()) {
      for (const slide of Object.values(selectedSlideshowGraphicItem.slides.slidesHashMap)) {
        if (slide.isTextSlide()) {
          void slide.updateFromObject(
            {
              baseWidth: slide.calculateBaseWidth(),
            },
            {undoable: false}
          );
        }
      }
    }
  };

  const onTranscriptItemLeftHandleDragEnded = (selectedTranscriptGraphicItem: TranscriptItem): void => {
    void selectedTranscriptGraphicItem.updateFromObject({
      width: selectedTranscriptGraphicItem.fabricObject.width,
      x: selectedTranscriptGraphicItem.fabricObject.left,
      y: selectedTranscriptGraphicItem.fabricObject.top,
    });
  };

  const onTextItemLeftHandleDragEnded = (selectedTextGraphicItem: TextItem): void => {
    void selectedTextGraphicItem.updateFromObject({
      baseWidth: selectedTextGraphicItem.calculateBaseWidth(),
      width: selectedTextGraphicItem.fabricObject.width,
    });
  };

  const updateInitialRefs = (e: globalThis.PointerEvent): void => {
    initialClientX.current = e.clientX;
    initialClientY.current = e.clientY;
  };

  const refreshFabricObject = (): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    activeItem.fabricObject.setCoords();

    currentPage.fabricCanvas.requestRenderAll();
  };

  const onItemResizedWithLeftHandle = (e: globalThis.PointerEvent): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    if (initialClientX.current === null || initialClientY.current === null) {
      return;
    }

    const angleRadians = degreesToRadians(activeItem.fabricObject.angle);
    const deltaW = getDeltaWidthForMiddleControl(e.clientX, initialClientX.current, e.clientY, initialClientY.current, currentPage.poster.scaling.scale, angleRadians);

    if (doesSelectedItemHaveCustomLeftRightHandlesResizing(activeItem)) {
      customOnItemLeftHandleDragged(e, deltaW);
    } else {
      defaultOnItemLeftHandleDragged(e, deltaW);
    }
  };

  const getCursor = (): CustomControlCursor => {
    let angle = activeItem.fabricObject.getTotalAngle();
    if (angle < 0) {
      angle = angle + 360;
    }

    if (angle >= 0 && angle < 23) {
      return CustomControlCursor.CURSOR_EW_RESIZE;
    }
    if (angle >= 23 && angle < 68) {
      return CustomControlCursor.CURSOR_NWSE_RESIZE;
    }
    if (angle >= 68 && angle < 113) {
      return CustomControlCursor.CURSOR_NS_RESIZE;
    }
    if (angle >= 113 && angle < 158) {
      return CustomControlCursor.CURSOR_NESW_RESIZE;
    }
    if (angle >= 158 && angle < 203) {
      return CustomControlCursor.CURSOR_EW_RESIZE;
    }
    if (angle >= 203 && angle < 248) {
      return CustomControlCursor.CURSOR_NWSE_RESIZE;
    }
    if (angle >= 248 && angle < 293) {
      return CustomControlCursor.CURSOR_NS_RESIZE;
    }
    if (angle >= 293 && angle < 338) {
      return CustomControlCursor.CURSOR_NESW_RESIZE;
    }
    return CustomControlCursor.CURSOR_EW_RESIZE;
  };

  const getMlControlPosition = (): CustomItemControlPositions => {
    const itemHeight = activeItem.fabricObject.getScaledHeight() * activeItem.page.poster.scaling.scale;

    const leftInitial = activeItem.fabricObject.getX() * activeItem.page.poster.scaling.scale;
    const topInitial = activeItem.fabricObject.getY() * activeItem.page.poster.scaling.scale;

    const xCorrectionDueToItemHeight = (itemHeight / 2) * Math.sin(degreesToRadians(activeItem.fabricObject.getTotalAngle()));

    const yCorrectionDueToItemHeight = (itemHeight / 2) * Math.cos(degreesToRadians(activeItem.fabricObject.getTotalAngle()));

    const angle = activeItem.fabricObject.getTotalAngle();
    const left =
      leftInitial -
      xCorrectionDueToItemHeight -
      Math.ceil(CUSTOM_ITEM_DIMENSIONS_CONTROL_SHORTER_SIDE / 2) -
      CUSTOM_ITEM_OUTLINE_THICKNESS * Math.cos(degreesToRadians(activeItem.fabricObject.getTotalAngle())) +
      CUSTOM_ITEM_CONTROL_BORDER * Math.cos(degreesToRadians(activeItem.fabricObject.getTotalAngle()));
    const top =
      topInitial +
      yCorrectionDueToItemHeight -
      Math.ceil(CUSTOM_ITEM_DIMENSIONS_CONTROL_LONGER_SIDE / 2) -
      CUSTOM_ITEM_OUTLINE_THICKNESS * Math.sin(degreesToRadians(activeItem.fabricObject.getTotalAngle())) +
      CUSTOM_ITEM_CONTROL_BORDER * Math.sin(degreesToRadians(activeItem.fabricObject.getTotalAngle()));

    return {left, top, angle};
  };

  if (arePosterItemsMoving || arePosterItemsRotating || (arePosterItemsScaling && !isActive)) {
    return null;
  }

  return (
    <BaseCustomItemControl
      controlId={CUSTOM_MIDDLE_LEFT_CONTROL}
      top={getMlControlPosition().top}
      left={getMlControlPosition().left}
      width={CUSTOM_ITEM_DIMENSIONS_CONTROL_SHORTER_SIDE}
      height={CUSTOM_ITEM_DIMENSIONS_CONTROL_LONGER_SIDE}
      angle={getMlControlPosition().angle}
      isVisible={(!arePosterItemsMoving && !arePosterItemsRotating && !arePosterItemsScaling) || (arePosterItemsScaling && isActive)}
      cursor={getCursor()}
      onPointerDown={handlePointerDown}
    />
  );
}
