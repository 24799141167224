import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {ItemConfig} from '@PosterWhiteboard/items/item/item.types';
import {FIXED_SEQUENTIAL_ANIMATION_DELAY_FACTOR, MAX_ITEM_ANIMATION_DELAY_FACTOR, MAX_ITEMS_WITH_FIXED_DELAY_FACTOR} from '@PosterWhiteboard/page/page.types';
import {util} from '@postermywall/fabricjs-2';
import type {DeepPartial} from '@/global';

export enum AnimationSpeed {
  LOWER = 'lower',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  HIGHER = 'higher',
}

export enum SlideType {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum AnimationType {
  NONE = 'none',
  FADE = 'fade',
  BLOCK = 'block',
  SLIDE = 'slide',
  RISE = 'rise',
  PAN = 'pan',
  POP = 'pop',
  TUMBLE = 'tumble',
  JELLO = 'jello',
  ROTATE = 'rotate',
  ZOOM_IN = 'zoom_in',
  ZOOM_OUT = 'zoom_out',
  BOUNCE_IN_DOWN = 'bounce_in_down',
  WIPE_DOWN = 'wipe_down',
  WIPE_UP = 'wipe_up',
  WIPE_LEFT = 'wipe_left',
  WIPE_RIGHT = 'wipe_right',
  PEEK_DOWN = 'peek_down',
  PEEK_UP = 'peek_up',
  PEEK_LEFT = 'peek_left',
  PEEK_RIGHT = 'peek_right',
  SHRINK = 'shrink',
  EXPAND = 'expand',
  PAN_LEFT = 'pan_left',
  PAN_RIGHT = 'pan_right',
  PAN_UP = 'pan_up',
  PAN_DOWN = 'pan_down',
}

export enum SpeedDuration {
  LOWER = 2.5,
  LOW = 1.7,
  MEDIUM = 1,
  HIGH = 0.5,
  HIGHER = 0.2,
}

export enum FadeSpeedDuration {
  LOWER = 2.7,
  LOW = 2.2,
  MEDIUM = 1.7,
  HIGH = 1,
  HIGHER = 0.3,
}

export enum SequentialAnimationSpeedDuration {
  LOWER = 1.2,
  LOW = 0.95,
  MEDIUM = 0.7,
  HIGH = 0.45,
  HIGHER = 0.2,
}

export enum PositionType {
  LEFT = 'LEFT',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
}

export interface AnimationConfig extends ItemConfig {
  type: AnimationType;
  slideType: SlideType | PositionType;
  speed: AnimationSpeed;
}

export const DEFAULT_POSTER_INTRO_ANIMATIONS = [
  AnimationType.NONE,
  AnimationType.BLOCK,
  AnimationType.BOUNCE_IN_DOWN,
  AnimationType.FADE,
  AnimationType.JELLO,
  AnimationType.PAN,
  AnimationType.POP,
  AnimationType.RISE,
  AnimationType.ROTATE,
  AnimationType.SLIDE,
  AnimationType.TUMBLE,
];

export const SLIDESHOW_INTRO_ANIMATIONS = [
  AnimationType.NONE,
  AnimationType.FADE,
  AnimationType.BLOCK,
  AnimationType.SHRINK,
  AnimationType.PEEK_DOWN,
  AnimationType.PEEK_UP,
  AnimationType.PEEK_LEFT,
  AnimationType.PEEK_RIGHT,
  AnimationType.WIPE_DOWN,
  AnimationType.WIPE_UP,
  AnimationType.WIPE_LEFT,
  AnimationType.WIPE_RIGHT,
  AnimationType.PAN_DOWN,
  AnimationType.PAN_UP,
  AnimationType.PAN_LEFT,
  AnimationType.PAN_RIGHT,
];

export class Animation extends CommonMethods {
  public type = AnimationType.NONE;
  public slideType = SlideType.LEFT;
  public speed = AnimationSpeed.MEDIUM;

  public static create(options: AnimationConfig): Animation {
    return new Animation(options);
  }

  constructor(options: DeepPartial<AnimationConfig> = {}) {
    super();
    this.copyVals(options);
  }

  public getDuration(): number {
    if (this.type === AnimationType.FADE) {
      switch (this.speed) {
        case AnimationSpeed.LOWER:
          return FadeSpeedDuration.LOWER;

        case AnimationSpeed.LOW:
          return FadeSpeedDuration.LOW;

        case AnimationSpeed.MEDIUM:
          return FadeSpeedDuration.MEDIUM;

        case AnimationSpeed.HIGH:
          return FadeSpeedDuration.HIGH;

        case AnimationSpeed.HIGHER:
          return FadeSpeedDuration.HIGHER;

        default:
          return 0;
      }
    } else if (this.hasSequentialDesignAnimationEffect()) {
      switch (this.speed) {
        case AnimationSpeed.LOWER:
          return SequentialAnimationSpeedDuration.LOWER;

        case AnimationSpeed.LOW:
          return SequentialAnimationSpeedDuration.LOW;

        case AnimationSpeed.MEDIUM:
          return SequentialAnimationSpeedDuration.MEDIUM;

        case AnimationSpeed.HIGH:
          return SequentialAnimationSpeedDuration.HIGH;

        case AnimationSpeed.HIGHER:
          return SequentialAnimationSpeedDuration.HIGHER;

        default:
          return 0;
      }
    } else {
      switch (this.speed) {
        case AnimationSpeed.LOWER:
          return SpeedDuration.LOWER;

        case AnimationSpeed.LOW:
          return SpeedDuration.LOW;

        case AnimationSpeed.MEDIUM:
          return SpeedDuration.MEDIUM;

        case AnimationSpeed.HIGH:
          return SpeedDuration.HIGH;

        case AnimationSpeed.HIGHER:
          return SpeedDuration.HIGHER;

        default:
          return 0;
      }
    }
  }

  public getMaxItemAnimationDelay(): number {
    const duration = this.getDuration();

    if (this.hasSequentialDesignAnimationEffect()) {
      return duration * FIXED_SEQUENTIAL_ANIMATION_DELAY_FACTOR * MAX_ITEMS_WITH_FIXED_DELAY_FACTOR;
    }

    return MAX_ITEM_ANIMATION_DELAY_FACTOR * duration;
  }

  public hasAnimation(): boolean {
    return isAnimation(this.type);
  }

  public hasSequentialDesignAnimationEffect(): boolean {
    return (
      this.type === AnimationType.BLOCK ||
      this.type === AnimationType.POP ||
      this.type === AnimationType.TUMBLE ||
      this.type === AnimationType.JELLO ||
      this.type === AnimationType.ROTATE ||
      this.type === AnimationType.PAN
    );
  }

  public toObject(): AnimationConfig {
    return {
      type: this.type,
      slideType: this.slideType,
      speed: this.speed,
    };
  }

  public updateFromObject(object: DeepPartial<AnimationConfig>): void {
    this.copyVals(object);
  }
}

export const isAnimation = (animationType: AnimationType): boolean => {
  return animationType !== AnimationType.NONE;
};

export const isWipeOrPeekAnimation = (animationType: AnimationType): boolean => {
  return (
    animationType === AnimationType.WIPE_DOWN ||
    animationType === AnimationType.WIPE_UP ||
    animationType === AnimationType.WIPE_LEFT ||
    animationType === AnimationType.WIPE_RIGHT ||
    animationType === AnimationType.PEEK_DOWN ||
    animationType === AnimationType.PEEK_UP ||
    animationType === AnimationType.PEEK_LEFT ||
    animationType === AnimationType.PEEK_RIGHT
  );
};

export const isWipeOrBlockAnimation = (animationType: AnimationType): boolean => {
  return (
    animationType === AnimationType.WIPE_DOWN ||
    animationType === AnimationType.WIPE_UP ||
    animationType === AnimationType.WIPE_LEFT ||
    animationType === AnimationType.WIPE_RIGHT ||
    animationType === AnimationType.BLOCK
  );
};

export const isBlockAnimation = (animationType: AnimationType): boolean => {
  return animationType === AnimationType.BLOCK;
};

export const getEaseFunctionForAnimationType = (type: AnimationType): ((timeElapsed: number, startValue: number, byValue: number, duration: number) => number) => {
  switch (type) {
    case AnimationType.SLIDE:
    case AnimationType.RISE:
    case AnimationType.PAN:
    case AnimationType.PAN_RIGHT:
    case AnimationType.PAN_LEFT:
    case AnimationType.PAN_DOWN:
    case AnimationType.PAN_UP:
    case AnimationType.SHRINK:
    case AnimationType.EXPAND:
    case AnimationType.TUMBLE:
    case AnimationType.ROTATE:
      return util.ease.easeOutCubic;

    case AnimationType.BLOCK:
      return util.ease.easeInOutQuint;

    case AnimationType.FADE:
      return util.ease.easeOutQuad;

    case AnimationType.POP:
    case AnimationType.JELLO:
      return util.ease.easeOutElastic;

    case AnimationType.BOUNCE_IN_DOWN:
    case AnimationType.ZOOM_IN:
    case AnimationType.ZOOM_OUT:
      return util.ease.easeOutBack;

    case AnimationType.WIPE_DOWN:
    case AnimationType.WIPE_UP:
    case AnimationType.WIPE_LEFT:
    case AnimationType.WIPE_RIGHT:
    case AnimationType.PEEK_DOWN:
    case AnimationType.PEEK_UP:
    case AnimationType.PEEK_LEFT:
    case AnimationType.PEEK_RIGHT:
      return util.ease.easeOutSine;

    case AnimationType.NONE:
      return util.ease.easeOutCubic;

    default:
      console.error('Unknown animation type: ', type);
      return util.ease.easeOutCubic;
  }
};
