export const CUSTOM_ITEM_DIMENSIONS_CONTROL_LONGER_SIDE = 30;
export const CUSTOM_ITEM_DIMENSIONS_CONTROL_SHORTER_SIDE = 8;
export const CUSTOM_ITEM_SCALING_CONTROL_SIDE = 14;
export const CUSTOM_ITEM_CONTROL_WITH_ICON_SIDE = 28;
export const CUSTOM_ITEM_CONTROL_BORDER = 1;

export const CUSTOM_CONTROLS_WRAPPER = 'items-control-wrapper';

export enum CustomControlCursor {
  CURSOR_EW_RESIZE = 'ew-resize',
  CURSOR_NS_RESIZE = 'ns-resize',
  CURSOR_NWSE_RESIZE = 'nwse-resize',
  CURSOR_NESW_RESIZE = 'nesw-resize',
  CURSOR_POINTER = 'pointer',
  CURSOR_CROSSHAIR = 'crosshair',
}
