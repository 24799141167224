import type {ReactElement} from 'react';
import React, {useRef} from 'react';
import type * as Fabric from '@postermywall/fabricjs-2';
import {Page} from '@PosterWhiteboard/page/page.class';
import {ControlledList} from '@Components/controlled-list';
import type {ControlledListItemType} from '@Components/controlled-list/controlled-list.types';
import {CONTROLLED_LIST_ITEM_TYPE} from '@Components/controlled-list/controlled-list.types';
import {ThumbnailType} from '@Components/thumbnail/thumbnail.types';
import {AddItemThumbnail} from '@Components/poster-editor/components/poster-add-item-bar/components/add-item-thumbnail';
import {updateImagePopUpState} from '@Components/poster-editor/components/image-item-popup/image-item-popup-slice';
import useOutsideClick from '@Hooks/useOutsideClick';
import {degreesToRadians} from '@Utils/math.util';
import {onOpenReplaceMedia} from '@Components/poster-editor/library/poster-editor-open-modals';
import type {ItemData} from '@PosterWhiteboard/page/add-item.class';
import {ReplaceMediaControlsButtons} from '@Components/replace-media-controls';
import {Canvas} from '@postermywall/fabricjs-2';
import {useAppDispatch, useAppSelector} from '@/hooks';
import styles from './image-item-popup.module.scss';
import {useSingleActivePosterItemId} from '@Hooks/poster-editor/useSingleActivePosterItemId';

interface PositionalParams {
  left?: number;
  top?: number;
}

interface CornerPoint {
  x: number;
  y: number;
}

const CORNER_PADDING = 5;
const POPUP_WIDTH = 278;
const POPUP_HEIGHT = 160;
const BUTTON_TOP_THRESHOLD = 65;

export function ImageItemPopup(): ReactElement | null {
  const popupState = useAppSelector((state) => {
    return state.imageItemPopUpMenu;
  });
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  const activeCanvas = currentPage?.fabricCanvas;
  const activeItemId = useSingleActivePosterItemId();
  const activeItem = activeItemId ? window.posterEditor?.whiteboard?.getItemForId(activeItemId) : undefined;
  const popUpRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const closePopup = (): void => {
    if (popupState.beforeOpen && !popupState.openPopup) {
      dispatch(
        updateImagePopUpState({
          openPopup: true,
          beforeOpen: false,
          target: popupState.target as Fabric.Image,
        })
      );
    } else if (popupState.openPopup) {
      dispatch(
        updateImagePopUpState({
          openPopup: false,
          target: null,
        })
      );
    }
  };

  useOutsideClick(popUpRef, closePopup);

  if (!(activeCanvas instanceof Canvas)) {
    return null;
  }

  const getMenuPosition = (): PositionalParams => {
    if (!popupState.openPopup) {
      return {
        left: 0,
        top: 0,
      };
    }
    const currentPageId = window.posterEditor?.whiteboard?.getCurrentPageId() ?? '';
    const canvasContainerElementOfFirstPage = Page.getFirstCanvasContainerHTML();
    const canvasContainerElementOfCurrentPage = Page.getCanvasContainerHTMLForPageId(currentPageId);
    const target = popupState.target as Fabric.Image;
    const posterScale = window.posterEditor?.whiteboard?.scaling.scale ?? 1;
    const pageOffsetHeight = Page.getPrePageHTMLContainerForPageId(currentPageId)?.offsetHeight ?? 0;
    const canvasContainerElementTopOffset = (canvasContainerElementOfCurrentPage?.offsetTop ?? 0) - (canvasContainerElementOfFirstPage?.offsetTop ?? 0);

    let left = (target.aCoords.tl.x + (target.aCoords.br.x - target.aCoords.bl.x) / 2) * posterScale - POPUP_WIDTH / 2;
    let top = target.aCoords.bl.y * posterScale + BUTTON_TOP_THRESHOLD;

    if (target.angle) {
      const position = getPositionForRotatedImage(target);
      left = position.x;
      top = position.y;
    }
    const position = getPositionForCornercases(target, left, top);

    return {
      left: position.x,
      top: position.y + pageOffsetHeight + canvasContainerElementTopOffset,
    };
  };

  const getPositionForRotatedImage = (target: Fabric.Image): CornerPoint => {
    const {angle} = target;
    const radAngle = degreesToRadians(angle);
    const imageAngle = 90 - angle;
    const imageRadAngle = degreesToRadians(imageAngle);
    const posterScale = window.posterEditor?.whiteboard?.scaling.scale ?? 1;

    const replaceBtnLeft = ((Math.abs(target.aCoords.br.x) - Math.abs(target.aCoords.bl.x)) * target.scaleX) / 2;
    const left = target.aCoords.tl.x - target.height * target.scaleY * Math.cos(imageRadAngle);
    const top = target.aCoords.tl.y + target.height * target.scaleY * Math.sin(imageRadAngle);
    const posX = (left - replaceBtnLeft * target.scaleX * Math.cos(radAngle) + target.height * target.scaleY * Math.cos(radAngle)) * posterScale;
    let posY = (top + replaceBtnLeft * target.scaleX * Math.sin(radAngle) + target.height * target.scaleY * Math.sin(radAngle)) * posterScale;
    posY += angle < 60 ? Math.abs(target.height * target.scaleY * Math.cos(radAngle)) / 4 : 0;
    posY += angle > 260 && angle < 340 ? Math.abs(target.height * target.scaleY * Math.cos(radAngle)) / 2 : 0;
    posY += angle >= 340 ? Math.abs(target.height * target.scaleY * Math.sin(radAngle)) / 2 + BUTTON_TOP_THRESHOLD : 0;
    return {x: posX, y: posY};
  };

  const getPositionForCornercases = (target: Fabric.Image, posX: number, posY: number): CornerPoint => {
    const canvas = window.posterEditor?.whiteboard?.getCurrentPage().fabricCanvas;
    const posterScale = window.posterEditor?.whiteboard?.scaling.scale ?? 1;
    if (canvas) {
      let x = posX;
      let y = posY;

      if (x <= CORNER_PADDING) {
        x = CORNER_PADDING;
      }
      if (y <= CORNER_PADDING) {
        y = CORNER_PADDING + BUTTON_TOP_THRESHOLD / 2;
      }
      if (x + POPUP_WIDTH > canvas.width - CORNER_PADDING) {
        x = canvas.width - POPUP_WIDTH - CORNER_PADDING;
      }
      if (y + POPUP_HEIGHT > canvas.height - CORNER_PADDING) {
        const posCenterY =
          (target.aCoords.bl.y > target.aCoords.br.y ? target.aCoords.br.y : target.aCoords.bl.y) + Math.abs(Math.abs(target.aCoords.br.y) - Math.abs(target.aCoords.bl.y)) / 2;
        y = (target.angle ? posCenterY : target.aCoords.bl.y) * posterScale - POPUP_HEIGHT / 2 - CORNER_PADDING - BUTTON_TOP_THRESHOLD;
      }
      return {x, y};
    }
    return {x: posX, y: posY};
  };

  const onReplace = (itemToReplaceWith: ItemData): void => {
    if (!currentPage || !activeItem) {
      return;
    }
    if (activeItem && !(activeItem.isImage() || activeItem.isVideo())) {
      return;
    }
    void currentPage.items.replaceItems.replaceMedia(itemToReplaceWith, activeItem);
  };

  const onReplaceOption = (type: ReplaceMediaControlsButtons): void => {
    if (activeItem && !(activeItem.isImage() || activeItem.isVideo())) {
      return;
    }
    if (activeItem) {
      dispatch(
        updateImagePopUpState({
          openPopup: false,
          target: null,
        })
      );
      onOpenReplaceMedia(type, activeItem, (itemData: ItemData): void => {
        onReplace(itemData);
      });
    }
  };

  const getPopupMenuItems = (): ControlledListItemType[] => {
    return [
      {
        id: 'replace-with-uploads',
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_2,
        text: window.i18next.t('pmwjs_myuploads_capitalized'),
        subText: window.i18next.t('pmwjs_replace_with_uploads_media'),
        onClick: (): void => {
          onReplaceOption(ReplaceMediaControlsButtons.MY_UPLOADS);
        },
        thumbnail: {
          type: ThumbnailType.CUSTOM,
          content: <AddItemThumbnail className={styles.thumbnailWidth} icon="icon-upload-1" />,
        },
      },
      {
        id: 'replace-with-stock',
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_2,
        text: window.i18next.t('pmwjs_media'),
        subText: window.i18next.t('pmwjs_replace_with_stock_media'),
        onClick: (): void => {
          onReplaceOption(ReplaceMediaControlsButtons.EXPLORE_MEDIA);
        },
        thumbnail: {
          type: ThumbnailType.CUSTOM,
          content: <AddItemThumbnail className={styles.thumbnailWidth} iconClassName={styles.icon} icon="icon-media" />,
        },
      },
    ];
  };

  const getPopupMenu = (): ReactElement | null => {
    return <ControlledList items={getPopupMenuItems()} className="spacing-p-2 spacing-m-0" />;
  };

  return (
    <div className={`${popupState.openPopup ? '' : '_hidden'} ${styles.popup}`}>
      <div ref={popUpRef} className={`${styles.popUpContainer} radius-4`} style={getMenuPosition()}>
        {getPopupMenu()}
      </div>
    </div>
  );
}
