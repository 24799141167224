import type {ReactElement} from 'react';
import React from 'react';
import type {CustomOutlineBoxPositionAndSize} from './custom-outline-boxes.types';
import {CUSTOM_ITEM_OUTLINE_THICKNESS, CUSTOM_MULTI_SELECT_OUTLINE_THICKNESS} from './custom-outline-boxes.types';
import {CUSTOM_MULTI_SELECTION_BOXES_WRAPPER, CUSTOM_SELECTION_BOX_CLASS} from './custom-outline-boxes.types';
import styles from './custom-outline-boxes.module.scss';
import {useAppSelector} from '@/hooks';
import {degreesToRadians} from '@Utils/math.util';
import {getHoveredItem, getActiveItems} from '@Components/poster-editor/poster-editor-reducer';
import type {FabricObject} from '@postermywall/fabricjs-2';
import {ActiveSelection} from '@postermywall/fabricjs-2';
import type {Page} from '@PosterWhiteboard/page/page.class';
import {ItemBorderColor} from '@PosterWhiteboard/poster/poster-item-controls';

export function CustomOutlineBoxes(): ReactElement {
  const activeItemsData = useAppSelector(getActiveItems);
  const hoveredItemData = useAppSelector(getHoveredItem);

  const verticalScroll = useAppSelector((state) => {
    return state.posterEditor.scrollState.verticalScroll;
  });
  const horizontalScroll = useAppSelector((state) => {
    return state.posterEditor.scrollState.horizontalScroll;
  });
  const isMobileVariant = useAppSelector((state) => {
    return state.posterEditor.isMobileVariant;
  });

  const getMultiSelectionOutlinePositionAndSize = (fabricObject: FabricObject, posterScale: number, isMultiSelectWrapper: boolean): CustomOutlineBoxPositionAndSize => {
    const scaledHeight = fabricObject.height * fabricObject.getObjectScaling().y * posterScale;
    const scaledWidth = fabricObject.width * fabricObject.getObjectScaling().x * posterScale;

    const offsetDueToBorderWidth = isMultiSelectWrapper ? CUSTOM_MULTI_SELECT_OUTLINE_THICKNESS : CUSTOM_ITEM_OUTLINE_THICKNESS;

    const leftInitial = fabricObject.getX() * posterScale - scaledWidth / 2;
    const topInitial = fabricObject.getY() * posterScale - scaledHeight / 2;

    const xCorrectionDueToHeight = (scaledHeight / 2) * Math.sin(degreesToRadians(fabricObject.getTotalAngle()));
    const xCorrectionDueToWidth = (scaledWidth / 2) * Math.cos(degreesToRadians(fabricObject.getTotalAngle()));

    const yCorrectionDueToHeight = (scaledHeight / 2) * Math.cos(degreesToRadians(fabricObject.getTotalAngle()));
    const yCorrectionDueToWidth = (scaledWidth / 2) * Math.sin(degreesToRadians(fabricObject.getTotalAngle()));

    const angle = fabricObject.getTotalAngle();
    const height = fabricObject.strokeWidth * posterScale + scaledHeight + offsetDueToBorderWidth * 2;
    const width = fabricObject.strokeWidth * posterScale + scaledWidth + offsetDueToBorderWidth * 2;
    const left = leftInitial - xCorrectionDueToHeight + xCorrectionDueToWidth - offsetDueToBorderWidth - fabricObject.strokeWidth * posterScale;
    const top = topInitial + yCorrectionDueToHeight + yCorrectionDueToWidth - offsetDueToBorderWidth;

    return {angle, height, width, left, top};
  };

  const getItemOutlinePositonAndSize = (itemFabricObject: FabricObject, currentPage: Page): CustomOutlineBoxPositionAndSize => {
    const objectCenterX = itemFabricObject.getCenterPoint().x * currentPage.poster.scaling.scale;
    const objectCenterY = itemFabricObject.getCenterPoint().y * currentPage.poster.scaling.scale;

    let objectTotalScaledWidth: number;
    let objectTotalScaledHeight: number;

    const activeSelection = currentPage.activeSelection.getActiveObject();
    if (!activeSelection || !(activeSelection instanceof ActiveSelection)) {
      objectTotalScaledWidth =
        itemFabricObject.width * itemFabricObject.scaleX * currentPage.poster.scaling.scale + itemFabricObject.strokeWidth * currentPage.poster.scaling.scale;
      objectTotalScaledHeight =
        itemFabricObject.height * itemFabricObject.scaleY * currentPage.poster.scaling.scale + itemFabricObject.strokeWidth * currentPage.poster.scaling.scale;
    } else {
      objectTotalScaledWidth =
        itemFabricObject.width * itemFabricObject.scaleX * activeSelection.scaleX * currentPage.poster.scaling.scale +
        itemFabricObject.strokeWidth * currentPage.poster.scaling.scale;
      objectTotalScaledHeight =
        itemFabricObject.height * itemFabricObject.scaleY * activeSelection.scaleY * currentPage.poster.scaling.scale +
        itemFabricObject.strokeWidth * currentPage.poster.scaling.scale;
    }

    const angle = itemFabricObject.getTotalAngle();
    const height = objectTotalScaledHeight + itemFabricObject.borderScaleFactor * 2;
    const width = objectTotalScaledWidth + itemFabricObject.borderScaleFactor * 2;
    const left = objectCenterX - objectTotalScaledWidth / 2 - itemFabricObject.borderScaleFactor;
    const top = objectCenterY - objectTotalScaledHeight / 2 - itemFabricObject.borderScaleFactor;

    return {angle, height, width, left, top};
  };

  const getMultiSelectionBoxesWrapper = (): ReactElement | null => {
    if (activeItemsData.length < 2) {
      return null;
    }

    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return null;
    }

    const activeSelection = currentPage.activeSelection.getActiveObject();
    if (!activeSelection || !(activeSelection instanceof ActiveSelection)) {
      return null;
    }

    const positionAndSize = getMultiSelectionOutlinePositionAndSize(activeSelection, currentPage.poster.scaling.scale, true);

    return (
      <div
        key={CUSTOM_MULTI_SELECTION_BOXES_WRAPPER}
        style={{
          visibility: 'visible',
          rotate: `${positionAndSize.angle}deg`,
          top: `${positionAndSize.top + verticalScroll}px`,
          left: `${positionAndSize.left + horizontalScroll}px`,
          height: `${positionAndSize.height}px`,
          width: `${positionAndSize.width}px`,
          borderColor: ItemBorderColor.STATIC_ITEM,
        }}
        className={`${CUSTOM_SELECTION_BOX_CLASS} ${styles.multiSelectWrapper}`}
      />
    );
  };

  const getHoverBox = (): ReactElement | null => {
    if (isMobileVariant) {
      return null;
    }

    if (hoveredItemData) {
      const item = window.posterEditor?.whiteboard?.pages.getItemForId(hoveredItemData.uid);
      if (item) {
        const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
        if (!currentPage) {
          return null;
        }

        const positionAndSize = getItemOutlinePositonAndSize(item.fabricObject, currentPage);

        return (
          <div
            key={hoveredItemData.uid}
            style={{
              visibility: 'visible',
              rotate: `${positionAndSize.angle}deg`,
              top: `${positionAndSize.top + verticalScroll}px`,
              left: `${positionAndSize.left + horizontalScroll}px`,
              height: `${positionAndSize.height}px`,
              width: `${positionAndSize.width}px`,
              borderColor: item.isStreamingMediaItem() ? ItemBorderColor.DYNAMIC_ITEM : ItemBorderColor.STATIC_ITEM,
            }}
            className={`${CUSTOM_SELECTION_BOX_CLASS} ${styles.baseStyles}`}
          />
        );
      }
    }

    return null;
  };

  const getSelectionBoxesForSelectedItems = (): ReactElement => {
    return (
      <>
        {getMultiSelectionBoxesWrapper()}

        {getHoverBox()}

        {activeItemsData.map((selectedItem) => {
          const item = window.posterEditor?.whiteboard?.pages.getItemForId(selectedItem.uid);
          if (!item) {
            return null;
          }
          const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
          if (!currentPage) {
            return null;
          }

          const positionAndSize = getItemOutlinePositonAndSize(item.fabricObject, currentPage);

          return (
            <div
              key={selectedItem.uid}
              style={{
                visibility: 'visible',
                top: `${positionAndSize.top + verticalScroll}px`,
                left: `${positionAndSize.left + horizontalScroll}px`,
                width: `${positionAndSize.width}px`,
                height: `${positionAndSize.height}px`,
                rotate: `${positionAndSize.angle}deg`,
                borderColor: item.isStreamingMediaItem() ? ItemBorderColor.DYNAMIC_ITEM : ItemBorderColor.STATIC_ITEM,
              }}
              className={`${CUSTOM_SELECTION_BOX_CLASS} ${styles.baseStyles}`}
            />
          );
        })}
      </>
    );
  };

  return getSelectionBoxesForSelectedItems();
}
