import type {
  AddTranscriptOpts,
  AnimatedStickerData,
  AudioData,
  ElementData,
  ElementDataWithUid,
  FancyTextData,
  ImageData,
  MenuData,
  PMWExtractedGettyStickerData,
  PMWIconData,
  PMWShapeData,
  PMWStillStickerData,
  ScheduleLayoutData,
  TableData,
  TempImageData,
  TranscriptData,
  VideoData,
} from '@Libraries/add-media-library';
import {ElementDataType} from '@Libraries/add-media-library';
import type {ItemObject, ItemType, SlideItemObject} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import {GlobalPosterEditorJqueryElement} from '@Components/poster-editor/poster-editor.types';
import type {AddItemOpts, Page} from '@PosterWhiteboard/page/page.class';
import {ITEM_CONTROL_DIMENSIONS} from '@PosterWhiteboard/poster/poster-item-controls';
import type {QRCodeItemObject} from '@PosterWhiteboard/items/qr-code-item.class';
import type {TextItemObject} from '@PosterWhiteboard/items/text-item/text-item.types';
import type {StickerItemObject} from '@PosterWhiteboard/items/sticker-item.class';
import type {VectorItemObject} from '@PosterWhiteboard/items/vector-item/vector-item.types';
import type {VideoItem, VideoItemObject} from '@PosterWhiteboard/items/video-item/video-item.class';
import type {TableItemObject} from '@PosterWhiteboard/items/table-item/table-item.class';
import {getSpacingValuesForMenuLayouts} from '@PosterWhiteboard/items/layouts/layout.library';
import {v4 as uuidv4} from 'uuid';
import {
  invalidateTableDataForCustomTableLayout,
  invalidateTableDataForFixedLayout,
  invalidateUnusedData,
  OFFSET_LAYOUT_Y_SPACING,
  SPORTS_LAYOUT_Y_SPACING,
} from '@PosterWhiteboard/items/table-item/user-table';
import type {MenuItemObject} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import {DEFAULT_FONT_FAMILY, DEFAULT_FONT_FAMILY2} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import type {ItemBorderObject} from '@PosterWhiteboard/classes/item-border.class';
import {BorderType} from '@PosterWhiteboard/classes/item-border.class';
import type {TabsItemObject} from '@PosterWhiteboard/items/tabs-item/tabs-item.class';
import {SeparatorType} from '@PosterWhiteboard/items/tabs-item/tabs-item.class';
import {ALIGNMENT_PADDING} from '@PosterWhiteboard/page/page-alignment';
import type {ImageItemObject} from '@PosterWhiteboard/items/image-item/image-item.class';
import type {SlideshowItemObject} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import {getMaxSlideshowDuration} from '@PosterWhiteboard/items/slideshow-item/slideshow-slides.class';
import {openMessageModal} from '@Modals/message-modal';
import type {ImageBackgroundItemObject} from '@PosterWhiteboard/page/background/image-background-item.class';
import type {CropperPanelApplyResponse} from '@Panels/cropper-panel';
import {openCropperModal} from '@Modals/cropper-modal/cropper-modal.library';
import {BackgroundTypeName} from '@PosterWhiteboard/page/background/background.class';
import type {FancyTextItemObject} from '@PosterWhiteboard/items/fancy-text-item/fancy-text-item.class';
import {USER_VIDEO_SOURCE} from '@Libraries/user-video-library';
import {
  onOpenTrimVideoModal,
  openExtractedGettyStickerLimitReachedMessageModal,
  openGettyImageLimitReachedMessageModal,
  openGettyVideoLimitReachedMessageModal,
  openVideoLimitReachedMessageModal,
} from '@Components/poster-editor/library/poster-editor-open-modals';
import {FillTypes} from '@PosterWhiteboard/classes/fill.class';
import type {VectorItem} from '@PosterWhiteboard/items/vector-item/vector-item.class';
import {MAX_ALLOWED_GRAPHIC_ITEMS, MAX_STICKER_ITEMS} from '@PosterWhiteboard/page/page.types';
import {POSTER_GETTY_LIMIT, POSTER_MAX_DURATION} from '@PosterWhiteboard/poster/poster.types';
import {GA4EventName, GA4EventParam, GA4EventParamName, trackPosterBuilderGA4Events} from '@Libraries/ga-events';
import {AlignType} from '@PosterWhiteboard/page/alignment.class';
import {DEFAULT_SLIDE_DURATION} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.types';
import {LayoutTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import {arePathsCustomizable, loadVectorSVG, VectorItemSource} from '@PosterWhiteboard/items/vector-item/vector-item.library';
import {createItemFromObject} from '@PosterWhiteboard/items/item/item-factory';
import type {TranscriptItemObject} from '@PosterWhiteboard/items/transcript-item/transcript-item.types';
import {TRANSCRIPT_ITEM_PADDING} from '@PosterWhiteboard/items/transcript-item/transcript-item.types';
import {getUniqueString} from '@Utils/string.util';
import type {SubtitleObject} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle.types';
import {ALL_TEMPLATE_STYLES, getDefaultSelectedSubtitleTemplateProperties} from '@PosterWhiteboard/items/transcript-item/subtitle/template-styles';
import {ADD_POSTER_AUDIO_DURATION_LIMIT_LEEWAY} from '@PosterWhiteboard/classes/audio-clips/audio-clips.class';
import {getAudioUrl} from '@Libraries/user-audio-library';
import type {AudioItem, AudioItemObject} from '@PosterWhiteboard/classes/audio-clips/audio-item.class';
import {updateBottomWebSeekbarState} from '@Components/poster-editor/poster-editor-reducer';
import {openTimelineModal} from '@Modals/timeline-modal/timeline-modal';
import {isEditorMobileVariant} from '@Components/poster-editor/library/poster-editor-library';
import type {TranscriptItem} from '@PosterWhiteboard/items/transcript-item/transcript-item';
import type {RectangleItem} from '@PosterWhiteboard/items/rectangle-item/rectangle-item';
import type {LineItem} from '@PosterWhiteboard/items/line-item/line-item';
import {PMW_SHAPE_TO_RECTANGLE_HASHMAP} from '@PosterWhiteboard/items/rectangle-item/pmw_shape_to_rectangle_hashmap';
import {PMW_STOCK_IMAGE_EXTENSION, PMWStockImageSource} from '@/libraries/pmw-stock-media-library.types';
import {ImageItemSource} from '@/libraries/image-item.library';
import type {DeepPartial} from '@/global';
import {DEFAULT_STROKE_WIDTH, TextHorizontalAlignType} from '../classes/text-styles.class';
import {getCompatibleImageFileExtension} from '@Utils/image.util';
import {colorToRGBA} from '@Utils/color.util';
import type {FabricObject} from '@postermywall/fabricjs-2';

const DEFAULT_X_Y = 20;
export const DEFAULT_FONT_SIZE = 15;
export const MINIMUM_FONT_SIZE_WITH_ADJUSTED_WIDTH = 20;
const ICON_ADD_POSTER_RATIO = 0.2;
const SMALL_ITEM_ADD_POSTER_RATIO = 0.3;
const DEFAULT_ITEM_ADD_POSTER_RATIO = 0.6;

export const OFFSET = 15;

export interface ItemCoordinates {
  x: number;
  y: number;
}

interface AddItemsOptions extends AddItemOpts {
  selectOnAdd?: boolean;
}

interface Scales {
  scaleX: number;
  scaleY: number;
}

export interface AddItemObject {
  item: ItemData;
  optionsToApply?: DeepPartial<ItemObject>;
}

interface NewItemData {
  x?: number;
  y?: number;
  scaleX?: number;
  scaleY?: number;
  // TODO: Remove width and height from here. The item mediator code decides the height & width
  width?: number;
  height?: number;
  /**
   * If given scales the item to fit this width before adding it to canvas.
   * Ignores ScaleX and ScaleY in this case even if provided
   */
  scaledWidth?: number;
  scaledHeight?: number;
  uid?: string;
  /**
   * If true scales the new item such that one value from scaledWidth/scaledHeight matches the scale while the other is equal
   * or lower to maintain aspect ratio
   */
  maintainAspectRatioForScaledDimension?: boolean;
}

export type ItemData = (ElementData | ElementDataWithUid) & NewItemData;

export type NewImageItemData = ImageData & NewItemData;
type NewPMWShapeItemData = PMWShapeData & NewItemData;
type NewPMWIconItemData = PMWIconData & NewItemData;
type NewStickerItemData = AnimatedStickerData & NewItemData;
type NewStillStickerItemData = PMWStillStickerData & NewItemData;
type NewExtractedGettyStickerItemData = PMWExtractedGettyStickerData & NewItemData;
type NewVideoItemData = VideoData & NewItemData;
type NewFancyTextItemData = FancyTextData & NewItemData;
export type NewTranscriptData = TranscriptData & NewItemData;
export type NewTempImageItemData = TempImageData & NewItemData;

export const isNewImageDataTempImageData = (newImageData: NewImageItemData | NewTempImageItemData): newImageData is NewTempImageItemData => {
  return 'dataUrl' in newImageData;
};

export class AddItem {
  public page: Page;
  public offsetMultiplier: number;

  public constructor(page: Page) {
    this.page = page;
    this.offsetMultiplier = 0;
  }

  private incrementOffset(): void {
    if (this.offsetMultiplier === 2) {
      this.offsetMultiplier = 0;
    }

    this.offsetMultiplier += 1;
  }

  public prepareObjectWithCommonOptions<T extends ItemData>(item: T): T {
    const coordinates = this.getCoordinatesForNewItem();

    return {
      ...item,
      x: item.x ?? coordinates.x,
      y: item.y ?? coordinates.y,
    };
  }

  public getCoordinatesForNewItem(): ItemCoordinates {
    const scrollTop = window.posterEditor?.elements[GlobalPosterEditorJqueryElement.POSTER_VERTICAL_SCROLL]?.get(0)?.scrollTop ?? 0;
    const scrollLeft = window.posterEditor?.elements[GlobalPosterEditorJqueryElement.POSTER_HORIZONTAL_SCROLL]?.get(0)?.scrollLeft ?? 0;
    const scale = window.posterEditor?.whiteboard?.scaling.scale;
    const widthOfThePoster = window.posterEditor?.whiteboard?.width;
    const heightOfThePoster = window.posterEditor?.whiteboard?.height;

    if (!scale || !heightOfThePoster || !widthOfThePoster) {
      return {
        x: DEFAULT_X_Y,
        y: DEFAULT_X_Y,
      };
    }

    const x = (scrollLeft + widthOfThePoster / 10) / Math.max(scale, 1) + this.offsetMultiplier * OFFSET;
    const y = (scrollTop + heightOfThePoster / 8) / Math.max(scale, 1) + this.offsetMultiplier * OFFSET;
    return {
      x,
      y,
    };
  }

  public getBaseWidthForText(fontSize: number, text: string): number {
    const widthOfThePoster = window.posterEditor?.whiteboard?.width;
    if (widthOfThePoster && widthOfThePoster <= 350) {
      return Math.floor(widthOfThePoster * 0.6667);
    }
    return fontSize * (text.length / 2);
  }

  public async addItemToPage(itemToAdd: ItemType, {undoable = true, updateRedux = true, zIndex, selectOnAdd = true}: AddItemsOptions = {}): Promise<boolean> {
    if (!this.canAddItem(itemToAdd)) {
      return false;
    }

    if (this.page.poster.drawing.isDrawModeOn) {
      this.page.poster.drawing.disableDrawMode();
    }
    await this.page.addItem(itemToAdd, {
      undoable,
      updateRedux,
      zIndex,
      checkForDurationUpdate: true,
    });
    this.incrementOffset();
    if (selectOnAdd) {
      this.page.activeSelection.selectFabricObjects([itemToAdd.fabricObject]);
    }
    if (itemToAdd.isPremium()) {
      this.page.pageWatermark.refreshWatermark();
    }
    return true;
  }

  private canAddItem(item: ItemType): boolean {
    if (Object.keys(this.page.items.itemsHashMap).length >= MAX_ALLOWED_GRAPHIC_ITEMS) {
      openMessageModal({
        title: window.i18next.t('pmwjs_maximum_graphic_items_limit_reached_title'),
        text: window.i18next.t('pmwjs_maximum_graphic_items_limit_reached'),
      });
      return false;
    }

    if (item.isVideo()) {
      if (item.hasGettyContent() && this.page.poster.hasMaxNumberofGettyVideoItems()) {
        openGettyVideoLimitReachedMessageModal();
        return false;
      }

      if (this.page.items.hasMaxNumberOfVideoContainingItems()) {
        openVideoLimitReachedMessageModal();
        return false;
      }
    } else if (item.isSticker() && this.page.items.hasMaxNumberOfStickerItems()) {
      openMessageModal({
        title: window.i18next.t('pmwjs_sticker_limit'),
        text: window.i18next.t('pmwjs_sticker_limit_message', {
          num: MAX_STICKER_ITEMS,
        }),
      });
      return false;
    } else if (item.isImage() && item.isNonPurchasedExtractedGettySticker() && this.page.poster.hasMaxNumberOfExtractedGettyStickers()) {
      openExtractedGettyStickerLimitReachedMessageModal();
      return false;
    } else if (item.isImage() && item.isNonPurchasedGettyImage() && this.page.poster.hasMaxNumberOfGettyImages()) {
      openGettyImageLimitReachedMessageModal();
      return false;
    } else if (item.isSlideshow()) {
      if (item.slides.hasGettyImageSlide() && this.page.poster.hasMaxNumberOfGettyImages()) {
        return false;
      }

      const posterHasMaxNumberOfGettyVideoItems = this.page.poster.hasMaxNumberofGettyVideoItems();

      if (item.slides.hasGettyVideoSlide() && posterHasMaxNumberOfGettyVideoItems) {
        openGettyVideoLimitReachedMessageModal();
        return false;
      }
      if (item.slides.hasVideoSlide() && this.page.items.hasMaxNumberOfVideoContainingItems()) {
        openVideoLimitReachedMessageModal();
        return false;
      }
    }

    return true;
  }

  public async addNewItems(items: ItemData[], addItemOpts: AddItemsOptions = {}): Promise<void> {
    const preparedItemObjects = [];
    for (let index = 0; index < items.length; index += 1) {
      const addItemObject: AddItemObject = {
        item: items[index],
        optionsToApply: {},
      };
      preparedItemObjects.push(addItemObject);
    }
    await this.addItems(preparedItemObjects, addItemOpts);
  }

  private async addItemsSequentially(items: AddItemObject[], addItemOpts: AddItemsOptions = {}): Promise<void> {
    showLoading('loading');

    for (let index = 0; index < items.length; index += 1) {
      const preparedItem = this.prepareObjectWithCommonOptions(items[index].item);
      const addItemObject = {
        item: preparedItem,
        optionsToApply: items[index].optionsToApply,
      };
      // This is disabled because for videos we don't want to add in parallel since the video being added later
      // depends on the first

      await this.addItem(addItemObject, addItemOpts);
    }

    hideLoading('loading');
  }

  private async addItemsInParallel(items: AddItemObject[], addItemOpts: AddItemsOptions = {}): Promise<void> {
    const promises: Promise<void>[] = [];
    showLoading('loading');

    for (let index = 0; index < items.length; index += 1) {
      const preparedItem = this.prepareObjectWithCommonOptions(items[index].item);
      const addItemObject = {
        item: preparedItem,
        optionsToApply: items[index].optionsToApply,
      };
      const addItemPromise = this.addItem(addItemObject, addItemOpts);
      promises.push(addItemPromise);
    }

    try {
      await Promise.all(promises);
    } finally {
      hideLoading('loading');
    }
  }

  private shouldAddItemsSequentially(items: AddItemObject[]): boolean {
    for (const item of items) {
      if (item.item.type !== ElementDataType.VIDEO && item.item.type !== ElementDataType.AUDIO) {
        return false;
      }
    }
    return true;
  }

  private async addItems(items: AddItemObject[], addItemOpts: AddItemsOptions = {}): Promise<void> {
    if (this.shouldAddItemsSequentially(items)) {
      return this.addItemsSequentially(items, addItemOpts);
    }

    return this.addItemsInParallel(items, addItemOpts);
  }

  private async addItem(itemToAdd: AddItemObject, addItemOpts: AddItemsOptions = {}): Promise<void> {
    switch (itemToAdd.item.type) {
      case ElementDataType.TEXT:
        await this.addTextItem(itemToAdd.item);
        return;
      case ElementDataType.QR_CODE:
        await this.addQRItem(itemToAdd.item);
        return;
      case ElementDataType.IMAGE:
        await this.addImageItem(itemToAdd.item);
        return;
      case ElementDataType.PMW_SHAPE:
        await this.addPMWShape(itemToAdd.item);
        return;
      case ElementDataType.ANIMATED_STICKER:
        await this.addStickerItem(itemToAdd.item);
        return;
      case ElementDataType.VIDEO:
        await this.addVideoItem(itemToAdd.item, {}, addItemOpts);
        return;
      case ElementDataType.AUDIO:
        await this.addAudioItems([itemToAdd.item], addItemOpts);
        return;
      case ElementDataType.FANCY_TEXT:
        await this.addFancyTextItem(itemToAdd.item);
        return;
      case ElementDataType.TAB:
        await this.addTabItem(itemToAdd.item);
        return;
      case ElementDataType.ICON:
        await this.addPMWIconItem(itemToAdd.item);
        return;
      case ElementDataType.STILL_STICKER:
        await this.addPMWStillStickerItem(itemToAdd.item);
        return;
      case ElementDataType.EXTRACTED_GETTY_STICKER:
        await this.addPMWExtractedGettyStickerItem(itemToAdd.item);
        return;
      default:
        throw new Error('unhandled item type sent to add new item');
    }
  }

  public async onAddMediaSlideshow(elementsData: (ImageData | TempImageData | VideoData | PMWStillStickerData | PMWExtractedGettyStickerData)[]): Promise<void> {
    if (elementsData.length > 0) {
      const mediaSlideDataType = elementsData[0].type;
      if (mediaSlideDataType === ElementDataType.IMAGE || mediaSlideDataType === ElementDataType.STILL_STICKER || mediaSlideDataType === ElementDataType.EXTRACTED_GETTY_STICKER) {
        return this.onAddImageSlideshow(elementsData as (ImageData | TempImageData)[]);
      }
      if (mediaSlideDataType === ElementDataType.VIDEO) {
        return this.onAddVideoSlideshow(elementsData as VideoData[]);
      }
    } else {
      throw new Error('no grid items selected in onAddMediaSlideshow');
    }

    throw new Error('unexpected type received in onAddMediaSlideshow');
  }

  public async onAddImageSlideshow(elementsData: (ImageData | TempImageData | PMWStillStickerData | PMWExtractedGettyStickerData)[]): Promise<void> {
    showLoading('prepareAndAddImageSlides');
    const orderedImageSlidesUIDs: string[] = [];
    const slidesHashmap: Record<string, SlideItemObject> = {};

    let durationLimitReached = false;
    let additionalDuration = 0;

    const preparedItem = this.prepareObjectWithCommonOptions({
      type: ElementDataType.SLIDESHOW,
    });
    for (const elementData of elementsData) {
      if (additionalDuration >= getMaxSlideshowDuration()) {
        durationLimitReached = true;
        break;
      }

      let slideDuration = DEFAULT_SLIDE_DURATION;

      if (additionalDuration + slideDuration > getMaxSlideshowDuration()) {
        slideDuration = getMaxSlideshowDuration() - additionalDuration;
      }

      additionalDuration += slideDuration;

      const slideId = 'uid' in elementData ? elementData.uid : uuidv4();
      orderedImageSlidesUIDs.push(slideId);

      const getSourceForStillSticker = (type: ElementDataType.STILL_STICKER | ElementDataType.EXTRACTED_GETTY_STICKER): string => {
        return type === ElementDataType.EXTRACTED_GETTY_STICKER ? PMWStockImageSource.PMW_EXTRACTED_GETTY_STICKER : PMWStockImageSource.PMW_STILL_STICKER;
      };

      slidesHashmap[slideId] = {
        gitype: ITEM_TYPE.IMAGESLIDE,
        imageSource: 'source' in elementData ? elementData.source : getSourceForStillSticker(elementData.type),
        uid: slideId,
      } as SlideItemObject;

      if ('hashedFilename' in elementData) {
        slidesHashmap[slideId] = {
          ...slidesHashmap[slideId],
          fileExtension: 'extension' in elementData ? elementData.extension : getCompatibleImageFileExtension(PMW_STOCK_IMAGE_EXTENSION),
          hashedFilename: elementData.hashedFilename,
        };
      } else {
        slidesHashmap[slideId] = {
          ...slidesHashmap[slideId],
          uploadingImageData: {
            tempUploadingImageUID: slideId,
            dataUrl: elementData.dataUrl,
            uploadStartTime: new Date().getTime(),
          },
        };
      }
    }

    if (durationLimitReached) {
      openMessageModal({
        title: window.i18next.t('pmwjs_maximum_slideshow_duration_reached_title'),
        text: window.i18next.t('pmwjs_maximum_slideshow_duration_reached'),
      });
    }

    const imageSlideshowItem = await createItemFromObject(this.page, {
      ...preparedItem,
      gitype: ITEM_TYPE.SLIDESHOW,
      slides: {
        slidesOrder: orderedImageSlidesUIDs,
        slidesHashMap: slidesHashmap,
      },
      introAnimationPadding: this.page.introAnimation.getAnimationMaxDuration() ?? 0,
    });

    hideLoading('prepareAndAddImageSlides');
    this.addItemToPage(imageSlideshowItem);

    trackPosterBuilderGA4Events(GA4EventName.ADDED_SLIDESHOW_ANIMATION, {[GA4EventParamName.TYPE]: GA4EventParam.IMAGE});
  }

  public async onAddVideoSlideshow(elementsData: VideoData[]): Promise<void> {
    // showLoading('onAddVideoSlideshow');
    let gettyVideosCount = this.page.poster.getGettyVideoCount();
    let gettyLimitReached = false;

    const orderedVideoSlidesUIDs: string[] = [];
    const slidesHashmap: Record<string, SlideItemObject> = {};

    let durationLimitReached = false;
    let additionalDuration = 0;

    const preparedItem = this.prepareObjectWithCommonOptions({
      type: ElementDataType.SLIDESHOW,
    });
    for (const elementData of elementsData) {
      if (elementData.source === USER_VIDEO_SOURCE.GETTY) {
        if (gettyVideosCount >= POSTER_GETTY_LIMIT.VIDEOS) {
          gettyLimitReached = true;
          break;
        }

        gettyVideosCount += 1;
      }

      if (additionalDuration >= getMaxSlideshowDuration()) {
        durationLimitReached = true;
        break;
      }

      let slideDuration = elementData.duration;

      if (additionalDuration + slideDuration > getMaxSlideshowDuration()) {
        slideDuration = getMaxSlideshowDuration() - additionalDuration;
      }

      additionalDuration += slideDuration;

      const slideId = uuidv4();
      orderedVideoSlidesUIDs.push(slideId);

      slidesHashmap[slideId] = {
        gitype: ITEM_TYPE.VIDEOSLIDE,
        videoSource: elementData.source,
        fileExtension: elementData.extension,
        hashedFilename: elementData.hashedFilename,
        duration: elementData.duration,
        frameRate: elementData.frameRate,
        uid: slideId,
        slideDuration,
      } as SlideItemObject;
    }

    if (gettyLimitReached) {
      openGettyVideoLimitReachedMessageModal();
    } else if (durationLimitReached) {
      openMessageModal({
        title: window.i18next.t('pmwjs_maximum_slideshow_duration_reached_title'),
        text: window.i18next.t('pmwjs_maximum_slideshow_duration_reached'),
      });
    }

    const videoSlideshowItem = await createItemFromObject(this.page, {
      ...preparedItem,
      gitype: ITEM_TYPE.SLIDESHOW,
      slides: {
        slidesOrder: orderedVideoSlidesUIDs,
        slidesHashMap: slidesHashmap,
      },
      introAnimationPadding: this.page.introAnimation.getAnimationMaxDuration() ?? 0,
    });

    // hideLoading('onAddVideoSlideshow');
    this.addItemToPage(videoSlideshowItem);

    trackPosterBuilderGA4Events(GA4EventName.ADDED_SLIDESHOW_ANIMATION, {[GA4EventParamName.TYPE]: GA4EventParam.VIDEO});
  }

  public async getTabItem(item: ItemData, optionsToAdd?: DeepPartial<TabsItemObject>): Promise<ItemType> {
    if (item.type !== ElementDataType.TAB) {
      throw new Error('type mismatch in getting tab item');
    }
    const text = item.text ?? `${window.i18next.t('pmwjs_contact_name')}\n${window.i18next.t('pmwjs_contact_number')}`;
    const widthOfThePoster = window.posterEditor?.whiteboard?.width;
    const heightOfThePoster = window.posterEditor?.whiteboard?.height;

    if (!widthOfThePoster || !heightOfThePoster) {
      throw new Error('Could not get width and height of the poster');
    }
    const fontSizeMagicNumber = 15; // Pre-multi aspect ratio font size for a portrait 600x900 was 40, so this value came from 600/40
    const fontSizeLowerLimit = widthOfThePoster > heightOfThePoster ? widthOfThePoster / fontSizeMagicNumber / 2 : heightOfThePoster / fontSizeMagicNumber / 2;
    const initialTextLineHeight = Math.max(
      widthOfThePoster < heightOfThePoster ? widthOfThePoster / fontSizeMagicNumber : heightOfThePoster / fontSizeMagicNumber,
      fontSizeLowerLimit
    );
    return createItemFromObject(this.page, {
      ...item,
      x: widthOfThePoster - ALIGNMENT_PADDING / 2,
      y: heightOfThePoster,
      numTabs: Math.round(widthOfThePoster / (initialTextLineHeight * 2 * 0.75)),
      height: heightOfThePoster / 4,
      width: widthOfThePoster - ALIGNMENT_PADDING,
      rotation: 180,
      gitype: ITEM_TYPE.TAB,
      text,
      separatorType: SeparatorType.DASHED,
      separatorColor: [100, 100, 100, 1],
      backgroundType: 1,
      backgroundColor: [255, 255, 255, 0.75],
      textStyles: {
        fontFamily: 'OpenSansRegular',
        fontWeight: 'normal',
        fontStyle: 'normal',
        textAlign: TextHorizontalAlignType.RIGHT,
      },
      scaleX: 1,
      scaleY: 1,
      ...optionsToAdd,
    });
  }

  public getTableItem(item: TableData, optionsToAdd?: DeepPartial<TableItemObject>): Promise<ItemType> {
    const tableItemObject = {
      ...item,
      ...this.getDefaultTableOptions(),
      gitype: ITEM_TYPE.TABLE,
    };
    const layoutDataMap = invalidateTableDataForCustomTableLayout(item);
    const layoutStyle = LayoutTypes.CUSTOM_TABLE_LAYOUT;
    const optionalProps: DeepPartial<TableItemObject> = {};

    return createItemFromObject(this.page, {
      ...tableItemObject,
      layoutDataMap,
      unusedData: null,
      layoutStyle,
      textStyles: {
        ...tableItemObject.textStyles,
        fontFamily: this.getRecommendedFont(layoutStyle),
      },
      fontFamily2: this.getRecommendedFont(layoutStyle),
      ...optionalProps,
      ...optionsToAdd,
    });
  }

  public getScheduleItem(item: ScheduleLayoutData, optionsToAdd?: DeepPartial<TableItemObject>): Promise<ItemType> {
    const tableItemObject = {
      ...item,
      ...this.getDefaultTableOptions(),
      gitype: ITEM_TYPE.TABLE,
    };
    const layoutDataMap = invalidateTableDataForFixedLayout(item);
    const {layoutStyle} = tableItemObject;
    const optionalProps: DeepPartial<TableItemObject> = {};

    if (layoutStyle === LayoutTypes.SPORTS_LAYOUT) {
      optionalProps.ySpacing = SPORTS_LAYOUT_Y_SPACING;
    } else if (layoutStyle === LayoutTypes.OFFSET_SPORTS_LAYOUT) {
      optionalProps.ySpacing = OFFSET_LAYOUT_Y_SPACING;
    }
    if (layoutStyle === LayoutTypes.SLANTED_SPORTS_LAYOUT || layoutStyle === LayoutTypes.STRAIGHT_SPORTS_LAYOUT) {
      optionalProps.backgroundType = 0;
    }

    return createItemFromObject(this.page, {
      ...tableItemObject,
      layoutDataMap,
      unusedData: invalidateUnusedData(item),
      layoutStyle,
      textStyles: {
        ...tableItemObject.textStyles,
        fontFamily: this.getRecommendedFont(layoutStyle),
      },
      fontFamily2: this.getRecommendedFont(layoutStyle),
      ...optionalProps,
      ...optionsToAdd,
    });
  }

  public getMenuItem(item: MenuData, optionsToAdd?: DeepPartial<MenuItemObject>): Promise<ItemType> {
    const menuItemObject = {
      ...item,
      ...this.getDefaultMenuOptions(),
      gitype: ITEM_TYPE.MENU,
    };

    const {layoutStyle} = item;
    const optionalBorderOpts: DeepPartial<ItemBorderObject> = {};

    if (layoutStyle === LayoutTypes.MENU_LAYOUT_4) {
      optionalBorderOpts.solidBorderType = BorderType.RECTANGLE_BORDER;
    }

    const dim = getSpacingValuesForMenuLayouts(item.layoutStyle);
    return createItemFromObject(this.page, {
      ...menuItemObject,
      xSpacing: dim.x,
      ySpacing: dim.y,
      itemIds: item.itemIds,
      layoutDataMap: item.layoutDataMap,
      layoutStyle,
      rows: item.rows,
      columns: item.columns,
      textStyles: {
        ...menuItemObject.textStyles,
        fontFamily: DEFAULT_FONT_FAMILY,
      },
      fontFamily2: DEFAULT_FONT_FAMILY2,
      border: {
        ...menuItemObject.border,
        ...optionalBorderOpts,
      },
      ...optionsToAdd,
    });
  }

  public getDefaultTableOptions(): DeepPartial<TableItemObject> {
    const {poster} = this.page;
    const posterWidth = poster.width;
    const posterHeight = poster.height;
    const fontSizeMagicNumber = 15;
    const fontSizeLowerLimit = posterWidth > posterHeight ? posterWidth / fontSizeMagicNumber / 2 : posterHeight / fontSizeMagicNumber / 2;
    const fontSize = Math.max(posterWidth < posterHeight ? posterWidth / fontSizeMagicNumber : posterHeight / fontSizeMagicNumber, fontSizeLowerLimit) / 2;

    return {
      textStyles: {
        fontSize,
        fontWeight: 'normal',
        fontStyle: 'normal',
      },
      width: 100,
      height: 100,
      scaleX: 1,
      scaleY: 1,
      backgroundColor: [255, 255, 255, 0.5],
      backgroundType: 1,
      border: {solidBorderThickness: 2},
      xSpacing: fontSize,
      ySpacing: fontSize / 2,
    };
  }

  public getDefaultMenuOptions(): DeepPartial<MenuItemObject> {
    const {poster} = this.page;
    const posterWidth = poster.width;
    const posterHeight = poster.height;
    const fontSizeMagicNumber = 15;
    const fontSizeLowerLimit = posterWidth > posterHeight ? posterWidth / fontSizeMagicNumber / 2 : posterHeight / fontSizeMagicNumber / 2;
    const fontSize = Math.max(posterWidth < posterHeight ? posterWidth / fontSizeMagicNumber : posterHeight / fontSizeMagicNumber, fontSizeLowerLimit) / 3;

    return {
      textStyles: {
        fontSize,
        fontWeight: 'normal',
        fontStyle: 'normal',
      },
      width: 100,
      height: 100,
      scaleX: 1,
      scaleY: 1,
      backgroundColor: [255, 255, 255, 0.5],
      backgroundType: 0,
      border: {solidBorderThickness: 2},
      iconsSize: fontSize,
    };
  }

  /**
   * Returns the preferred font family to use.
   */
  public getRecommendedFont(layoutStyle: string): string {
    switch (layoutStyle) {
      case LayoutTypes.SLANTED_SPORTS_LAYOUT:
      case LayoutTypes.STRAIGHT_SPORTS_LAYOUT:
        return 'LeagueSpartanBold';
      default:
        return 'OpenSansRegular';
    }
  }

  public async addTextItem(item: ItemData, optionsToAdd?: DeepPartial<TextItemObject>): Promise<void> {
    const key = getUniqueString();
    showLoading(key);
    const preparedItem = this.prepareObjectWithCommonOptions(item);
    const textItem = await this.getTextItem(preparedItem, optionsToAdd);
    await this.addItemToPage(textItem);
    hideLoading(key);
  }

  public async addTranscriptItem(item: NewTranscriptData, opts: AddTranscriptOpts): Promise<void> {
    const key = getUniqueString();
    showLoading(key);
    const preparedItem = this.prepareObjectWithCommonOptions(item);
    const transcriptItem = await this.getTranscriptItem(preparedItem, opts.optionsToAdd);
    await this.addItemToPage(transcriptItem, {selectOnAdd: opts.selectOnAdd});
    const addedTranscriptItem = transcriptItem as TranscriptItem;
    const allTranscriptItems = this.page.items.getAllTranscriptItems();
    if (!opts.optionsToAdd?.x && !opts.optionsToAdd?.y) {
      await addedTranscriptItem.updateFabricObjectPositionAndSize(allTranscriptItems.length);
    }
    hideLoading(key);
  }

  public async addTextSlideshowItem(item: ItemData, optionsToAdd?: DeepPartial<SlideshowItemObject>): Promise<void> {
    showLoading('loading');
    const preparedItem = this.prepareObjectWithCommonOptions(item);
    const textSlideshowItem = await this.getTextSlideshowItem(preparedItem, optionsToAdd);
    await this.addItemToPage(textSlideshowItem);
    hideLoading('loading');
  }

  public async addTabItem(item: ItemData, optionsToAdd?: DeepPartial<TabsItemObject>): Promise<void> {
    showLoading('loading');
    const preparedItem = this.prepareObjectWithCommonOptions(item);
    const tableItem = await this.getTabItem(preparedItem, optionsToAdd);
    await this.addItemToPage(tableItem);
    this.page.activeSelection.alignment.alignItems(AlignType.BOTTOM, false);
    hideLoading('loading');
  }

  public async addTableItem(item: TableData, optionsToAdd?: DeepPartial<TableItemObject>, doShowLoading = false): Promise<void> {
    if (doShowLoading) {
      showLoading('AddingTableItem');
    }
    const preparedItem = this.prepareObjectWithCommonOptions(item) as TableData & DeepPartial<TableItemObject>;
    const tableItem = await this.getTableItem(preparedItem, optionsToAdd);
    await this.addItemToPage(tableItem);
    if (doShowLoading) {
      hideLoading('AddingTableItem');
    }
  }

  public async addScheduleItem(item: ScheduleLayoutData, optionsToAdd?: DeepPartial<TableItemObject>, doShowLoading = false): Promise<void> {
    if (doShowLoading) {
      showLoading('AddingScheduleItem');
    }
    const preparedItem = this.prepareObjectWithCommonOptions(item) as ScheduleLayoutData & DeepPartial<TableItemObject>;
    const scheduleItem = await this.getScheduleItem(preparedItem, optionsToAdd);
    await this.addItemToPage(scheduleItem);
    if (doShowLoading) {
      hideLoading('AddingScheduleItem');
    }
  }

  public async addMenuItem(item: MenuData, optionsToAdd?: DeepPartial<TableItemObject>, doShowLoading = false): Promise<void> {
    if (doShowLoading) {
      showLoading('AddingMenuItem');
    }
    const preparedItem = this.prepareObjectWithCommonOptions(item) as MenuData & DeepPartial<MenuItemObject>;
    const menuItem = await this.getMenuItem(preparedItem, optionsToAdd);
    await this.addItemToPage(menuItem);
    if (doShowLoading) {
      hideLoading('AddingMenuItem');
    }
  }

  public async getQRItem(item: ItemData, optionsToAdd?: DeepPartial<QRCodeItemObject>): Promise<ItemType> {
    if (item.type !== ElementDataType.QR_CODE) {
      throw new Error('type mismatch in get qr item');
    }

    return createItemFromObject(this.page, {
      ...item,
      gitype: ITEM_TYPE.QR_CODE,
      ...optionsToAdd,
    });
  }

  public async addQRItem(itemData: ItemData, optionsToAdd?: DeepPartial<QRCodeItemObject>): Promise<void> {
    const preparedItem = this.prepareObjectWithCommonOptions(itemData);
    const qrItem = await this.getQRItem(preparedItem, optionsToAdd);
    await this.scaleNewItemBeforeAdd(qrItem, itemData, SMALL_ITEM_ADD_POSTER_RATIO);
    await this.addItemToPage(qrItem);
  }

  public async addImageItem(itemData: NewImageItemData | NewTempImageItemData, optionsToAdd?: DeepPartial<ItemObject>, addItemOpts: AddItemsOptions = {}): Promise<void> {
    const preparedItemData = this.prepareObjectWithCommonOptions(itemData);
    const imageItem = isNewImageDataTempImageData(preparedItemData)
      ? await this.getTempImageItem(preparedItemData, optionsToAdd)
      : await this.getImageItem(preparedItemData, optionsToAdd);
    await this.scaleNewItemBeforeAdd(imageItem, itemData);
    await this.addItemToPage(imageItem, addItemOpts);
  }

  public async addPMWIconItem(itemData: NewPMWIconItemData, optionsToAdd?: DeepPartial<ImageItemObject>, addItemOpts: AddItemsOptions = {}): Promise<void> {
    const preparedItemData = this.prepareObjectWithCommonOptions(itemData);
    const imageItem = await this.getImageItemForPMWIcon(preparedItemData, optionsToAdd);
    await this.scaleNewItemBeforeAdd(imageItem, itemData, ICON_ADD_POSTER_RATIO);
    await this.addItemToPage(imageItem, addItemOpts);
  }

  public async addPMWStillStickerItem(itemData: NewStillStickerItemData, optionsToAdd?: DeepPartial<ImageItemObject>, addItemOpts: AddItemsOptions = {}): Promise<void> {
    const preparedItemData = this.prepareObjectWithCommonOptions(itemData);
    const imageItem = await this.getImageItemForPMWStillSticker(preparedItemData, optionsToAdd);
    await this.scaleNewItemBeforeAdd(imageItem, itemData, SMALL_ITEM_ADD_POSTER_RATIO);
    await this.addItemToPage(imageItem, addItemOpts);
  }

  public async addPMWExtractedGettyStickerItem(
    itemData: NewExtractedGettyStickerItemData,
    optionsToAdd?: DeepPartial<ImageItemObject>,
    addItemOpts: AddItemsOptions = {}
  ): Promise<void> {
    const preparedItemData = this.prepareObjectWithCommonOptions(itemData);
    const imageItem = await this.getImageItemForPMWExtractedGettySticker(preparedItemData, optionsToAdd);
    await this.scaleNewItemBeforeAdd(imageItem, itemData, SMALL_ITEM_ADD_POSTER_RATIO);
    await this.addItemToPage(imageItem, addItemOpts);
  }

  public async addPMWShape(itemData: NewPMWShapeItemData, optionsToAdd?: DeepPartial<VectorItemObject>): Promise<void> {
    const preparedItem = this.prepareObjectWithCommonOptions(itemData);
    const item = await this.getPMWShapeItem(preparedItem, optionsToAdd);
    await this.scaleNewItemBeforeAdd(item, itemData, SMALL_ITEM_ADD_POSTER_RATIO);

    await item.updateFromObject(
      {
        border: {
          solidBorderThickness: item.border.solidBorderThickness * item.fabricObject.scaleX,
        },
      },
      {
        undoable: false,
      }
    );
    await this.addItemToPage(item);
  }

  private async convertScaleToDimensions(item: RectangleItem): Promise<void> {
    await item.updateFromObject(
      {
        width: item.getScaledWidth(),
        height: item.getScaledHeight(),
        scaleX: 1,
        scaleY: 1,
      },
      {
        undoable: false,
        updateRedux: false,
      }
    );
  }

  public async addStickerItem(itemData: NewStickerItemData, optionsToAdd?: DeepPartial<StickerItemObject>, addItemOpts: AddItemsOptions = {}): Promise<void> {
    const preparedItem = this.prepareObjectWithCommonOptions(itemData);
    const stickerItem = await this.getStickerItem(preparedItem, optionsToAdd);
    await this.scaleNewItemBeforeAdd(stickerItem, itemData, SMALL_ITEM_ADD_POSTER_RATIO);
    await this.addItemToPage(stickerItem, addItemOpts);
  }

  public async addVideoItem(itemData: NewVideoItemData, optionsToAdd?: DeepPartial<VideoItemObject>, addItemOpts: AddItemsOptions = {}): Promise<void> {
    const preparedItem = this.prepareObjectWithCommonOptions(itemData);
    const videoItem = await this.getVideoItem(preparedItem, optionsToAdd);
    await this.scaleNewItemBeforeAdd(videoItem, itemData);
    await this.addItemToPage(videoItem, addItemOpts);

    return new Promise((resolve) => {
      if (this.isVideoAbovePosterDurationLimits(itemData)) {
        trackPosterBuilderGA4Events(GA4EventName.VIDEO_LIMIT_DIALOG);
        onOpenTrimVideoModal(videoItem as VideoItem, true, (): void => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  public isVideoAbovePosterDurationLimits(item: VideoData): boolean {
    return item.duration > POSTER_MAX_DURATION;
  }

  public async addFancyTextItem(itemData: NewFancyTextItemData, optionsToAdd?: DeepPartial<FancyTextItemObject>): Promise<void> {
    const preparedItemData = this.prepareObjectWithCommonOptions(itemData);
    const fancyTextItem = await this.getFancyTextItem(preparedItemData, optionsToAdd);

    await this.scaleNewItemBeforeAdd(fancyTextItem, itemData);
    await this.addItemToPage(fancyTextItem);
  }

  public async addItemsFromItemType(items: ItemType[]): Promise<void> {
    showLoading('loading');
    const itemObjects = [];
    const fabricObjects = [];
    for (const item of items) {
      itemObjects.push(createItemFromObject(this.page, item.toObject()));
    }

    try {
      const itemsToAdd = await Promise.all(itemObjects);
      for (let index = 0; index < itemsToAdd.length; index += 1) {
        const addSuccess = await this.addItemToPage(itemsToAdd[index], {
          undoable: index === itemsToAdd.length - 1,
          updateRedux: true,
          selectOnAdd: false,
        });
        if (addSuccess) {
          fabricObjects.push(itemsToAdd[index].fabricObject);
        }
      }
    } finally {
      hideLoading('loading');
    }

    if (fabricObjects.length > 0) {
      this.page.poster.itemsMultiSelect.isActiveSelectionModificationProcessing = true;
      this.page.activeSelection.selectFabricObjects(fabricObjects);
      this.page.poster.itemsMultiSelect.isActiveSelectionModificationProcessing = false;
    }
  }

  public onAddImageBackground(elementsData: (ImageData | TempImageData)[]): void {
    const {poster} = this.page;
    const backgroundImageData = elementsData[0];
    const backgroundUID = 'uid' in backgroundImageData ? backgroundImageData.uid : uuidv4();

    let imageBackgroundItemObject = {
      gitype: ITEM_TYPE.IMAGEBACKGROUND,
      imageSource: backgroundImageData.source,
      uid: backgroundUID,
      x: -1,
      y: -1,
    } as ImageBackgroundItemObject;

    if ('hashedFilename' in backgroundImageData) {
      imageBackgroundItemObject = {
        ...imageBackgroundItemObject,
        fileExtension: backgroundImageData.extension,
        hashedFilename: backgroundImageData.hashedFilename,
        idUser: backgroundImageData.uploaderId,
        uploadingImageData: undefined,
      };
    } else {
      imageBackgroundItemObject = {
        ...imageBackgroundItemObject,
        hashedFilename: undefined,
        fileExtension: undefined,
        uploadingImageData: {
          tempUploadingImageUID: backgroundUID,
          dataUrl: backgroundImageData.dataUrl,
          uploadStartTime: new Date().getTime(),
        },
      };
    }

    const onApplyCallback = (cropResponse: CropperPanelApplyResponse): void => {
      imageBackgroundItemObject = {
        ...imageBackgroundItemObject,
        cropData: {
          cropped: true,
          ...cropResponse.cropData,
        },
      };
      const backgroundObject = {
        details: {
          type: BackgroundTypeName.IMAGE,
          imageBackgroundItemObject,
        },
      };

      void this.page.background.updateFromObject(backgroundObject);
    };

    openCropperModal({
      onApply: onApplyCallback,
      imageHashedFilename: 'hashedFilename' in backgroundImageData ? backgroundImageData.hashedFilename : undefined,
      dataUrl: 'dataUrl' in backgroundImageData ? backgroundImageData.dataUrl : undefined,
      imageSource: backgroundImageData.source,
      showRemoveBackgroundOption: false,
      showCroppingPresets: false,
      aspectRatio: poster.width / poster.height,
    });
  }

  public async addAudioItems(audioItemsData: AudioData[], {selectOnAdd = true}: AddItemsOptions): Promise<void> {
    await this.page.poster.audioClips.addAudioItemsData(audioItemsData, {selectOnAdd});
    this.page.poster.clearItemSelection();
    window.PMW.redux.store.dispatch(updateBottomWebSeekbarState(true));
    if (isEditorMobileVariant() && selectOnAdd) {
      openTimelineModal();
    }
  }

  private async getTranscriptItem(itemData: NewTranscriptData, optionsToAdd?: DeepPartial<TranscriptItemObject>): Promise<ItemType> {
    if (itemData.type !== ElementDataType.TRANSCRIPT) {
      throw new Error('type mismatch in get transcript item');
    }

    const widthOfThePoster = window.posterEditor?.whiteboard?.width;
    const heightOfThePoster = window.posterEditor?.whiteboard?.height;

    if (!widthOfThePoster || !heightOfThePoster) {
      throw new Error('Could not get width and height of the poster');
    }

    const fontSizeLowerLimit = widthOfThePoster > heightOfThePoster ? widthOfThePoster / DEFAULT_FONT_SIZE / 2 : heightOfThePoster / DEFAULT_FONT_SIZE / 2;

    const fontSize = Math.max(widthOfThePoster < heightOfThePoster ? widthOfThePoster / DEFAULT_FONT_SIZE : heightOfThePoster / DEFAULT_FONT_SIZE, fontSizeLowerLimit);

    const subtitlesHashmap: Record<string, DeepPartial<SubtitleObject>> = {};

    const selectedTemplateId = itemData.selectedSubtitleTemplateId ?? getDefaultSelectedSubtitleTemplateProperties().id;

    const appliedStyles = itemData.styles ?? ALL_TEMPLATE_STYLES[selectedTemplateId].templateStyles.stylesToApply;

    const {sentenceTextStyles, activeWordTextStyles, ...styles} = appliedStyles;

    for (const subtitle of itemData.subtitles) {
      subtitlesHashmap[subtitle.id] = {
        words: subtitle.words,
        subtitleUID: subtitle.id,
        text: subtitle.text,
        startTime: subtitle.startTime,
        endTime: subtitle.endTime,
        hasUserEdited: !itemData.areAIGenerated,
        selectedTemplateId,
        sentenceTextStyles: {
          fontSize,
          ...sentenceTextStyles,
        },
        activeWordTextStyles: {
          fontSize,
          ...activeWordTextStyles,
        },
        ...styles,
      };
    }

    const item = await createItemFromObject(this.page, {
      gitype: ITEM_TYPE.TRANSCRIPT,
      height: 0,
      width: this.page.poster.width - TRANSCRIPT_ITEM_PADDING - TRANSCRIPT_ITEM_PADDING,
      ...itemData,
      ...optionsToAdd,
      subtitlesHashmap,
    });

    const allTranscriptItems = this.page.items.getAllTranscriptItems();
    const {x, y} = (item as TranscriptItem).getInitialCoordinatesForTranscript(allTranscriptItems.length + 1);
    item.fabricObject.left = x;
    item.fabricObject.top = y;

    return item;
  }

  public async addAudioItemFromAudioItemObject(audioItemObject: AudioItemObject): Promise<void> {
    const {poster} = this.page;
    const onPosterStartTime = poster.getCurrentTime();

    if (onPosterStartTime > POSTER_MAX_DURATION - ADD_POSTER_AUDIO_DURATION_LIMIT_LEEWAY) {
      openMessageModal({
        title: window.i18next.t('pmwjs_audio_max_duration_reached_title'),
        text: window.i18next.t('pmwjs_audio_max_poster_start_time_reached'),
      });
      return;
    }

    showLoading('addingAudioItem');
    const newItemUID = uuidv4();
    const newEndTimeOnPoster = onPosterStartTime + (audioItemObject.audioPlayer.trim.endTime - audioItemObject.audioPlayer.trim.startTime) / audioItemObject.audioPlayer.speed;
    const isAudioExceedingPosterMaxDuration = newEndTimeOnPoster > POSTER_MAX_DURATION;
    const audioItemObjectToAdd = {
      ...audioItemObject,
      onPosterStartTime,
      audioPlayer: {
        ...audioItemObject.audioPlayer,
        trim: {
          isTrimmed: isAudioExceedingPosterMaxDuration ? true : audioItemObject.audioPlayer.trim.isTrimmed,
          endTime: isAudioExceedingPosterMaxDuration
            ? (POSTER_MAX_DURATION - onPosterStartTime + audioItemObject.audioPlayer.trim.startTime) * audioItemObject.audioPlayer.speed
            : audioItemObject.audioPlayer.trim.endTime,
          startTime: audioItemObject.audioPlayer.trim.startTime,
        },
      },
      uid: newItemUID,
    };
    const newAudioItemHashMap: Record<string, AudioItemObject> = {};
    for (const [key, items] of Object.entries(poster.audioClips.audioItemsHashMap)) {
      newAudioItemHashMap[key] = items.toObject();
    }
    newAudioItemHashMap[newItemUID] = {...audioItemObjectToAdd};

    poster.audioClips.selectedAudioItemUID = newItemUID;
    await this.page.poster.audioClips.updateFromObject({
      audioItemsHashMap: newAudioItemHashMap,
    });

    const currentPageDuration = poster.getCurrentPage().getDuration();
    if (newEndTimeOnPoster > currentPageDuration) {
      await this.page.updateFromObject(
        {
          duration: Math.min(newEndTimeOnPoster, POSTER_MAX_DURATION),
        },
        {undoable: false}
      );
    }

    this.page.poster.clearItemSelection();
    window.PMW.redux.store.dispatch(updateBottomWebSeekbarState(true));
    hideLoading('addingAudioItem');
  }

  public async addAudioItemFromAnotherAudioItem(audioItemToCopy: AudioItem): Promise<void> {
    const {poster} = this.page;
    const onPosterStartTime = poster.getCurrentTime();

    if (onPosterStartTime > POSTER_MAX_DURATION - ADD_POSTER_AUDIO_DURATION_LIMIT_LEEWAY / audioItemToCopy.audioPlayer.speed) {
      openMessageModal({
        title: window.i18next.t('pmwjs_audio_max_duration_reached_title'),
        text: window.i18next.t('pmwjs_audio_max_poster_start_time_reached'),
      });
      return;
    }

    showLoading('addingAudioItem');
    const newEndTimeOnPoster = onPosterStartTime + audioItemToCopy.audioPlayer.getPlaybackDuration();
    const isAudioExceedingPosterMaxDuration = newEndTimeOnPoster > POSTER_MAX_DURATION;
    const newItemUID = uuidv4();

    const audioItemObject: AudioItemObject = {
      uid: newItemUID,
      hashedFilename: audioItemToCopy.hashedFilename,
      onPosterStartTime,
      audioPlayer: {
        playCycles: audioItemToCopy.audioPlayer.playCycles,
        volume: audioItemToCopy.audioPlayer.volume,
        fade: {...audioItemToCopy.audioPlayer.fade},
        speed: audioItemToCopy.audioPlayer.speed,
        isPlaying: false,
        audioUrl: getAudioUrl(audioItemToCopy.hashedFilename, audioItemToCopy.source),
        originalDuration: audioItemToCopy.audioPlayer.originalDuration,
        trim: {
          isTrimmed: isAudioExceedingPosterMaxDuration ? true : audioItemToCopy.audioPlayer.trim.isTrimmed,
          endTime: isAudioExceedingPosterMaxDuration
            ? (POSTER_MAX_DURATION - onPosterStartTime + audioItemToCopy.audioPlayer.trim.startTime) * audioItemToCopy.audioPlayer.speed
            : audioItemToCopy.audioPlayer.trim.endTime,
          startTime: audioItemToCopy.audioPlayer.trim.startTime,
        },
      },
      name: audioItemToCopy.name,
      source: audioItemToCopy.source,
    };

    const newAudioItemHashMap: Record<string, AudioItemObject> = {};
    for (const [key, items] of Object.entries(poster.audioClips.audioItemsHashMap)) {
      newAudioItemHashMap[key] = items.toObject();
    }
    newAudioItemHashMap[newItemUID] = {...audioItemObject};

    poster.audioClips.selectedAudioItemUID = newItemUID;
    await this.page.poster.audioClips.updateFromObject({
      audioItemsHashMap: newAudioItemHashMap,
    });

    const currentPageDuration = poster.getCurrentPage().getDuration();
    if (newEndTimeOnPoster > currentPageDuration) {
      await this.page.updateFromObject(
        {
          duration: Math.min(newEndTimeOnPoster, POSTER_MAX_DURATION),
        },
        {undoable: false}
      );
    }

    this.page.poster.clearItemSelection();
    window.PMW.redux.store.dispatch(updateBottomWebSeekbarState(true));
    hideLoading('addingAudioItem');
  }

  private getFontSizeAndBaseWidthForNewTextItem(text: string, widthOfThePoster: number, heightOfThePoster: number): [number, number] {
    const fontSizeLowerLimit = widthOfThePoster > heightOfThePoster ? widthOfThePoster / DEFAULT_FONT_SIZE / 2 : heightOfThePoster / DEFAULT_FONT_SIZE / 2;
    let fontSize = Math.max(widthOfThePoster < heightOfThePoster ? widthOfThePoster / DEFAULT_FONT_SIZE : heightOfThePoster / DEFAULT_FONT_SIZE, fontSizeLowerLimit);
    let baseWidth = this.getBaseWidthForText(fontSize, text);
    if (baseWidth >= widthOfThePoster) {
      const widthRatio = baseWidth / widthOfThePoster;
      fontSize = fontSize / widthRatio > MINIMUM_FONT_SIZE_WITH_ADJUSTED_WIDTH ? fontSize / widthRatio : MINIMUM_FONT_SIZE_WITH_ADJUSTED_WIDTH;
      baseWidth = widthOfThePoster * 0.75;
    }

    return [fontSize, baseWidth];
  }

  private async getTextItem(item: ItemData, optionsToAdd?: DeepPartial<TextItemObject>): Promise<ItemType> {
    if (item.type !== ElementDataType.TEXT) {
      throw new Error('type mismatch in get text item');
    }
    const text = item.text ?? window.i18next.t('pmwjs_add_text');
    const widthOfThePoster = window.posterEditor?.whiteboard?.width;
    const heightOfThePoster = window.posterEditor?.whiteboard?.height;

    if (!widthOfThePoster || !heightOfThePoster) {
      throw new Error('Could not get width and height of the poster');
    }

    const [fontSize, baseWidth] = this.getFontSizeAndBaseWidthForNewTextItem(text, widthOfThePoster, heightOfThePoster);

    return createItemFromObject(this.page, {
      ...item,
      gitype: ITEM_TYPE.TEXT,
      text,
      textStyles: {
        fontSize,
        fill: {
          fillType: FillTypes.SOLID,
          fillColor: [window.posterEditor?.whiteboard?.lastAddedTextColor],
        },
      },
      scaleX: 1,
      scaleY: 1,
      baseWidth,
      width: baseWidth + ITEM_CONTROL_DIMENSIONS.PMW_ITEM_LEGACY_PADDING * 2 + DEFAULT_STROKE_WIDTH,
      ...optionsToAdd,
    });
  }

  private async getTextSlideshowItem(item: ItemData, optionsToAdd?: DeepPartial<SlideshowItemObject>): Promise<ItemType> {
    if (item.type !== ElementDataType.SLIDESHOW) {
      throw new Error('type mismatch in get slideshow item');
    }
    const text = window.i18next.t('pmwjs_add_text');
    const widthOfThePoster = window.posterEditor?.whiteboard?.width;
    const heightOfThePoster = window.posterEditor?.whiteboard?.height;

    if (!widthOfThePoster || !heightOfThePoster) {
      throw new Error('Could not get width and height of the poster');
    }
    const fontSizeLowerLimit = widthOfThePoster > heightOfThePoster ? widthOfThePoster / DEFAULT_FONT_SIZE / 2 : heightOfThePoster / DEFAULT_FONT_SIZE / 2;
    const fontSize = Math.max(widthOfThePoster < heightOfThePoster ? widthOfThePoster / DEFAULT_FONT_SIZE : heightOfThePoster / DEFAULT_FONT_SIZE, fontSizeLowerLimit);
    const baseWidth = this.getBaseWidthForText(fontSize, text);
    const textSlideUID = uuidv4();
    const textSlideObject = {
      gitype: ITEM_TYPE.TEXTSLIDE,
      text,
      textStyles: {
        fontSize,
        fill: {
          fillType: FillTypes.SOLID,
          fillColor: [window.posterEditor?.whiteboard?.lastAddedTextColor],
          fillAlpha: 1,
        },
      },
      scaleX: 1,
      scaleY: 1,
      baseWidth,
      width: baseWidth + ITEM_CONTROL_DIMENSIONS.PMW_ITEM_LEGACY_PADDING * 2 + DEFAULT_STROKE_WIDTH,
      uid: textSlideUID,
    };
    return createItemFromObject(this.page, {
      ...item,
      gitype: ITEM_TYPE.SLIDESHOW,
      scaleX: 1,
      scaleY: 1,
      slides: {
        slidesOrder: [textSlideUID],
        slidesHashMap: {
          [textSlideUID]: textSlideObject,
        },
      },
      introAnimationPadding: this.page.introAnimation.getAnimationMaxDuration() ?? 0,
      ...optionsToAdd,
    });
  }

  public async getImageItem(item: NewImageItemData, optionsToAdd?: DeepPartial<ImageItemObject>): Promise<ItemType> {
    if (this.isVectorGraphicItemExtension(item.extension)) {
      return this.getVectorItemForImageData(item, optionsToAdd);
    }

    if (item.source === ImageItemSource.PMW_STOCK_IMAGE) {
      return this.getImageItemForPMWImage(item, optionsToAdd);
    }

    return createItemFromObject(this.page, {
      gitype: ITEM_TYPE.IMAGE,
      imageSource: item.source,
      fileExtension: item.extension,
      hashedFilename: item.hashedFilename,
      x: item.x,
      y: item.y,
      ...optionsToAdd,
    });
  }

  public async getTempImageItem(item: NewTempImageItemData, optionsToAdd?: DeepPartial<ImageItemObject>): Promise<ItemType> {
    return createItemFromObject(this.page, {
      gitype: ITEM_TYPE.IMAGE,
      imageSource: item.source,
      uid: item.uid,
      uploadingImageData: {
        dataUrl: item.dataUrl,
        tempUploadingImageUID: item.uid,
        uploadStartTime: new Date().getTime(),
      },
      x: item.x,
      y: item.y,
      ...optionsToAdd,
    });
  }

  private async getImageItemForPMWIcon(item: NewPMWIconItemData, optionsToAdd?: DeepPartial<ImageItemObject>): Promise<ItemType> {
    return createItemFromObject(this.page, {
      gitype: ITEM_TYPE.IMAGE,
      imageSource: ImageItemSource.PMW_ICON,
      fileExtension: PMW_STOCK_IMAGE_EXTENSION,
      hashedFilename: item.hashedFilename,
      x: item.x,
      y: item.y,
      ...optionsToAdd,
    });
  }

  private async getImageItemForPMWStillSticker(item: NewStillStickerItemData, optionsToAdd?: DeepPartial<ImageItemObject>): Promise<ItemType> {
    return createItemFromObject(this.page, {
      gitype: ITEM_TYPE.IMAGE,
      imageSource: ImageItemSource.PMW_STILL_STICKER,
      fileExtension: PMW_STOCK_IMAGE_EXTENSION,
      hashedFilename: item.hashedFilename,
      x: item.x,
      y: item.y,
      ...optionsToAdd,
    });
  }

  private async getImageItemForPMWImage(item: NewImageItemData, optionsToAdd?: DeepPartial<ImageItemObject>): Promise<ItemType> {
    return createItemFromObject(this.page, {
      gitype: ITEM_TYPE.IMAGE,
      imageSource: ImageItemSource.PMW_STOCK_IMAGE,
      fileExtension: PMW_STOCK_IMAGE_EXTENSION,
      hashedFilename: item.hashedFilename,
      x: item.x,
      y: item.y,
      ...optionsToAdd,
    });
  }

  private async getImageItemForPMWExtractedGettySticker(item: NewExtractedGettyStickerItemData, optionsToAdd?: DeepPartial<ImageItemObject>): Promise<ItemType> {
    return createItemFromObject(this.page, {
      gitype: ITEM_TYPE.IMAGE,
      imageSource: ImageItemSource.PMW_EXTRACTED_GETTY_STICKER,
      fileExtension: PMW_STOCK_IMAGE_EXTENSION,
      hashedFilename: item.hashedFilename,
      x: item.x,
      y: item.y,
      ...optionsToAdd,
    });
  }

  private getVectorItemForImageData(item: NewImageItemData, optionsToAdd?: DeepPartial<ItemObject>): Promise<VectorItem> {
    return createItemFromObject(this.page, {
      gitype: ITEM_TYPE.VECTOR,
      fileName: item.hashedFilename,
      source: item.source,
      x: item.x,
      y: item.y,
      ...optionsToAdd,
    } as Partial<VectorItemObject>) as Promise<VectorItem>;
  }

  private isVectorGraphicItemExtension = (extension: string): boolean => {
    return extension === 'svg';
  };

  private async getPMWShapeItem(item: NewPMWShapeItemData, optionsToAdd?: DeepPartial<VectorItemObject>): Promise<VectorItem | RectangleItem | LineItem> {
    const rectangleData = PMW_SHAPE_TO_RECTANGLE_HASHMAP[item.hashedFilename];
    if (rectangleData !== undefined) {
      const pageItem = (await createItemFromObject(this.page, {
        gitype: ITEM_TYPE.RECTANGLE,
        x: item.x,
        y: item.y,
        rx: rectangleData.cornerRoundness,
        ry: rectangleData.cornerRoundness,
        width: rectangleData.width,
        height: rectangleData.height,
        rotation: rectangleData.rotation ?? 0,
        border: {
          solidBorderColor: rectangleData.borderColor,
          solidBorderThickness: rectangleData.borderThickness,
          solidBorderType: rectangleData.borderType,
        },
      })) as RectangleItem;
      pageItem.fill.fillColor = [rectangleData.fillColor];
      return pageItem;
    }

    const {paths} = await loadVectorSVG(item.hashedFilename, VectorItemSource.PMW_SHAPES);

    const opts = {
      gitype: ITEM_TYPE.VECTOR,
      x: item.x,
      y: item.y,
      fileName: item.hashedFilename,
      source: VectorItemSource.PMW_SHAPES,
      border: {
        solidBorderThickness: paths[0].strokeWidth,
        solidBorderType: BorderType.RECTANGLE_BORDER,
      },
      fill: {
        fillType: this.getFillTypeForPaths(paths),
      },
      ...optionsToAdd,
    };
    const stroke = colorToRGBA(paths[0].stroke);
    if (stroke !== null) {
      opts.border.solidBorderColor = stroke;
    }
    if (arePathsCustomizable(paths)) {
      opts.fill.fillColor = [colorToRGBA(paths[0].fill)];
    }

    return createItemFromObject(this.page, opts) as Promise<VectorItem>;
  }

  private getFillTypeForPaths(paths: FabricObject[]): FillTypes {
    if (paths.length > 0 && !paths[0].fill) {
      for (const item of paths) {
        if (item.fill) {
          return FillTypes.SOLID;
        }
      }
      return FillTypes.NONE;
    }

    return FillTypes.SOLID;
  }

  private async getStickerItem(item: NewStickerItemData, optionsToAdd?: DeepPartial<StickerItemObject>): Promise<ItemType> {
    return createItemFromObject(this.page, {
      gitype: ITEM_TYPE.STICKER,
      x: item.x,
      y: item.y,
      hashedFilename: item.hashedFilename,
      duration: item.duration,
      frameRate: item.frameRate,
      endTime: item.duration,
      highResAnimatedSprite: item.highResAnimatedSprite,
      screenAnimatedSprite: item.screenAnimatedSprite,
      ...optionsToAdd,
    });
  }

  private async getVideoItem(item: NewVideoItemData, optionsToAdd?: DeepPartial<VideoItemObject>): Promise<ItemType> {
    let endTime = item.duration;

    if (this.isVideoAbovePosterDurationLimits(item)) {
      endTime = POSTER_MAX_DURATION;
    }

    return createItemFromObject(this.page, {
      ...item,
      gitype: ITEM_TYPE.VIDEO,
      x: item.x,
      y: item.y,
      hashedFilename: item.hashedFilename,
      videoSource: item.source,
      fileExtension: item.extension,
      duration: item.duration,
      frameRate: item.frameRate,
      ...optionsToAdd,
      endTime,
    });
  }

  private async getFancyTextItem(itemData: NewFancyTextItemData, optionsToAdd?: DeepPartial<FancyTextItemObject>): Promise<ItemType> {
    return createItemFromObject(this.page, {
      ...itemData,
      gitype: ITEM_TYPE.FANCY_TEXT,
      ...optionsToAdd,
    });
  }

  public async scaleItemToPosterDimension(item: ItemType, itemToPosterDimension = DEFAULT_ITEM_ADD_POSTER_RATIO): Promise<void> {
    const scale = this.getInitialScaleForNewItem(item.fabricObject.width, item.fabricObject.height, itemToPosterDimension);
    return item.updateFromObject(
      {
        scaleX: scale,
        scaleY: scale,
      },
      {
        undoable: false,
      }
    );
  }

  private getInitialScaleForNewItem(itemWidth: number, itemHeight: number, itemToPosterDimension: number): number {
    if (!window.posterEditor?.whiteboard?.width) {
      throw new Error('Poster not yet initialized');
    }

    const posterWidth = window.posterEditor.whiteboard.width;
    const posterHeight = window.posterEditor.whiteboard.height;
    if (itemWidth / posterWidth >= itemHeight / posterHeight) {
      return itemToPosterDimension * (posterWidth / itemWidth);
    }
    return itemToPosterDimension * (posterHeight / itemHeight);
  }

  /**
   * Scales an item to desired size. Pritoizes keys in the following descending order
   * 1) scaledWidth, scaledHeight
   * 2) scaleX, scaleY
   * 3) itemToPosterDimension
   */
  private async scaleNewItemBeforeAdd(item: ItemType, itemData: ItemData, itemToPosterDimension = DEFAULT_ITEM_ADD_POSTER_RATIO): Promise<Scales> {
    let {scaleX, scaleY} = itemData;

    if (itemData.scaledWidth !== undefined) {
      scaleX = itemData.scaledWidth / item.fabricObject.width;
    }

    if (itemData.scaledHeight !== undefined) {
      scaleY = itemData.scaledHeight / item.fabricObject.height;
    }

    if (itemData.maintainAspectRatioForScaledDimension) {
      if (scaleX !== undefined && scaleY !== undefined) {
        scaleX = scaleX < scaleY ? scaleX : scaleY;
        scaleY = scaleY < scaleX ? scaleY : scaleX;
      } else if (scaleX !== undefined && scaleY === undefined) {
        scaleY = scaleX;
      } else if (scaleY !== undefined && scaleX === undefined) {
        scaleX = scaleY;
      }
    }

    if (scaleX === undefined || scaleY === undefined) {
      const scale = this.getInitialScaleForNewItem(item.fabricObject.width, item.fabricObject.height, itemToPosterDimension);
      scaleX = scaleX ?? scale;
      scaleY = scaleY ?? scale;
    }

    await item.updateFromObject(
      {
        scaleX,
        scaleY,
      },
      {
        undoable: false,
      }
    );

    return {
      scaleX,
      scaleY,
    };
  }
}
