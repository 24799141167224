import {getSingleActiveItemObject} from '@Components/poster-editor/poster-editor-reducer';
import {useAppSelector} from '@/hooks';
import type {SlideItem} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.types';

export function useGetSelectedSlide(): SlideItem | null {
  const singleActiveItem = useAppSelector(getSingleActiveItemObject);
  const activeItem = singleActiveItem ? window.posterEditor?.whiteboard?.getItemForId(singleActiveItem.uid) : null;
  if (!activeItem) {
    return null;
  }

  if (!activeItem.isSlideshow()) {
    return null;
  }

  return activeItem.getSelectedSlide();
}
