import type {ReactElement} from 'react';
import {type PointerEvent, useEffect, useRef, useState} from 'react';
import React from 'react';
import {useAppSelector} from '@/hooks';
import {getActiveItems} from '@Components/poster-editor/poster-editor-reducer';
import type {FabricObject} from '@postermywall/fabricjs-2';
import type {CustomItemControlPositions} from '@Components/poster-editor/components/custom-item-controls/components/single-item-custom-controls/single-item-custom-controls.types';
import {degreesToRadians} from '@Utils/math.util';
import {BaseCustomItemControl} from '@Components/poster-editor/components/custom-item-controls/components/base-custom-item-control/base-custom-item-control';
import {CUSTOM_ITEM_SCALING_CONTROL_SIDE, CustomControlCursor} from '@Components/poster-editor/components/custom-item-controls/custom-item-controls.types';
import {CustomCornerControl, onScaleFromCornerEnded, scaleFromCorner} from '@PosterWhiteboard/libraries/custom-item-controls.library';
import {CUSTOM_TR_CONTROL_ID} from './custom-item-control-tr.types';

export function CustomItemControlTr(): ReactElement | null {
  const activeItemsData = useAppSelector(getActiveItems);
  const arePosterItemsMoving = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsMoving;
  });
  const arePosterItemsScaling = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsScaling;
  });
  const arePosterItemsRotating = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsRotating;
  });
  const [isActive, setIsActive] = useState(false);
  const initialClientX = useRef<number | null>(null);
  const initialClientY = useRef<number | null>(null);

  const handlePointerDown = (e: PointerEvent): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    const activeSelection = currentPage.activeSelection.getActiveObject();
    if (!activeSelection) {
      return;
    }

    const target = e.target as HTMLElement;
    if (target.id === CUSTOM_TR_CONTROL_ID) {
      setIsActive(true);
      initialClientX.current = e.clientX;
      initialClientY.current = e.clientY;

      currentPage.poster.redux.setPosterItemsModificationState('scaling');
    }
  };

  const getTrControlPosition = (fabricObject: FabricObject, posterScale: number): CustomItemControlPositions => {
    const leftInitial = (fabricObject.getX() + fabricObject.getScaledWidth() * Math.cos(degreesToRadians(fabricObject.getTotalAngle()))) * posterScale;
    const topInitial = (fabricObject.getY() + fabricObject.getScaledWidth() * Math.sin(degreesToRadians(fabricObject.getTotalAngle()))) * posterScale;

    const angle = fabricObject.getTotalAngle();
    const left = leftInitial - CUSTOM_ITEM_SCALING_CONTROL_SIDE / 2;
    const top = topInitial - CUSTOM_ITEM_SCALING_CONTROL_SIDE / 2;

    return {left, top, angle};
  };

  const getCursor = (fabricObject: FabricObject): CustomControlCursor => {
    let angle = fabricObject.getTotalAngle();
    if (angle < 0) {
      angle = angle + 360;
    }

    if (angle >= 0 && angle < 23) {
      return CustomControlCursor.CURSOR_NESW_RESIZE;
    }
    if (angle >= 23 && angle < 68) {
      return CustomControlCursor.CURSOR_EW_RESIZE;
    }
    if (angle >= 68 && angle < 113) {
      return CustomControlCursor.CURSOR_NWSE_RESIZE;
    }
    if (angle >= 113 && angle < 158) {
      return CustomControlCursor.CURSOR_NS_RESIZE;
    }
    if (angle >= 158 && angle < 203) {
      return CustomControlCursor.CURSOR_NESW_RESIZE;
    }
    if (angle >= 203 && angle < 248) {
      return CustomControlCursor.CURSOR_EW_RESIZE;
    }
    if (angle >= 248 && angle < 293) {
      return CustomControlCursor.CURSOR_NWSE_RESIZE;
    }
    if (angle >= 293 && angle < 338) {
      return CustomControlCursor.CURSOR_NS_RESIZE;
    }
    return CustomControlCursor.CURSOR_NESW_RESIZE;
  };

  const getTrControl = (): ReactElement | null => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return null;
    }

    const activeSelection = currentPage.activeSelection.getActiveObject();
    if (!activeSelection) {
      return null;
    }

    if (activeSelection.lockScalingX || activeSelection.lockScalingY) {
      return null;
    }

    const position = getTrControlPosition(activeSelection, currentPage.poster.scaling.scale);

    return (
      <BaseCustomItemControl
        controlId={CUSTOM_TR_CONTROL_ID}
        top={position.top}
        left={position.left}
        width={CUSTOM_ITEM_SCALING_CONTROL_SIDE}
        height={CUSTOM_ITEM_SCALING_CONTROL_SIDE}
        angle={position.angle}
        borderRadius={'4px'}
        isVisible={(!arePosterItemsMoving && !arePosterItemsRotating && !arePosterItemsScaling) || (arePosterItemsScaling && isActive)}
        cursor={getCursor(activeSelection)}
        onPointerDown={handlePointerDown}
      />
    );
  };

  const onItemScaledWithTopRightHandle = (e: globalThis.PointerEvent): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    const activeSelection = currentPage.activeSelection.getActiveObject();
    if (!activeSelection) {
      return;
    }

    if (initialClientX.current === null || initialClientY.current === null) {
      return;
    }

    const didScale = scaleFromCorner(e, initialClientX.current, initialClientY.current, currentPage, activeSelection, CustomCornerControl.TR);
    if (didScale) {
      updateInitialRefs(e);
    }
  };

  const updateInitialRefs = (e: globalThis.PointerEvent): void => {
    initialClientX.current = e.clientX;
    initialClientY.current = e.clientY;
  };

  const handlePointerMove = (e: globalThis.PointerEvent): void => {
    if (!isActive) {
      return;
    }

    onItemScaledWithTopRightHandle(e);
  };

  const handlePointerUp = (): void => {
    if (!isActive) {
      return;
    }

    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    void onScaleFromCornerEnded();

    setIsActive(false);

    currentPage.poster.redux.setPosterItemsModificationState(false);

    initialClientX.current = null;
    initialClientY.current = null;
  };

  useEffect(() => {
    if (isActive) {
      window.addEventListener('pointerup', handlePointerUp);
      window.addEventListener('pointermove', handlePointerMove);
    }

    return (): void => {
      window.removeEventListener('pointermove', handlePointerMove);
      window.removeEventListener('pointerup', handlePointerUp);
    };
  }, [isActive]);

  if (!activeItemsData || activeItemsData.length === 0) {
    return null;
  }

  if (arePosterItemsMoving || arePosterItemsRotating || (arePosterItemsScaling && !isActive)) {
    return null;
  }

  return getTrControl();
}
