import type {ReactElement} from 'react';
import React, {useEffect, useState} from 'react';
import {createPortal} from 'react-dom';
import {CUSTOM_DIMENSIONS_BOX_DISTANCE_FROM_CURSOR, CUSTOM_DIMENSIONS_BOX_ID, PossiblePosterUnit} from './custom-scaled-dimensions-info-box.types';
import {useAppSelector} from '@/hooks';
import {Text, TextSize} from '@Components/text';
import type {FabricObject} from '@postermywall/fabricjs-2';
import {config, util} from '@postermywall/fabricjs-2';
import {roundPrecision} from '@Utils/math.util';
import styles from './custom-scaled-dimensions-info-box.module.scss';
import type {Page} from '@PosterWhiteboard/page/page.class';
import {getActiveItems} from '@Components/poster-editor/poster-editor-reducer';

export function CustomScaledDimensionsInfoBox(): ReactElement | null {
  const activeItemsData = useAppSelector(getActiveItems);

  const posterSize = useAppSelector((state) => {
    return state.posterEditor.posterObject?.type.description;
  });
  const posterUnitsStored = useAppSelector((state) => {
    return state.posterEditor.posterObject?.units;
  });
  const [cursorPos, setCursorPos] = useState({x: 0, y: 0});
  const [boxSize, setBoxSize] = useState({width: 0, height: 0});
  const arePosterItemsScaling = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsScaling;
  });
  const arePosterItemsRotating = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsRotating;
  });
  const isMobileVariant = useAppSelector((state) => {
    return state.posterEditor.isMobileVariant;
  });

  useEffect(() => {
    const handlePointerDownOrMove = (event: PointerEvent): void => {
      if (cursorPos.x !== event.clientX || cursorPos.y !== event.clientY) {
        setCursorPos({
          x: event.clientX,
          y: event.clientY,
        });
      }
    };

    document.addEventListener('pointermove', handlePointerDownOrMove);
    document.addEventListener('pointerdown', handlePointerDownOrMove);
    return (): void => {
      document.removeEventListener('pointermove', handlePointerDownOrMove);
      document.removeEventListener('pointerdown', handlePointerDownOrMove);
    };
  }, []);

  useEffect(() => {
    const box = document.getElementById('custom-info-box');
    if (box) {
      setBoxSize({width: box.offsetWidth, height: box.offsetHeight});
    }
  }, [cursorPos]);

  const getOffsetFromPointer = (): number => {
    if (isMobileVariant) {
      return -CUSTOM_DIMENSIONS_BOX_DISTANCE_FROM_CURSOR * 5;
    }

    return CUSTOM_DIMENSIONS_BOX_DISTANCE_FROM_CURSOR;
  };

  const getDimensionsboxPosition = (): {x: number; y: number} => {
    return adjustPositionToFitWithinViewport(cursorPos.x + getOffsetFromPointer(), cursorPos.y + getOffsetFromPointer());
  };

  const adjustPositionToFitWithinViewport = (x: number, y: number): {x: number; y: number} => {
    return {
      x: Math.max(0, Math.min(x, window.innerWidth - boxSize.width - CUSTOM_DIMENSIONS_BOX_DISTANCE_FROM_CURSOR)),
      y: Math.max(0, Math.min(y, window.innerHeight - boxSize.height - CUSTOM_DIMENSIONS_BOX_DISTANCE_FROM_CURSOR)),
    };
  };

  const getCharsAfterLastDigit = (input: string): string | null => {
    const match = /\d\D*$/.exec(input); // Find the last digit and everything after it
    return match ? match[0].replace(/^\d+\s*/, '') : ''; // Remove the leading number and any spaces
  };

  const normalizeUnit = (unit: string): PossiblePosterUnit => {
    const unitMap: Record<string, PossiblePosterUnit> = {
      inch: PossiblePosterUnit.IN,
      in: PossiblePosterUnit.IN,
      cm: PossiblePosterUnit.CM,
      mm: PossiblePosterUnit.MM,
      ft: PossiblePosterUnit.FT,
      px: PossiblePosterUnit.PX,
    };

    const cleanedUnit = unit.trim().toLowerCase();
    return unitMap[cleanedUnit] || PossiblePosterUnit.PX;
  };

  const extractUnit = (posterSizeDescription: string): PossiblePosterUnit | null => {
    const units = getCharsAfterLastDigit(posterSizeDescription);
    if (!units) {
      return null;
    }

    return normalizeUnit(units);
  };

  const getUnitScaleFactor = (posterUnits: PossiblePosterUnit): number => {
    switch (posterUnits) {
      case PossiblePosterUnit.CM:
        return 2.54;
      case PossiblePosterUnit.IN:
        return 1;
      case PossiblePosterUnit.MM:
        return 25.4;
      case PossiblePosterUnit.FT:
        return 12;
      default:
        return 1;
    }
  };

  const getDimensionsInfoPrecisionForUnit = (posterUnits: PossiblePosterUnit): number => {
    switch (posterUnits) {
      case PossiblePosterUnit.CM:
        return 1;
      case PossiblePosterUnit.IN:
        return 2;
      case PossiblePosterUnit.MM:
        return 0;
      case PossiblePosterUnit.FT:
        return 2;
      default:
        return 0;
    }
  };

  const getRatioForCustomSizedPoster = (currentPage: Page): number => {
    let ratio: number;
    let userWidth = 0;
    let userHeight = 0;

    const posterWidth = currentPage.poster.width;
    const posterHeight = currentPage.poster.height;

    userWidth = currentPage.poster.userWidth;
    userHeight = currentPage.poster.userHeight;

    // for some weird reason, in some cases, userWidth and/or userHeight are strings in page.poster.type; this handles those cases
    if (typeof userWidth === 'string') {
      userWidth = parseFloat(userWidth);
    }

    if (typeof userHeight === 'string') {
      userHeight = parseFloat(userHeight);
    }

    if (posterWidth > posterHeight) {
      userWidth = Math.max(userWidth, userHeight);
      ratio = userWidth / posterWidth;
    } else {
      userHeight = Math.max(userWidth, userHeight);
      ratio = userHeight / posterHeight;
    }

    return ratio * config.DPI;
  };

  const getRatioForNonPxNonCustomSizedPoster = (currentPage: Page, posterUnits: PossiblePosterUnit): number => {
    let ratio: number;

    let posterTypeWidthInUnits: number;
    let posterTypeHeightInUnits: number;

    switch (posterUnits) {
      case PossiblePosterUnit.CM:
        posterTypeWidthInUnits = currentPage.poster.type.widthCm;
        posterTypeHeightInUnits = currentPage.poster.type.heightCm;
        break;
      case PossiblePosterUnit.IN:
        posterTypeWidthInUnits = currentPage.poster.type.widthInch;
        posterTypeHeightInUnits = currentPage.poster.type.heightInch;
        break;
      case PossiblePosterUnit.MM:
        posterTypeWidthInUnits = currentPage.poster.type.widthMm;
        posterTypeHeightInUnits = currentPage.poster.type.heightMm;
        break;
      case PossiblePosterUnit.FT:
        posterTypeWidthInUnits = currentPage.poster.type.widthInch / 12;
        posterTypeHeightInUnits = currentPage.poster.type.heightInch / 12;
        break;
      default:
        throw new Error(`Unhandled posterUnits ${posterUnits} in getRatioForNonCustomSizedPoster`);
    }

    if (posterTypeWidthInUnits > posterTypeHeightInUnits) {
      ratio = posterTypeWidthInUnits / Math.max(currentPage.poster.type.width, currentPage.poster.type.height); // weird case where sometimes, type.height is > than type.width,
      // while posterTypeHeightInUnits is < posterTypeWidthInUnits, and vice-versa
    } else {
      ratio = posterTypeHeightInUnits / Math.max(currentPage.poster.type.width, currentPage.poster.type.height); // weird case where sometimes, type.height is > than type.width,
      // while posterTypeHeightInUnits is < posterTypeWidthInUnits, and vice-versa
    }

    return ratio * config.DPI;
  };

  const getDimensionsBoxText = (activeSelection: FabricObject, currentPage: Page): string => {
    if (arePosterItemsRotating) {
      let angle = roundPrecision(activeSelection.getTotalAngle(), 0);
      if (angle < 0) {
        angle = angle + 360;
      }

      return `${angle}°`;
    }

    const posterUnits = window.posterEditor?.whiteboard?.type.isCustomSize()
      ? normalizeUnit(posterUnitsStored ?? PossiblePosterUnit.PX)
      : posterSize
        ? (extractUnit(posterSize) ?? PossiblePosterUnit.PX)
        : PossiblePosterUnit.PX;

    let oneUnitofPosterUnitToFabricPixels = util.parseUnit(`1${posterUnits}`);

    if (posterUnits === PossiblePosterUnit.FT) {
      // util.parseUnit doesn't handle 'ft', so convert that to inches and divide by 12
      oneUnitofPosterUnitToFabricPixels = util.parseUnit(`1in`);
      oneUnitofPosterUnitToFabricPixels = oneUnitofPosterUnitToFabricPixels / 12;
    }

    if (posterUnits === PossiblePosterUnit.PX) {
      const scaledWidth = roundPrecision((activeSelection.getScaledWidth() - activeSelection.strokeWidth) / oneUnitofPosterUnitToFabricPixels, 0);
      const scaledHeight = roundPrecision((activeSelection.getScaledHeight() - activeSelection.strokeWidth) / oneUnitofPosterUnitToFabricPixels, 0);

      return `w ${scaledWidth}  ·  h ${scaledHeight} ${PossiblePosterUnit.PX}`;
    }

    let ratio: number;

    if (currentPage.poster.type.isCustomSize()) {
      ratio = getRatioForCustomSizedPoster(currentPage);
    } else {
      ratio = getRatioForNonPxNonCustomSizedPoster(currentPage, posterUnits);
    }

    const factor = ratio / getUnitScaleFactor(posterUnits);
    const precision = getDimensionsInfoPrecisionForUnit(posterUnits);

    const scaledWidth = roundPrecision(((activeSelection.getScaledWidth() - activeSelection.strokeWidth) / oneUnitofPosterUnitToFabricPixels) * factor, precision).toFixed(
      precision
    );
    const scaledHeight = roundPrecision(((activeSelection.getScaledHeight() - activeSelection.strokeWidth) / oneUnitofPosterUnitToFabricPixels) * factor, precision).toFixed(
      precision
    );

    return `w ${scaledWidth}  ·  h ${scaledHeight} ${posterUnits}`;
  };

  const getCustomScaledDimensionsInfoBoxComponent = (): ReactElement | null => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) return null;

    const activeSelection = currentPage.activeSelection.getActiveObject();
    if (!activeSelection) return null;

    const infoBox = (
      <div
        id={CUSTOM_DIMENSIONS_BOX_ID}
        className={`_position-absolute flex-center spacing-p-1 ${styles.dimensionsBox}`}
        style={{
          left: `${getDimensionsboxPosition().x}px`,
          top: `${getDimensionsboxPosition().y}px`,
        }}
      >
        <Text className={'content-body'} size={TextSize.XXXSMALL} val={getDimensionsBoxText(activeSelection, currentPage)} />
      </div>
    );

    return createPortal(infoBox, document.body); // to make sure that the dimensions box is visible over the add item bar, editing side panel, and navbar too
  };

  if (!arePosterItemsScaling && !arePosterItemsRotating) {
    return null;
  }

  if (!activeItemsData || activeItemsData.length === 0) {
    return null;
  }

  return getCustomScaledDimensionsInfoBoxComponent();
}
