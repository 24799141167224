export const CUSTOM_DIMENSIONS_BOX_ID = 'custom-info-box';
export const CUSTOM_DIMENSIONS_BOX_DISTANCE_FROM_CURSOR = 10;

export enum PossiblePosterUnit {
  IN = 'in',
  CM = 'cm',
  MM = 'mm',
  PX = 'px',
  FT = 'ft',
}
