import type {ReactElement} from 'react';
import React from 'react';
import {CustomOutlineBoxes} from '@Components/poster-editor/components/custom-item-controls/components/custom-outline-boxes/custom-outline-boxes';
import {SingleItemCustomControls} from '@Components/poster-editor/components/custom-item-controls/components/single-item-custom-controls/single-item-custom-controls';
import {ActiveSelectionCustomControls} from '@Components/poster-editor/components/custom-item-controls/components/active-selection-custom-controls/active-selection-custom-controls';
import {CUSTOM_CONTROLS_WRAPPER} from '@Components/poster-editor/components/custom-item-controls/custom-item-controls.types';
import {useAppSelector} from '@/hooks';

export function CustomItemControls(): ReactElement | null {
  const istouchGestureBeingApplied = useAppSelector((state) => {
    return state.posterEditor.isTouchGestureBeingApplied;
  });

  const getCustomItemControls = (): ReactElement | null => {
    if (istouchGestureBeingApplied) {
      return null;
    }

    return (
      <div key={CUSTOM_CONTROLS_WRAPPER} id={CUSTOM_CONTROLS_WRAPPER} className={'_position-absolute'}>
        <CustomOutlineBoxes />
        <SingleItemCustomControls />
        <ActiveSelectionCustomControls />
      </div>
    );
  };

  return getCustomItemControls();
}
