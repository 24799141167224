import type {PointerEvent, ReactElement} from 'react';
import {useEffect} from 'react';
import {useRef, useState} from 'react';
import React from 'react';
import {CUSTOM_ROTATE_CONTROL_MARGIN} from './custom-rotation-control.types';
import {CUSTOM_ROTATION_CONTROL_ID} from './custom-rotation-control.types';
import {getAngleBetweenFabricObjectCenterAndCursor} from '@PosterWhiteboard/libraries/custom-item-controls.library';
import {degreesToRadians} from '@Utils/math.util';
import type {CustomItemControlPositions} from '@Components/poster-editor/components/custom-item-controls/components/single-item-custom-controls/single-item-custom-controls.types';
import {
  CUSTOM_ITEM_CONTROL_BORDER,
  CUSTOM_ITEM_CONTROL_WITH_ICON_SIDE,
  CUSTOM_ITEM_DIMENSIONS_CONTROL_LONGER_SIDE,
  CustomControlCursor,
} from '@Components/poster-editor/components/custom-item-controls/custom-item-controls.types';
import type {FabricObject} from '@postermywall/fabricjs-2';
import {ActiveSelection} from '@postermywall/fabricjs-2';
import type {Page} from '@PosterWhiteboard/page/page.class';
import {useAppSelector} from '@/hooks';
import {getActiveItems} from '@Components/poster-editor/poster-editor-reducer';
import {BaseCustomItemControl} from '@Components/poster-editor/components/custom-item-controls/components/base-custom-item-control/base-custom-item-control';

export function CustomRotationControl(): ReactElement | null {
  const activeItemsData = useAppSelector(getActiveItems);
  const arePosterItemsMoving = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsMoving;
  });
  const arePosterItemsScaling = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsScaling;
  });
  const arePosterItemsRotating = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsRotating;
  });
  const verticalScroll = useAppSelector((state) => {
    return state.posterEditor.scrollState.verticalScroll;
  });
  const horizontalScroll = useAppSelector((state) => {
    return state.posterEditor.scrollState.horizontalScroll;
  });
  const [isActive, setIsActive] = useState(false);
  const initialClientX = useRef<number | null>(null);
  const initialClientY = useRef<number | null>(null);

  const handlePointerUp = (): void => {
    if (!isActive) {
      return;
    }

    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    void onRotateSelectionEnded();

    setIsActive(false);

    currentPage.poster.redux.setPosterItemsModificationState(false);

    initialClientX.current = null;
    initialClientY.current = null;
  };

  const handlePointerMove = (e: globalThis.PointerEvent): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    if (!isActive) {
      return;
    }

    if (initialClientX.current === null) {
      return;
    }

    initRotateStarted();
    onRotateSelection(e);
  };

  const handlePointerDown = (e: PointerEvent): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    const activeSelection = currentPage.activeSelection.getActiveObject();
    if (!activeSelection) {
      return;
    }

    const target = e.target as HTMLElement;
    if (target.id === CUSTOM_ROTATION_CONTROL_ID) {
      setIsActive(true);

      initialClientX.current = e.clientX;
      initialClientY.current = e.clientY;

      currentPage.poster.redux.setPosterItemsModificationState('rotating');
    }
  };

  const initRotateStarted = (): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    const activeSelection = currentPage.activeSelection.getActiveObject();
    if (!activeSelection) {
      return;
    }
  };

  const getNewRotationAngle = (e: globalThis.PointerEvent, fabricObject: FabricObject, currentPage: Page): number => {
    let angleBetweenFabricObjectCenterAndCursor = getAngleBetweenFabricObjectCenterAndCursor(e, fabricObject, currentPage, horizontalScroll, verticalScroll);

    if (angleBetweenFabricObjectCenterAndCursor < 0) {
      angleBetweenFabricObjectCenterAndCursor = angleBetweenFabricObjectCenterAndCursor + 360;
    }

    const targetAngles = [0, 45, 90, 135, 180, 225, 270, 315, 360];

    for (const targetAngle of targetAngles) {
      if (Math.abs(angleBetweenFabricObjectCenterAndCursor - targetAngle) <= 2) {
        return targetAngle;
      }
    }

    return angleBetweenFabricObjectCenterAndCursor;
  };

  const onRotateSelectionEnded = async (): Promise<void> => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    const activeSelection = currentPage.activeSelection.getActiveObject();
    if (!activeSelection) {
      return;
    }

    const updateFromObjectPromises = [];

    if (activeSelection instanceof ActiveSelection) {
      const fabricObjects = activeSelection.getObjects();
      for (const fabricObject of fabricObjects) {
        const item = currentPage.items.getItemForFabricObject(fabricObject);
        if (item) {
          updateFromObjectPromises.push(
            item.updateFromObject(
              {
                x: item.fabricObject.left,
                y: item.fabricObject.top,
                rotation: item.fabricObject.angle,
              },
              {undoable: false}
            )
          );
        }
      }
    } else {
      const item = currentPage.items.getItemForFabricObject(activeSelection);
      if (item) {
        updateFromObjectPromises.push(
          item.updateFromObject(
            {
              x: item.fabricObject.getX(),
              y: item.fabricObject.getY(),
              rotation: item.fabricObject.angle,
            },
            {undoable: false}
          )
        );
      }
    }

    await Promise.all(updateFromObjectPromises);

    currentPage.poster.history.addPosterHistory();
  };

  const onRotateSelection = (e: globalThis.PointerEvent): void => {
    if (initialClientX.current === null || initialClientY.current === null) {
      return;
    }

    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    const activeSelection = currentPage.activeSelection.getActiveObject();
    if (!activeSelection) {
      return;
    }

    const angleBetweenFabricObjectCenterAndCursor = getAngleBetweenFabricObjectCenterAndCursor(e, activeSelection, currentPage, horizontalScroll, verticalScroll);
    const newRotationAngle = getNewRotationAngle(e, activeSelection, currentPage);

    activeSelection.rotate(newRotationAngle);
    activeSelection.setCoords();

    if (activeSelection instanceof ActiveSelection) {
      const fabricObjects = activeSelection.getObjects();
      for (const fabricObject of fabricObjects) {
        const item = currentPage.items.getItemForFabricObject(fabricObject);
        if (item) {
          item.onRotating();
        }
      }
    } else {
      const item = currentPage.items.getItemForFabricObject(activeSelection);
      if (item) {
        item.onRotating();
      }
    }

    if (angleBetweenFabricObjectCenterAndCursor !== newRotationAngle) {
      updateInitialRefs(e);
    }

    currentPage.fabricCanvas.requestRenderAll();
  };

  const updateInitialRefs = (e: globalThis.PointerEvent): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    const activeSelection = currentPage.activeSelection.getActiveObject();
    if (!activeSelection) {
      return;
    }

    initialClientX.current = e.clientX;
    initialClientY.current = e.clientY;
  };

  const getCustomRotationControlPosition = (fabricObject: FabricObject, posterScale: number): CustomItemControlPositions => {
    const itemWidth = (fabricObject.width * fabricObject.getObjectScaling().x + fabricObject.strokeWidth) * posterScale;

    const leftInitial = fabricObject.getX() * posterScale;
    const topInitial = fabricObject.getY() * posterScale;

    const xCorrectionDueToItemWidth = (itemWidth / 2) * Math.cos(degreesToRadians(fabricObject.getTotalAngle()));
    const yCorrectionDueToItemWidth = (itemWidth / 2) * Math.sin(degreesToRadians(fabricObject.getTotalAngle()));

    const x = CUSTOM_ROTATE_CONTROL_MARGIN * Math.sin(degreesToRadians(fabricObject.getTotalAngle()));
    const y = CUSTOM_ROTATE_CONTROL_MARGIN * Math.cos(degreesToRadians(fabricObject.getTotalAngle()));

    const angle = fabricObject.getTotalAngle();
    const left =
      leftInitial +
      xCorrectionDueToItemWidth -
      Math.ceil(CUSTOM_ITEM_DIMENSIONS_CONTROL_LONGER_SIDE / 2 - x) +
      CUSTOM_ITEM_CONTROL_BORDER * Math.cos(degreesToRadians(fabricObject.getTotalAngle()));
    const top = topInitial + yCorrectionDueToItemWidth - Math.ceil(CUSTOM_ITEM_DIMENSIONS_CONTROL_LONGER_SIDE / 2) - y;

    return {left, top, angle};
  };

  const getRotationControl = (): ReactElement | null => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return null;
    }

    const activeSelection = currentPage.activeSelection.getActiveObject();
    if (!activeSelection) {
      return null;
    }

    if (activeSelection.lockRotation) {
      return null;
    }

    const positionAndSize = getCustomRotationControlPosition(activeSelection, currentPage.poster.scaling.scale);

    return (
      <BaseCustomItemControl
        icon={'icon-refresh'}
        controlId={CUSTOM_ROTATION_CONTROL_ID}
        top={positionAndSize.top}
        left={positionAndSize.left}
        width={CUSTOM_ITEM_CONTROL_WITH_ICON_SIDE}
        height={CUSTOM_ITEM_CONTROL_WITH_ICON_SIDE}
        angle={positionAndSize.angle}
        borderRadius={'50%'}
        isVisible={(!arePosterItemsMoving && !arePosterItemsRotating && !arePosterItemsScaling) || (arePosterItemsRotating && isActive)}
        onPointerDown={handlePointerDown}
        cursor={CustomControlCursor.CURSOR_CROSSHAIR}
      />
    );
  };

  useEffect(() => {
    if (isActive) {
      window.addEventListener('pointerup', handlePointerUp);
      window.addEventListener('pointermove', handlePointerMove);
    }

    return (): void => {
      window.removeEventListener('pointermove', handlePointerMove);
      window.removeEventListener('pointerup', handlePointerUp);
    };
  }, [isActive]);

  if (!activeItemsData || activeItemsData.length === 0) {
    return null;
  }

  if (arePosterItemsScaling) {
    return null;
  }

  if (arePosterItemsMoving || arePosterItemsScaling || (arePosterItemsRotating && !isActive)) {
    return null;
  }

  return getRotationControl();
}
