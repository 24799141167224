import {getSingleActiveItemObject} from '@Components/poster-editor/poster-editor-reducer';
import type {SlideshowItem} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import {useAppSelector} from '@/hooks';
import type {SlideItem} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.types';

interface UseGetSlideShowItemWithSelectedSlideObject {
  activeItem: SlideshowItem;
  activeSlide: SlideItem;
}

export function useGetSlideShowItemWithSelectedSlide(): UseGetSlideShowItemWithSelectedSlideObject {
  const singleActiveItem = useAppSelector(getSingleActiveItemObject);
  const activeItem = singleActiveItem ? window.posterEditor?.whiteboard?.getItemForId(singleActiveItem.uid) : null;

  if (!activeItem) {
    throw new Error(`Active item not found`);
  }

  if (!activeItem.isSlideshow()) {
    throw new Error(`Expected the active item to be a slideshow item, instead got ${activeItem?.gitype}`);
  }

  const activeSlide = activeItem.getSelectedSlide();

  return {
    activeItem,
    activeSlide,
  };
}
