import {floor} from 'lodash';

export const SCALE_PRECISION = 3;

export type CornerPointsArray = [Point, Point, Point, Point];

export interface CornerPoints {
  tl: Point;
  tr: Point;
  bl: Point;
  br: Point;
}

export interface Point {
  x: number;
  y: number;
}

export interface Position {
  left: number;
  top: number;
}

export interface Line {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}

export const degreesToRadians = (degrees: number): number => {
  return degrees * (Math.PI / 180);
};

export const radiansToDegrees = (radians: number): number => {
  return radians * (180 / Math.PI);
};

export const distanceBetweenTwoPoints = (x1: number, y1: number, x2: number, y2: number): number => {
  return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
};

export const angleBetweenTwoPoints = (x1: number, y1: number, x2: number, y2: number): number => {
  const dy = y2 - y1;
  const dx = x2 - x1;
  let theta = Math.atan2(dy, dx); // range (-PI, PI]

  theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
  if (theta < 0) {
    theta = 360 + theta; // range [0, 360)
  }
  return theta;
};

export const integerToRoman = (num: number): string => {
  const digits = String(+num).split('');
  const key = [
    '',
    'C',
    'CC',
    'CCC',
    'CD',
    'D',
    'DC',
    'DCC',
    'DCCC',
    'CM',
    '',
    'X',
    'XX',
    'XXX',
    'XL',
    'L',
    'LX',
    'LXX',
    'LXXX',
    'XC',
    '',
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'VIII',
    'IX',
  ];
  let roman_num = '';
  let i = 3;
  while (i--) {
    const digit = digits.pop();
    if (digit) {
      roman_num = (key[+digit + i * 10] || '') + roman_num;
    }
  }
  return Array(+digits.join('') + 1).join('M') + roman_num;
};

export const degToRad = (deg: number): number => {
  return (deg * Math.PI) / 180;
};

/**
 * Returns scale needed to fit old dimension to newer ones
 */
export const getScaleToFit = (newWidth: number, newHeight: number, oldWidth: number, oldHeight: number): number => {
  const widthRatio = newWidth / oldWidth;
  const heightRatio = newHeight / oldHeight;
  let scale;

  if (oldWidth > oldHeight) {
    if (Math.round(oldWidth * widthRatio) <= newWidth && Math.round(oldHeight * widthRatio) <= newHeight) {
      scale = widthRatio;
    } else if (Math.round(oldWidth * heightRatio) <= newWidth && Math.round(oldHeight * heightRatio) <= newHeight) {
      scale = heightRatio;
    } else {
      scale = widthRatio;
    }
  } else if (Math.round(oldWidth * heightRatio) <= newWidth && Math.round(oldHeight * heightRatio) <= newHeight) {
    scale = heightRatio;
  } else if (Math.round(oldWidth * widthRatio) <= newWidth && Math.round(oldHeight * widthRatio) <= newHeight) {
    scale = widthRatio;
  } else {
    scale = heightRatio;
  }

  return floor(scale, SCALE_PRECISION);
};

/**
 * Calculates the intersection area of two rectangles.
 * The first rectangle is divided into smaller rectangles and their intersection with the second rectangle is checked.
 * The area of smaller intersecting rectangles is added to estimate the total area of intersection.
 * The second rectangle can be rotated.
 * IMPORTANT: The first rectangle is assumed to have no rotation.
 * @param rect1 an array of connected points [{x:, y: }, {x:, y:},...] that form the first rectangle.
 * @param rect2 an array of connected points [{x:, y: }, {x:, y:},...] that form the second rectangle.
 * @param divisions Number of parts the first rectangle is divided into (default value = 100).
 * @returns {number}
 */
export const getCommonAreaOfRectangles = (rect1: CornerPoints, rect2: CornerPoints, divisions = 100): number => {
  const rect1Width = rect1.bl.x;
  const rect1Height = rect1.bl.y;
  const deltaY = rect1Height / divisions; // change in height
  const deltaX = rect1Width / divisions; // change in width
  const boxArea = deltaY * deltaX; // the smaller rectangles formed by dividing rect1
  let intersectionArea = 0;
  let i;
  let j;

  for (i = 0; i < rect1Height; i += deltaY) {
    for (j = 0; j < rect1Width; j += deltaX) {
      const coordinates = [
        {
          // top left
          x: j,
          y: i,
        },
        {
          // top right
          x: j + deltaX,
          y: i,
        },
        {
          // bottom right
          x: j + deltaX,
          y: i + deltaY,
        },
        {
          // bottom left
          x: j,
          y: i + deltaY,
        },
      ];
      intersectionArea = doPolygonsIntersect(coordinates, [rect2.tl, rect2.tr, rect2.bl, rect2.br]) ? intersectionArea + boxArea : intersectionArea;
    }
  }
  return intersectionArea;
};

export const secondsToMicroSeconds = (secs: number): number => {
  return Math.round(secs * 10 ** 6);
};

/**
 * Helper function to determine whether there is an intersection between the two polygons described
 * by the lists of vertices. Uses the Separating Axis Theorem
 *
 * @param {Array} a an array of connected points [{x:, y:}, {x:, y:},...] that form a closed polygon
 * @param {Array} b an array of connected points [{x:, y:}, {x:, y:},...] that form a closed polygon
 * @return {boolean} true if there is any intersection between the 2 polygons, false otherwise
 */
export const doPolygonsIntersect = (a: Point[], b: Point[]): boolean => {
  const polygons = [a, b];
  let minA;
  let maxA;
  let projected;
  let i;
  let i1;
  let j;
  let minB;
  let maxB;

  for (i = 0; i < polygons.length; i++) {
    // for each polygon, look at each edge of the polygon, and determine if it separates
    // the two shapes
    const polygon = polygons[i];
    for (i1 = 0; i1 < polygon.length; i1++) {
      // grab 2 vertices to create an edge
      const i2 = (i1 + 1) % polygon.length;
      const p1 = polygon[i1];
      const p2 = polygon[i2];

      // find the line perpendicular to this edge
      const normal = {x: p2.y - p1.y, y: p1.x - p2.x};

      minA = undefined;
      maxA = undefined;
      // for each vertex in the first shape, project it onto the line perpendicular to the edge
      // and keep track of the min and max of these values
      for (j = 0; j < a.length; j++) {
        projected = normal.x * a[j].x + normal.y * a[j].y;
        if (typeof minA === 'undefined' || projected < minA) {
          minA = projected;
        }
        if (typeof maxA === 'undefined' || projected > maxA) {
          maxA = projected;
        }
      }

      // for each vertex in the second shape, project it onto the line perpendicular to the edge
      // and keep track of the min and max of these values
      minB = undefined;
      maxB = undefined;
      for (j = 0; j < b.length; j++) {
        projected = normal.x * b[j].x + normal.y * b[j].y;
        if (typeof minB === 'undefined' || projected < minB) {
          minB = projected;
        }
        if (typeof maxB === 'undefined' || projected > maxB) {
          maxB = projected;
        }
      }

      // if there is no overlap between the projects, the edge we are looking at separates the two
      // polygons, and we know there is no overlap
      if (typeof minA !== 'undefined' && typeof maxA !== 'undefined' && typeof minB !== 'undefined' && typeof maxB !== 'undefined' && (maxA < minB || maxB < minA)) {
        return false;
      }
    }
  }
  return true;
};

export const inchToPixel = (inches: number): number => {
  return 96 * inches;
};

export const pixelToInch = (pixels: number): number => {
  return pixels / 96;
};

export const inchToMillimeter = (inch: number): number => {
  return inch * 25.4;
};

export const inchToCentimeter = (inch: number): number => {
  return inch * 2.54;
};

export const inchToFeet = (inch: number): number => {
  return inch * 0.0833333;
};

export const millimeterToInch = (mm: number): number => {
  return mm / 25.4;
};

export const feetToInch = (ft: number): number => {
  return ft / 0.0833333;
};

export const centimeterToInch = (cm: number): number => {
  return cm / 2.54;
};

export const getRandomNumberInRange = (start: number, end: number): number => {
  if (start <= end) {
    return Math.floor(Math.random() * end) + start;
  }
  throw new Error('Invalid bounds passed');
};

export const sum = (a: number, b: number): number => {
  return a + b;
};

/**
 * Calculates the average of multiple vectors (x, y values)
 */
export const getVectorAvg = (vectors: Point[]): Point => {
  return {
    x:
      vectors
        .map((v) => {
          return v.x;
        })
        .reduce(sum) / vectors.length,
    y:
      vectors
        .map((v) => {
          return v.y;
        })
        .reduce(sum) / vectors.length,
  };
};

export const getDistanceBetweenPoints = (a: Point, b: Point): number => {
  const x = a.x - b.x;
  const y = a.y - b.y;
  return Math.sqrt(x * x + y * y);
};

export const getScaleForFitToContainer = (objectWidth: number, objectHeight: number, containerWidth: number, containerHeight: number): number => {
  let scale;

  const widthRatio = objectWidth / containerWidth;
  const heightRatio = objectHeight / containerHeight;

  if (containerWidth > containerHeight) {
    if (Math.round(containerWidth * widthRatio) <= objectWidth && Math.round(containerHeight * widthRatio) <= objectHeight) {
      scale = widthRatio;
    } else if (Math.round(containerWidth * heightRatio) <= objectWidth && Math.round(containerHeight * heightRatio) <= objectHeight) {
      scale = heightRatio;
    } else {
      scale = widthRatio;
    }
  } else if (Math.round(containerWidth * heightRatio) <= objectWidth && Math.round(containerHeight * heightRatio) <= objectHeight) {
    scale = heightRatio;
  } else if (Math.round(containerWidth * widthRatio) <= objectWidth && Math.round(containerHeight * widthRatio) <= objectHeight) {
    scale = widthRatio;
  } else {
    scale = heightRatio;
  }

  return ceilPrecision(scale, SCALE_PRECISION);
};

export const ceilPrecision = (val: number, precision: number): number => {
  const precisionToUse = typeof precision !== 'undefined' ? precision : 0;
  const padding = 10 ** precisionToUse;
  return Math.ceil(val * padding) / padding;
};

export const roundPrecision = (val: number, precision: number): number => {
  const precisionToUse = typeof precision !== 'undefined' ? precision : 0;
  const padding = 10 ** precisionToUse;
  return Math.round(val * padding) / padding;
};

export const mapNumberToRange = (input: number, inputStart: number, inputEnd: number, outputStart: number, outputEnd: number): number => {
  const s = (outputEnd - outputStart) / (inputEnd - inputStart);
  return outputStart + s * (input - inputStart);
};

export const scaleDimensionsByMaxArea = (width: number, height: number, maxArea: number): {width: number; height: number} => {
  const area = height * width;
  if (area > maxArea) {
    const ratio = width / height;
    const newHeight = Math.sqrt(maxArea / ratio);
    const newWidth = maxArea / newHeight;

    return {
      width: newWidth,
      height: newHeight,
    };
  }
  return {
    width,
    height,
  };
};

export const secondsToMicroseconds = (seconds: number): number => {
  return parseFloat(String(seconds)) * 1000000;
};

export const secondsToMilliseconds = (seconds: number): number => {
  return parseFloat(String(seconds)) * 1000;
};

export const calculateAdjustedModulus = (numerator: number, denominator: number): number => {
  return ((numerator * 10) % (denominator * 10)) / 10;
};
