import type {CSSProperties, ReactElement} from 'react';
import {useState} from 'react';
import {useRef} from 'react';
import React, {useEffect} from 'react';
import type {BaseCustomItemControlProps} from './base-custom-item-control.types';
import {ControlType} from './base-custom-item-control.types';
import {CUSTOM_CONTROL_MOBILE_TOUCH_AREA} from './base-custom-item-control.types';
import styles from './base-custom-item-control.module.scss';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize} from '@Components/icon-v2/icon.types';
import {Text, TextSize} from '@Components/text';
import {setCanvasCursor} from '@Utils/fabric.util';
import type {Canvas} from '@postermywall/fabricjs-2';
import {CUSTOM_CONTROLS_WRAPPER, CustomControlCursor} from '@Components/poster-editor/components/custom-item-controls/custom-item-controls.types';
import {useAppSelector} from '@/hooks';
import {ClickableDiv} from '@Components/clickable-div';

export function BaseCustomItemControl({
  cursor = CustomControlCursor.CURSOR_POINTER,
  borderRadius = '10px',
  isDisabled = false,
  adjustForPosterScroll = true,
  controlType = ControlType.DRAG,
  ...props
}: BaseCustomItemControlProps): ReactElement {
  const verticalScroll = useAppSelector((state) => {
    return state.posterEditor.scrollState.verticalScroll;
  });
  const horizontalScroll = useAppSelector((state) => {
    return state.posterEditor.scrollState.horizontalScroll;
  });
  const isMobileVariant = useAppSelector((state) => {
    return state.posterEditor.isMobileVariant;
  });
  const isActive = useRef(false);
  const [isDragging, setIsDragging] = useState(false);

  const onPointerDown = (e: React.PointerEvent): void => {
    e.preventDefault();

    if (isDragging || isDisabled) {
      return;
    }

    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    isActive.current = true;

    setIsDragging(true);

    if (!isMobileVariant) {
      document.body.style.cursor = cursor;
      setCanvasCursor(currentPage.fabricCanvas as Canvas, cursor);
      (currentPage.fabricCanvas as Canvas).setCursor(cursor);
    }

    props.onPointerDown(e);
  };

  const onPointerUp = (): void => {
    isActive.current = false;

    setIsDragging(false);

    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    if (!isMobileVariant) {
      document.body.style.cursor = '';
      setCanvasCursor(currentPage.fabricCanvas as Canvas, 'default');
      (currentPage.fabricCanvas as Canvas).setCursor('default');
    }
  };

  const getCursorClassForHover = (): string => {
    switch (cursor) {
      case CustomControlCursor.CURSOR_NESW_RESIZE:
        return styles.cursorNESW;
      case CustomControlCursor.CURSOR_EW_RESIZE:
        return styles.cursorEW;
      case CustomControlCursor.CURSOR_NWSE_RESIZE:
        return styles.cursorNWSE;
      case CustomControlCursor.CURSOR_NS_RESIZE:
        return styles.cursorNS;
      case CustomControlCursor.CURSOR_CROSSHAIR:
        return styles.cursorCrosshair;
      default:
        return '_cursor-pointer';
    }
  };

  const getBaseClasses = (): string => {
    return `${props.controlId} ${isMobileVariant && controlType !== ControlType.CLICK ? styles.noPointerEvents : ''} ${styles.baseControl} ${isDisabled ? `_cursor-not-allowed ${styles.disabled}` : ''} ${getCursorClassForHover()}`;
  };

  const getTop = (): number => {
    if (adjustForPosterScroll) {
      return props.top + verticalScroll;
    }

    return props.top;
  };

  const getLeft = (): number => {
    if (adjustForPosterScroll) {
      return props.left + horizontalScroll;
    }

    return props.left;
  };

  const getStylesForLargeScreen = (): CSSProperties => {
    return {
      visibility: shouldShow() ? 'visible' : 'hidden',
      rotate: `${props.angle}deg`,
      top: `${getTop()}px`,
      left: `${getLeft()}px`,
      width: props.width ? `${props.width}px` : 'fit-content',
      height: props.height ? `${props.height}px` : 'fit-content',
      borderRadius: borderRadius,
    };
  };

  const getClickableBaseCustomControlWithText = (icon: string, text: string): ReactElement => {
    return (
      <ClickableDiv
        key={props.controlId}
        id={props.controlId}
        onClick={(e) => {
          props.onPointerDown(e);
        }}
        style={getStylesForLargeScreen()}
        className={`${getBaseClasses()} spacing-p-t-2 spacing-p-b-2 spacing-p-l-3 spacing-p-r-3 flexbox flex-items-center ${styles.gap4}`}
      >
        <Icon
          isSelected
          isDisabled={isDisabled}
          id={props.controlId}
          icon={icon}
          size={IconSize.SIZE_ICON_16}
          className={`${getCursorClassForHover()} spacing-p-0 ${isDisabled ? '_cursor-not-allowed' : ''} flex-1 ${styles.icon} ${styles.noPointerEvents}`}
        />
        <Text bold size={TextSize.XSMALL} className={`${getCursorClassForHover()} flex-1  ${styles.noPointerEvents}`} val={text} />
      </ClickableDiv>
    );
  };

  const getDraggableBaseCustomControl = (): ReactElement => {
    if (isMobileVariant) {
      return getBaseCustomControlForSmallScreen();
    }

    return getBaseCustomControlForLargeScreen();
  };

  const getBaseCustomControlForLargeScreen = (): ReactElement => {
    return (
      <div key={props.controlId} id={props.controlId} onPointerDown={onPointerDown} style={getStylesForLargeScreen()} className={`${getBaseClasses()}  flex-center`}>
        {props.icon ? (
          <Icon
            isDisabled={isDisabled}
            isSelected
            id={props.controlId}
            icon={props.icon}
            size={IconSize.SIZE_ICON_16}
            shape={IconShape.CIRCLE}
            className={`spacing-p-0 ${isDisabled ? '_cursor-not-allowed' : ''}  ${styles.icon} ${styles.noPointerEvents}`}
          />
        ) : null}
      </div>
    );
  };

  const getClickableBaseCustomControlForLargeScreen = (): ReactElement => {
    return (
      <div
        key={props.controlId}
        id={props.controlId}
        onClick={(e) => {
          props.onPointerDown(e);
        }}
        style={getStylesForLargeScreen()}
        className={`${getBaseClasses()}  flex-center`}
      >
        {props.icon ? (
          <Icon
            isDisabled={isDisabled}
            isSelected
            id={props.controlId}
            icon={props.icon}
            size={IconSize.SIZE_ICON_16}
            shape={IconShape.CIRCLE}
            className={`spacing-p-0 ${isDisabled ? '_cursor-not-allowed' : ''}  ${styles.icon} ${styles.noPointerEvents}`}
          />
        ) : null}
      </div>
    );
  };

  const shouldShow = (): boolean => {
    const controlsWrapper = document.getElementById(CUSTOM_CONTROLS_WRAPPER);
    if (!controlsWrapper) {
      return false;
    }

    const wrapperVisibility = getComputedStyle(controlsWrapper).visibility === 'visible';
    return wrapperVisibility && props.isVisible;
  };

  const getBaseCustomControlForSmallScreen = (): ReactElement => {
    return (
      <div
        key={props.controlId}
        id={props.controlId}
        className={styles.touchWrapper}
        style={{
          visibility: shouldShow() ? 'visible' : 'hidden',
          top: `${getTop() - CUSTOM_CONTROL_MOBILE_TOUCH_AREA / 2}px`,
          left: `${getLeft() - CUSTOM_CONTROL_MOBILE_TOUCH_AREA / 2}px`,
          rotate: `${props.angle}deg`,
          width: props.width ? `${props.width + CUSTOM_CONTROL_MOBILE_TOUCH_AREA}px` : 'fit-content',
          height: props.height ? `${props.height + CUSTOM_CONTROL_MOBILE_TOUCH_AREA}px` : 'fit-content',
          borderRadius: borderRadius,
        }}
        onPointerDown={onPointerDown}
        onPointerUp={() => {
          isActive.current = false;
          document.body.style.pointerEvents = ''; // Re-enable clicks
        }}
      >
        <div
          style={{
            top: `${CUSTOM_CONTROL_MOBILE_TOUCH_AREA / 2}px`,
            left: `${CUSTOM_CONTROL_MOBILE_TOUCH_AREA / 2}px`,
            width: props.width ? `${props.width}px` : 'fit-content',
            height: props.height ? `${props.height}px` : 'fit-content',
            borderRadius: borderRadius,
          }}
          className={`${getBaseClasses()} flex-center ${isActive.current ? styles.active : ''}`}
        >
          {props.icon ? (
            <Icon
              isDisabled={isDisabled}
              isSelected
              id={props.controlId}
              icon={props.icon}
              size={IconSize.SIZE_ICON_16}
              shape={IconShape.CIRCLE}
              className={`spacing-p-0 ${isDisabled ? '_cursor-not-allowed' : ''}  ${styles.icon} ${styles.noPointerEvents}`}
            />
          ) : null}
        </div>
      </div>
    );
  };

  const getClickableBaseCustomControlForSmallScreen = (): ReactElement => {
    return (
      <div
        key={props.controlId}
        id={props.controlId}
        className={styles.touchWrapper}
        style={{
          visibility: shouldShow() ? 'visible' : 'hidden',
          top: `${getTop() - CUSTOM_CONTROL_MOBILE_TOUCH_AREA / 2}px`,
          left: `${getLeft() - CUSTOM_CONTROL_MOBILE_TOUCH_AREA / 2}px`,
          rotate: `${props.angle}deg`,
          width: props.width ? `${props.width + CUSTOM_CONTROL_MOBILE_TOUCH_AREA}px` : 'fit-content',
          height: props.height ? `${props.height + CUSTOM_CONTROL_MOBILE_TOUCH_AREA}px` : 'fit-content',
          borderRadius: borderRadius,
        }}
        onClick={(e) => {
          props.onPointerDown(e);
        }}
      >
        <div
          style={{
            top: `${CUSTOM_CONTROL_MOBILE_TOUCH_AREA / 2}px`,
            left: `${CUSTOM_CONTROL_MOBILE_TOUCH_AREA / 2}px`,
            width: props.width ? `${props.width}px` : 'fit-content',
            height: props.height ? `${props.height}px` : 'fit-content',
            borderRadius: borderRadius,
          }}
          className={`${getBaseClasses()} flex-center ${isActive.current ? styles.active : ''}`}
        >
          {props.icon ? (
            <Icon
              isDisabled={isDisabled}
              isSelected
              id={props.controlId}
              icon={props.icon}
              size={IconSize.SIZE_ICON_16}
              shape={IconShape.CIRCLE}
              className={`spacing-p-0 ${isDisabled ? '_cursor-not-allowed' : ''}  ${styles.icon} ${styles.noPointerEvents}`}
            />
          ) : null}
        </div>
      </div>
    );
  };

  const getClickableBaseCustomControl = (): ReactElement => {
    if (isMobileVariant) {
      return getClickableBaseCustomControlForSmallScreen();
    }

    return getClickableBaseCustomControlForLargeScreen();
  };

  const getBaseCustomControl = (): ReactElement => {
    if (controlType === ControlType.CLICK) {
      if (props.text && props.icon) {
        return getClickableBaseCustomControlWithText(props.icon, props.text);
      }

      return getClickableBaseCustomControl();
    }

    return getDraggableBaseCustomControl();
  };

  useEffect(() => {
    if (controlType === ControlType.DRAG) {
      window.addEventListener('pointerup', onPointerUp);
    }

    return (): void => {
      if (controlType === ControlType.DRAG) {
        window.removeEventListener('pointerup', onPointerUp);
      }
    };
  }, []);

  return (
    <>
      {/* Invisible Overlay - Blocks Clicks While Dragging */}
      {isDragging && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'transparent',
            zIndex: 9999,
            pointerEvents: 'none',
          }}
          onPointerDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }} // to stop new pointer events (such as clicks) while dragging --> needed for mobile
          onPointerUp={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }} // to stop new pointer events (such as clicks) while dragging --> needed for mobile
        />
      )}

      {getBaseCustomControl()}
    </>
  );
}
