import type {ReactElement} from 'react';
import React from 'react';
// CodeReviewTaimur: 3 level components are against our standards. server/react/src/components/poster-editor/components/custom-item-controls/components/ shouldn't exist
import {CustomScaledDimensionsInfoBox} from '@Components/poster-editor/components/custom-item-controls/components/custom-scaled-dimensions-info-box/custom-scaled-dimensions-info-box';
import {CustomItemUnlockControl} from '@Components/poster-editor/components/custom-item-unlock-control/custom-item-unlock-control';
import {CustomRotationControl} from '@Components/poster-editor/components/custom-rotation-control/custom-rotation-control';
import {CustomItemControlTl} from '@Components/poster-editor/components/custom-item-control-tl/custom-item-control-tl';
import {CustomItemControlBl} from '@Components/poster-editor/components/custom-item-control-bl/custom-item-control-bl';
import {CustomItemControlTr} from '@Components/poster-editor/components/custom-item-control-tr/custom-item-control-tr';
import {CustomItemControlBr} from '@Components/poster-editor/components/custom-item-control-br/custom-item-control-br';

export function ActiveSelectionCustomControls(): ReactElement {
  return (
    <>
      <CustomItemUnlockControl />
      <CustomRotationControl />

      <CustomItemControlTl />
      <CustomItemControlBl />
      <CustomItemControlTr />
      <CustomItemControlBr />
      <CustomScaledDimensionsInfoBox />
    </>
  );
}
