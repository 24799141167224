import type {PointerEvent, ReactElement} from 'react';
import {useRef} from 'react';
import {useState} from 'react';
import {useEffect} from 'react';
import React from 'react';
import {CUSTOM_MIDDLE_TOP_CONTROL} from './custom-item-control-mt.types';
import {
  defaultOnSingleItemResizeHandleDragEnded,
  doesSelectedItemHaveCustomTopBottomHandlesResizing,
  doesSelectedItemHaveTopBottomResizeControls,
  getDeltaHeightForMiddleControl,
} from '@PosterWhiteboard/libraries/custom-item-controls.library';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import {degreesToRadians} from '@Utils/math.util';
import type {TextItem} from '@PosterWhiteboard/items/text-item/text-item.class';
import type {SlideshowItem} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import {SLIDESHOW_MIN_SCALED_DIMENSION} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.types';
import {
  CUSTOM_ITEM_CONTROL_BORDER,
  CUSTOM_ITEM_DIMENSIONS_CONTROL_LONGER_SIDE,
  CUSTOM_ITEM_DIMENSIONS_CONTROL_SHORTER_SIDE,
  CustomControlCursor,
} from '@Components/poster-editor/components/custom-item-controls/custom-item-controls.types';
import type {CustomItemControlPositions} from '@Components/poster-editor/components/custom-item-controls/components/single-item-custom-controls/single-item-custom-controls.types';
import {useSingleActivePosterItem} from '@Hooks/poster-editor/useSingleActivePosterItem';
import {BaseCustomItemControl} from '@Components/poster-editor/components/custom-item-controls/components/base-custom-item-control/base-custom-item-control';
import {useAppSelector} from '@/hooks';
import {CUSTOM_ITEM_OUTLINE_THICKNESS} from '@Components/poster-editor/components/custom-item-controls/components/custom-outline-boxes/custom-outline-boxes.types';

export function CustomItemControlMt(): ReactElement | null {
  const activeItem = useSingleActivePosterItem();

  const arePosterItemsMoving = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsMoving;
  });
  const arePosterItemsScaling = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsScaling;
  });
  const arePosterItemsRotating = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsRotating;
  });
  const [isActive, setIsActive] = useState(false);
  const initialClientX = useRef<number | null>(null);
  const initialClientY = useRef<number | null>(null);

  const handlePointerMove = (e: globalThis.PointerEvent): void => {
    if (!isActive) {
      return;
    }

    onItemResizedWithTopHandle(e);
  };

  const handlePointerUp = (): void => {
    if (!activeItem) {
      return;
    }

    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    if (!isActive) {
      return;
    }

    setIsActive(false);

    currentPage.poster.redux.setPosterItemsModificationState(false);

    if (doesSelectedItemHaveCustomTopBottomHandlesResizing(activeItem)) {
      customOnItemTopHandleDragEnded();
    } else {
      void defaultOnSingleItemResizeHandleDragEnded(activeItem);
    }

    initialClientX.current = null;
    initialClientY.current = null;
  };

  useEffect(() => {
    if (isActive) {
      window.addEventListener('pointerup', handlePointerUp);
      window.addEventListener('pointermove', handlePointerMove);
    }

    return (): void => {
      window.removeEventListener('pointermove', handlePointerMove);
      window.removeEventListener('pointerup', handlePointerUp);
    };
  }, [isActive]);

  if (!activeItem) {
    return null;
  }

  if (!doesSelectedItemHaveTopBottomResizeControls(activeItem)) {
    return null;
  }

  const handlePointerDown = (e: PointerEvent): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    const target = e.target as HTMLElement;
    if (target.id === CUSTOM_MIDDLE_TOP_CONTROL) {
      setIsActive(true);

      initialClientX.current = e.clientX;
      initialClientY.current = e.clientY;

      currentPage.poster.redux.setPosterItemsModificationState('scaling');
    }
  };

  const defaultOnItemTopHandleDragged = (e: globalThis.PointerEvent, deltaH: number): void => {
    const originalScaledHeight = activeItem.getScaledHeight();
    const changedScaledHeight = originalScaledHeight - deltaH;
    if (changedScaledHeight <= 0) {
      return;
    }

    const newScaleY = changedScaledHeight / activeItem.fabricObject.height;

    activeItem.fabricObject.set({
      scaleY: newScaleY,
      left: activeItem.fabricObject.getX() + deltaH * Math.cos(degreesToRadians(activeItem.fabricObject.angle + 90)),
      top: activeItem.fabricObject.getY() + deltaH * Math.sin(degreesToRadians(activeItem.fabricObject.angle + 90)),
    });

    activeItem.onScaling();

    updateInitialRefs(e);
    refreshFabricObject();
  };

  const customOnItemTopHandleDragged = (e: globalThis.PointerEvent, deltaW: number): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    switch (activeItem.gitype) {
      case ITEM_TYPE.TEXT:
        onTextItemTopHandleDragged(e, deltaW, activeItem as TextItem);
        break;
      case ITEM_TYPE.SLIDESHOW:
        onSlideshowItemTopHandleDragged(e, deltaW, activeItem as SlideshowItem);
        break;
      default:
        throw new Error(`Unhandled ITEM_TYPE ${activeItem.gitype} in customOnItemTopHandleDragged`);
    }

    currentPage.poster.redux.updateReduxItemData(currentPage.hashedID, activeItem.uid, {
      x: activeItem.fabricObject.getX(),
      y: activeItem.fabricObject.getY(),
      scaleX: activeItem.fabricObject.getObjectScaling().x,
      scaleY: activeItem.fabricObject.getObjectScaling().y,
    });
  };

  const onTextItemTopHandleDragged = (e: globalThis.PointerEvent, deltaH: number, selectedTextGraphicItem: TextItem): void => {
    const newHeight = selectedTextGraphicItem.fabricObject.height - deltaH / selectedTextGraphicItem.fabricObject.scaleY;
    if (newHeight < selectedTextGraphicItem.getMinHeight()) {
      return;
    }

    selectedTextGraphicItem.fabricObject.set({
      height: newHeight,
      left: selectedTextGraphicItem.fabricObject.left - deltaH * Math.sin(degreesToRadians(selectedTextGraphicItem.fabricObject.angle)),
      top: selectedTextGraphicItem.fabricObject.top + deltaH * Math.cos(degreesToRadians(selectedTextGraphicItem.fabricObject.angle)),
    });

    selectedTextGraphicItem.onModifyHeight();

    updateInitialRefs(e);
    refreshFabricObject();
  };

  const onSlideshowItemTopHandleDragged = (e: globalThis.PointerEvent, deltaH: number, selectedSlideshowGraphicItem: SlideshowItem): void => {
    const newHeight = selectedSlideshowGraphicItem.fabricObject.height - deltaH / selectedSlideshowGraphicItem.fabricObject.scaleY;
    if (newHeight < selectedSlideshowGraphicItem.getMinHeight() || selectedSlideshowGraphicItem.fabricObject.getScaledHeight() - deltaH < SLIDESHOW_MIN_SCALED_DIMENSION) {
      return;
    }

    selectedSlideshowGraphicItem.fabricObject.set({
      height: newHeight,
      left: selectedSlideshowGraphicItem.fabricObject.left - deltaH * Math.sin(degreesToRadians(selectedSlideshowGraphicItem.fabricObject.angle)),
      top: selectedSlideshowGraphicItem.fabricObject.top + deltaH * Math.cos(degreesToRadians(selectedSlideshowGraphicItem.fabricObject.angle)),
    });

    selectedSlideshowGraphicItem.resizeGroupItemsHeight(newHeight);

    updateInitialRefs(e);
    refreshFabricObject();
  };

  const customOnItemTopHandleDragEnded = (): void => {
    switch (activeItem.gitype) {
      case ITEM_TYPE.TEXT:
        onTextItemTopHandleDragEnded(activeItem as TextItem);
        return;
      case ITEM_TYPE.SLIDESHOW:
        onSlideshowItemTopHandleDragEnded(activeItem as SlideshowItem);
        return;
      default:
        throw new Error(`Unhandled ITEM_TYPE ${activeItem.gitype} in customOnItemTopHandleDragEnded`);
    }
  };

  const onTextItemTopHandleDragEnded = (selectedTextGraphicItem: TextItem): void => {
    void selectedTextGraphicItem.updateFromObject({
      verticalPadding: selectedTextGraphicItem.getVerticalPadding(),
      height: selectedTextGraphicItem.fabricObject.height,
      x: selectedTextGraphicItem.fabricObject.left,
      y: selectedTextGraphicItem.fabricObject.top,
    });
  };

  const onSlideshowItemTopHandleDragEnded = (selectedSlideshowGraphicItem: SlideshowItem): void => {
    void selectedSlideshowGraphicItem.updateFromObject({
      height: selectedSlideshowGraphicItem.fabricObject.height,
      x: selectedSlideshowGraphicItem.fabricObject.left,
      y: selectedSlideshowGraphicItem.fabricObject.top,
    });

    if (selectedSlideshowGraphicItem.slides.hasTextSlide()) {
      for (const slide of Object.values(selectedSlideshowGraphicItem.slides.slidesHashMap)) {
        if (slide.isTextSlide()) {
          void slide.updateFromObject(
            {
              verticalPadding: slide.getVerticalPadding(),
            },
            {undoable: false}
          );
        }
      }
    }
  };

  const updateInitialRefs = (e: globalThis.PointerEvent): void => {
    initialClientX.current = e.clientX;
    initialClientY.current = e.clientY;
  };

  const refreshFabricObject = (): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }
    activeItem.fabricObject.setCoords();

    currentPage.fabricCanvas.requestRenderAll();
  };

  const onItemResizedWithTopHandle = (e: globalThis.PointerEvent): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    if (initialClientX.current === null || initialClientY.current === null) {
      return;
    }

    const angleRadians = degreesToRadians(activeItem.fabricObject.angle);
    const deltaH = getDeltaHeightForMiddleControl(e.clientX, initialClientX.current, e.clientY, initialClientY.current, activeItem.page.poster.scaling.scale, angleRadians);

    if (doesSelectedItemHaveCustomTopBottomHandlesResizing(activeItem)) {
      customOnItemTopHandleDragged(e, deltaH);
    } else {
      defaultOnItemTopHandleDragged(e, deltaH);
    }
  };

  const getCursor = (): CustomControlCursor => {
    let angle = activeItem.fabricObject.getTotalAngle();
    if (angle < 0) {
      angle = angle + 360;
    }

    if (angle >= 0 && angle < 23) {
      return CustomControlCursor.CURSOR_NS_RESIZE;
    }
    if (angle >= 23 && angle < 68) {
      return CustomControlCursor.CURSOR_NESW_RESIZE;
    }
    if (angle >= 68 && angle < 113) {
      return CustomControlCursor.CURSOR_EW_RESIZE;
    }
    if (angle >= 113 && angle < 158) {
      return CustomControlCursor.CURSOR_NWSE_RESIZE;
    }
    if (angle >= 158 && angle < 203) {
      return CustomControlCursor.CURSOR_NS_RESIZE;
    }
    if (angle >= 203 && angle < 248) {
      return CustomControlCursor.CURSOR_NESW_RESIZE;
    }
    if (angle >= 248 && angle < 293) {
      return CustomControlCursor.CURSOR_EW_RESIZE;
    }
    if (angle >= 293 && angle < 338) {
      return CustomControlCursor.CURSOR_NWSE_RESIZE;
    }
    return CustomControlCursor.CURSOR_NS_RESIZE;
  };

  const getMtControlPosition = (): CustomItemControlPositions => {
    const itemWidth = activeItem.fabricObject.getScaledWidth() * activeItem.page.poster.scaling.scale;

    const leftInitial = activeItem.fabricObject.getX() * activeItem.page.poster.scaling.scale;
    const topInitial = activeItem.fabricObject.getY() * activeItem.page.poster.scaling.scale;

    const xCorrectionDueToItemWidth = (itemWidth / 2) * Math.cos(degreesToRadians(activeItem.fabricObject.getTotalAngle()));

    const yCorrectionDueToItemWidth = (itemWidth / 2) * Math.sin(degreesToRadians(activeItem.fabricObject.getTotalAngle()));

    const angle = activeItem.fabricObject.getTotalAngle();
    const left =
      leftInitial +
      xCorrectionDueToItemWidth -
      Math.ceil(CUSTOM_ITEM_DIMENSIONS_CONTROL_LONGER_SIDE / 2) +
      CUSTOM_ITEM_OUTLINE_THICKNESS * Math.sin(degreesToRadians(activeItem.fabricObject.getTotalAngle())) -
      CUSTOM_ITEM_CONTROL_BORDER * Math.sin(degreesToRadians(activeItem.fabricObject.getTotalAngle()));
    const top =
      topInitial +
      yCorrectionDueToItemWidth -
      Math.ceil(CUSTOM_ITEM_DIMENSIONS_CONTROL_SHORTER_SIDE / 2) -
      CUSTOM_ITEM_OUTLINE_THICKNESS * Math.cos(degreesToRadians(activeItem.fabricObject.getTotalAngle())) +
      CUSTOM_ITEM_CONTROL_BORDER * Math.cos(degreesToRadians(activeItem.fabricObject.getTotalAngle()));

    return {left, top, angle};
  };

  if (arePosterItemsMoving || arePosterItemsRotating || (arePosterItemsScaling && !isActive)) {
    return null;
  }

  return (
    <BaseCustomItemControl
      controlId={CUSTOM_MIDDLE_TOP_CONTROL}
      top={getMtControlPosition().top}
      left={getMtControlPosition().left}
      width={CUSTOM_ITEM_DIMENSIONS_CONTROL_LONGER_SIDE}
      height={CUSTOM_ITEM_DIMENSIONS_CONTROL_SHORTER_SIDE}
      angle={getMtControlPosition().angle}
      isVisible={(!arePosterItemsMoving && !arePosterItemsRotating && !arePosterItemsScaling) || (arePosterItemsScaling && isActive)}
      cursor={getCursor()}
      onPointerDown={handlePointerDown}
    />
  );
}
