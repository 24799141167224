import type {ReactElement} from 'react';
import {type PointerEvent, useEffect, useRef, useState} from 'react';
import React from 'react';
import {CUSTOM_MIDDLE_RIGHT_CONTROL} from './custom-item-control-mr.types';
import type {ItemType} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import {degreesToRadians} from '@Utils/math.util';
import {
  defaultOnSingleItemResizeHandleDragEnded,
  doesSelectedItemHaveCustomLeftRightHandlesResizing,
  doesSelectedItemHaveLeftRightResizeControls,
  getDeltaWidthForMiddleControl,
} from '@PosterWhiteboard/libraries/custom-item-controls.library';
import type {TextItem} from '@PosterWhiteboard/items/text-item/text-item.class';
import type {TranscriptItem} from '@PosterWhiteboard/items/transcript-item/transcript-item';
import type {SlideshowItem} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import {SLIDESHOW_MIN_SCALED_DIMENSION} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.types';
import type {MenuItem} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import type {CustomItemControlPositions} from '@Components/poster-editor/components/custom-item-controls/components/single-item-custom-controls/single-item-custom-controls.types';
import {
  CUSTOM_ITEM_CONTROL_BORDER,
  CUSTOM_ITEM_DIMENSIONS_CONTROL_LONGER_SIDE,
  CUSTOM_ITEM_DIMENSIONS_CONTROL_SHORTER_SIDE,
  CustomControlCursor,
} from '@Components/poster-editor/components/custom-item-controls/custom-item-controls.types';
import {useSingleActivePosterItem} from '@Hooks/poster-editor/useSingleActivePosterItem';
import {BaseCustomItemControl} from '@Components/poster-editor/components/custom-item-controls/components/base-custom-item-control/base-custom-item-control';
import {useAppSelector} from '@/hooks';
import {CUSTOM_ITEM_OUTLINE_THICKNESS} from '@Components/poster-editor/components/custom-item-controls/components/custom-outline-boxes/custom-outline-boxes.types';

export function CustomItemControlMr(): ReactElement | null {
  const activeItem = useSingleActivePosterItem();
  const arePosterItemsMoving = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsMoving;
  });
  const arePosterItemsScaling = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsScaling;
  });
  const arePosterItemsRotating = useAppSelector((state) => {
    return state.posterEditor.arePosterItemsRotating;
  });
  const [isActive, setIsActive] = useState(false);
  const initialClientX = useRef<number | null>(null);
  const initialClientY = useRef<number | null>(null);

  const handlePointerMove = (e: globalThis.PointerEvent): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    if (!isActive) {
      return;
    }

    if (initialClientX.current === null) {
      return;
    }

    onItemResizedWithRightHandle(e);
  };

  const handlePointerUp = (): void => {
    if (!activeItem) {
      return;
    }

    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    if (!isActive) {
      return;
    }

    setIsActive(false);

    currentPage.poster.redux.setPosterItemsModificationState(false);

    if (doesSelectedItemHaveCustomLeftRightHandlesResizing(activeItem)) {
      customOnItemRightHandleDragEnded(activeItem);
    } else {
      void defaultOnSingleItemResizeHandleDragEnded(activeItem);
    }

    initialClientX.current = null;
    initialClientY.current = null;
  };

  useEffect(() => {
    if (isActive) {
      window.addEventListener('pointerup', handlePointerUp);
      window.addEventListener('pointermove', handlePointerMove);
    }

    return (): void => {
      window.removeEventListener('pointermove', handlePointerMove);
      window.removeEventListener('pointerup', handlePointerUp);
    };
  }, [isActive]);

  if (!activeItem) {
    return null;
  }

  if (!doesSelectedItemHaveLeftRightResizeControls(activeItem)) {
    return null;
  }

  const handlePointerDown = (e: PointerEvent<HTMLElement>): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    const target = e.target as HTMLElement;
    if (target.id === CUSTOM_MIDDLE_RIGHT_CONTROL) {
      setIsActive(true);

      initialClientX.current = e.clientX;
      initialClientY.current = e.clientY;

      currentPage.poster.redux.setPosterItemsModificationState('scaling');
    }
  };

  const defaultOnItemRightHandleDragged = (e: globalThis.PointerEvent, deltaW: number): void => {
    const originalScaledWidth = activeItem.getScaledWidth();
    const changedScaledWidth = originalScaledWidth + deltaW;
    if (changedScaledWidth <= 0) {
      return;
    }

    const newScaleX = changedScaledWidth / activeItem.fabricObject.width;

    activeItem.fabricObject.set({
      scaleX: newScaleX,
    });

    activeItem.onScaling();

    updateInitialRefs(e);
    refreshFabricObject();
  };

  const customOnItemRightHandleDragged = (e: globalThis.PointerEvent, deltaW: number): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    switch (activeItem.gitype) {
      case ITEM_TYPE.TEXT:
        onTextItemRightHandleDragged(e, deltaW, activeItem as TextItem);
        break;
      case ITEM_TYPE.TRANSCRIPT:
        onTranscriptItemRightHandleDragged(e, deltaW, activeItem as TranscriptItem);
        break;
      case ITEM_TYPE.SLIDESHOW:
        onSlideshowItemRightHandleDragged(e, deltaW, activeItem as SlideshowItem);
        break;
      case ITEM_TYPE.MENU:
        onMenuItemRightHandleDragged(e, deltaW, activeItem as MenuItem);
        break;
      default:
        throw new Error(`Unhandled ITEM_TYPE ${activeItem.gitype} in customOnItemRightHandleDragged`);
    }
  };

  const onMenuItemRightHandleDragged = (e: globalThis.PointerEvent, deltaW: number, selectedMenuGraphicItem: MenuItem): void => {
    const offset = deltaW / selectedMenuGraphicItem.fabricObject.scaleX;
    if (selectedMenuGraphicItem.xSpacing + offset < 0) {
      return;
    }

    void selectedMenuGraphicItem.updateFromObject(
      {
        xSpacing: selectedMenuGraphicItem.xSpacing + offset,
      },
      {undoable: false}
    );

    updateInitialRefs(e);
    refreshFabricObject();
  };

  const onSlideshowItemRightHandleDragged = (e: globalThis.PointerEvent, deltaW: number, selectedSlideshowGraphicItem: SlideshowItem): void => {
    const newWidth = selectedSlideshowGraphicItem.fabricObject.width + deltaW / selectedSlideshowGraphicItem.fabricObject.scaleX;
    if (newWidth < selectedSlideshowGraphicItem.getMinWidth() || selectedSlideshowGraphicItem.fabricObject.getScaledWidth() + deltaW < SLIDESHOW_MIN_SCALED_DIMENSION) {
      return;
    }

    selectedSlideshowGraphicItem.fabricObject.set({width: newWidth});
    selectedSlideshowGraphicItem.resizeGroupItemsWidth(newWidth);

    if (selectedSlideshowGraphicItem.slides.hasTextSlide()) {
      for (const slide of Object.values(selectedSlideshowGraphicItem.slides.slidesHashMap)) {
        if (slide.isTextSlide()) {
          void slide.updateFromObject(
            {
              baseWidth: slide.calculateBaseWidth(),
            },
            {undoable: false}
          );
        }
      }
    } else {
      void selectedSlideshowGraphicItem.updateFromObject(
        {
          width: selectedSlideshowGraphicItem.fabricObject.width,
        },
        {undoable: false}
      );
    }

    updateInitialRefs(e);
    refreshFabricObject();
  };

  const onTranscriptItemRightHandleDragged = (e: globalThis.PointerEvent, deltaW: number, selectedTranscriptGraphicItem: TranscriptItem): void => {
    const newWidth = selectedTranscriptGraphicItem.fabricObject.width + deltaW / selectedTranscriptGraphicItem.fabricObject.scaleX;
    if (newWidth < selectedTranscriptGraphicItem.getSmallestWidthNeededBySubtitleFabricTextBox()) {
      return;
    }

    selectedTranscriptGraphicItem.setTranscriptItemAndFabricGroupWidth(newWidth);

    void selectedTranscriptGraphicItem.updateFromObject(
      {
        width: selectedTranscriptGraphicItem.fabricObject.width,
      },
      {undoable: false}
    );

    updateInitialRefs(e);
    refreshFabricObject();
  };

  const onTextItemRightHandleDragged = (e: globalThis.PointerEvent, deltaW: number, selectedTextGraphicItem: TextItem): void => {
    const newWidth = selectedTextGraphicItem.fabricObject.width + deltaW / selectedTextGraphicItem.fabricObject.scaleX;
    if (newWidth < selectedTextGraphicItem.getMinWidth()) {
      return;
    }

    selectedTextGraphicItem.fabricObject.set({
      width: newWidth,
    });
    selectedTextGraphicItem.onModifyWidth();

    void selectedTextGraphicItem.updateFromObject(
      {
        width: selectedTextGraphicItem.fabricObject.width,
        baseWidth: selectedTextGraphicItem.calculateBaseWidth(),
      },
      {undoable: false}
    );

    updateInitialRefs(e);
    refreshFabricObject();
  };

  const customOnItemRightHandleDragEnded = (selectedGraphicItem: ItemType): void => {
    switch (selectedGraphicItem.gitype) {
      case ITEM_TYPE.TEXT:
        onTextItemRightHandleDragEnded(selectedGraphicItem as TextItem);
        return;
      case ITEM_TYPE.TRANSCRIPT:
        onTranscriptItemRightHandleDragEnded(selectedGraphicItem as TranscriptItem);
        return;
      case ITEM_TYPE.SLIDESHOW:
        onSlideshowItemRightHandleDragEnded(selectedGraphicItem as SlideshowItem);
        return;
      case ITEM_TYPE.MENU:
        onMenuItemRightHandleDragEnded(selectedGraphicItem as MenuItem);
        return;
      default:
        throw new Error(`Unhandled ITEM_TYPE ${selectedGraphicItem.gitype} in customOnItemRightHandleDragEnded`);
    }
  };

  const onMenuItemRightHandleDragEnded = (selectedMenuGraphicItem: MenuItem): void => {
    void selectedMenuGraphicItem.updateFromObject({
      xSpacing: selectedMenuGraphicItem.xSpacing,
    });
  };

  const onSlideshowItemRightHandleDragEnded = (selectedSlideshowGraphicItem: SlideshowItem): void => {
    void selectedSlideshowGraphicItem.updateFromObject({
      width: selectedSlideshowGraphicItem.fabricObject.width,
    });
  };

  const onTranscriptItemRightHandleDragEnded = (selectedTranscriptGraphicItem: TranscriptItem): void => {
    void selectedTranscriptGraphicItem.updateFromObject({
      width: selectedTranscriptGraphicItem.fabricObject.width,
    });
  };

  const onTextItemRightHandleDragEnded = (selectedTextGraphicItem: TextItem): void => {
    void selectedTextGraphicItem.updateFromObject({
      baseWidth: selectedTextGraphicItem.calculateBaseWidth(),
      width: selectedTextGraphicItem.fabricObject.width,
    });
  };

  const updateInitialRefs = (e: globalThis.PointerEvent): void => {
    initialClientX.current = e.clientX;
    initialClientY.current = e.clientY;
  };

  const refreshFabricObject = (): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    activeItem.fabricObject.setCoords();

    currentPage.fabricCanvas.requestRenderAll();
  };

  const onItemResizedWithRightHandle = (e: globalThis.PointerEvent): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }

    if (initialClientX.current === null || initialClientY.current === null) {
      return;
    }

    const angleRadians = degreesToRadians(activeItem.fabricObject.angle);
    const deltaW = getDeltaWidthForMiddleControl(e.clientX, initialClientX.current, e.clientY, initialClientY.current, currentPage.poster.scaling.scale, angleRadians);

    if (doesSelectedItemHaveCustomLeftRightHandlesResizing(activeItem)) {
      customOnItemRightHandleDragged(e, deltaW);
    } else {
      defaultOnItemRightHandleDragged(e, deltaW);
    }
  };

  const getCursor = (): CustomControlCursor => {
    let angle = activeItem.fabricObject.getTotalAngle();
    if (angle < 0) {
      angle = angle + 360;
    }

    if (angle >= 0 && angle < 23) {
      return CustomControlCursor.CURSOR_EW_RESIZE;
    }
    if (angle >= 23 && angle < 68) {
      return CustomControlCursor.CURSOR_NWSE_RESIZE;
    }
    if (angle >= 68 && angle < 113) {
      return CustomControlCursor.CURSOR_NS_RESIZE;
    }
    if (angle >= 113 && angle < 158) {
      return CustomControlCursor.CURSOR_NESW_RESIZE;
    }
    if (angle >= 158 && angle < 203) {
      return CustomControlCursor.CURSOR_EW_RESIZE;
    }
    if (angle >= 203 && angle < 248) {
      return CustomControlCursor.CURSOR_NWSE_RESIZE;
    }
    if (angle >= 248 && angle < 293) {
      return CustomControlCursor.CURSOR_NS_RESIZE;
    }
    if (angle >= 293 && angle < 338) {
      return CustomControlCursor.CURSOR_NESW_RESIZE;
    }
    return CustomControlCursor.CURSOR_EW_RESIZE;
  };

  const getMrControlPosition = (): CustomItemControlPositions => {
    const itemHeight = activeItem.fabricObject.getScaledHeight() * activeItem.page.poster.scaling.scale;
    const itemWidth = activeItem.fabricObject.getScaledWidth() * activeItem.page.poster.scaling.scale;

    const leftInitial = activeItem.fabricObject.getX() * activeItem.page.poster.scaling.scale;
    const topInitial = activeItem.fabricObject.getY() * activeItem.page.poster.scaling.scale;

    const xCorrectionDueToItemHeight = (itemHeight / 2) * Math.sin(degreesToRadians(activeItem.fabricObject.getTotalAngle()));
    const xCorrectionDueToItemWidth = itemWidth * Math.cos(degreesToRadians(activeItem.fabricObject.getTotalAngle()));

    const yCorrectionDueToItemHeight = (itemHeight / 2) * Math.cos(degreesToRadians(activeItem.fabricObject.getTotalAngle()));
    const yCorrectionDueToItemWidth = itemWidth * Math.sin(degreesToRadians(activeItem.fabricObject.getTotalAngle()));

    const angle = activeItem.fabricObject.getTotalAngle();
    const left =
      leftInitial -
      xCorrectionDueToItemHeight +
      xCorrectionDueToItemWidth -
      Math.floor(CUSTOM_ITEM_DIMENSIONS_CONTROL_SHORTER_SIDE / 2) +
      CUSTOM_ITEM_OUTLINE_THICKNESS * Math.cos(degreesToRadians(activeItem.fabricObject.getTotalAngle())) -
      CUSTOM_ITEM_CONTROL_BORDER * Math.cos(degreesToRadians(activeItem.fabricObject.getTotalAngle()));
    const top =
      topInitial +
      yCorrectionDueToItemHeight +
      yCorrectionDueToItemWidth -
      Math.floor(CUSTOM_ITEM_DIMENSIONS_CONTROL_LONGER_SIDE / 2) +
      CUSTOM_ITEM_OUTLINE_THICKNESS * Math.sin(degreesToRadians(activeItem.fabricObject.getTotalAngle())) -
      CUSTOM_ITEM_CONTROL_BORDER * Math.sin(degreesToRadians(activeItem.fabricObject.getTotalAngle()));

    return {left, top, angle};
  };

  if (arePosterItemsMoving || arePosterItemsRotating || (arePosterItemsScaling && !isActive)) {
    return null;
  }

  return (
    <BaseCustomItemControl
      controlId={CUSTOM_MIDDLE_RIGHT_CONTROL}
      top={getMrControlPosition().top}
      left={getMrControlPosition().left}
      width={CUSTOM_ITEM_DIMENSIONS_CONTROL_SHORTER_SIDE}
      height={CUSTOM_ITEM_DIMENSIONS_CONTROL_LONGER_SIDE}
      angle={getMrControlPosition().angle}
      isVisible={(!arePosterItemsMoving && !arePosterItemsRotating && !arePosterItemsScaling) || (arePosterItemsScaling && isActive)}
      cursor={getCursor()}
      onPointerDown={handlePointerDown}
    />
  );
}
