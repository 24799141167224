export const CUSTOM_SELECTION_BOX_CLASS = 'custom-selection-box';
export const CUSTOM_MULTI_SELECTION_BOXES_WRAPPER = 'custom-multi-selection-boxes-wrapper';

export interface CustomOutlineBoxPositionAndSize {
  left: number;
  top: number;
  angle: number;
  width: number;
  height: number;
}

export const CUSTOM_ITEM_OUTLINE_THICKNESS = 2;
export const CUSTOM_MULTI_SELECT_OUTLINE_THICKNESS = 3;
