import type {ReactElement} from 'react';
import React from 'react';
import {CUSTOM_UNLOCK_CONTROL_MARGIN} from './custom-item-unlock-control.types';
import {CUSTOM_UNLOCK_CONTROL_ID} from './custom-item-unlock-control.types';
import {useAppSelector} from '@/hooks';
import {getActiveItems} from '@Components/poster-editor/poster-editor-reducer';
import type {FabricObject} from '@postermywall/fabricjs-2';
import {ActiveSelection} from '@postermywall/fabricjs-2';
import type {CustomItemControlPositions} from '@Components/poster-editor/components/custom-item-controls/components/single-item-custom-controls/single-item-custom-controls.types';
import {degreesToRadians} from '@Utils/math.util';
import {CUSTOM_ITEM_CONTROL_WITH_ICON_SIDE, CUSTOM_ITEM_DIMENSIONS_CONTROL_LONGER_SIDE} from '@Components/poster-editor/components/custom-item-controls/custom-item-controls.types';
import {toggleLockStateForSelectedItem} from '@Components/poster-editor/library/poster-editor-library';
import {BaseCustomItemControl} from '@Components/poster-editor/components/custom-item-controls/components/base-custom-item-control/base-custom-item-control';
import {ControlType} from '@Components/poster-editor/components/custom-item-controls/components/base-custom-item-control/base-custom-item-control.types';

export function CustomItemUnlockControl(): ReactElement | null {
  const activeItemsData = useAppSelector(getActiveItems);

  const getCustomUnlockControlPosition = (fabricObject: FabricObject, posterScale: number): CustomItemControlPositions => {
    const itemWidth = (fabricObject.width * fabricObject.getObjectScaling().x + fabricObject.strokeWidth) * posterScale;

    const leftInitial = fabricObject.getX() * posterScale;
    const topInitial = fabricObject.getY() * posterScale;

    const xCorrectionDueToItemWidth = (itemWidth / 2) * Math.cos(degreesToRadians(fabricObject.getTotalAngle()));
    const yCorrectionDueToItemWidth = (itemWidth / 2) * Math.sin(degreesToRadians(fabricObject.getTotalAngle()));

    const x = CUSTOM_UNLOCK_CONTROL_MARGIN * Math.sin(degreesToRadians(fabricObject.getTotalAngle()));
    const y = CUSTOM_UNLOCK_CONTROL_MARGIN * Math.cos(degreesToRadians(fabricObject.getTotalAngle()));

    const angle = fabricObject.getTotalAngle();
    const left = leftInitial + xCorrectionDueToItemWidth - Math.ceil(CUSTOM_ITEM_DIMENSIONS_CONTROL_LONGER_SIDE / 2 - x);
    const top = topInitial + yCorrectionDueToItemWidth - Math.ceil(CUSTOM_ITEM_DIMENSIONS_CONTROL_LONGER_SIDE / 2) - y;

    return {left, top, angle};
  };

  const getUnlockControl = (): ReactElement | null => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return null;
    }

    const activeSelection = currentPage.activeSelection.getActiveObject();
    if (!activeSelection || activeSelection instanceof ActiveSelection) {
      return null;
    }

    const item = currentPage.getItemForFabricObject(activeSelection);
    if (!item) {
      return null;
    }

    if (!item.isLocked()) {
      return null;
    }

    const positionAndSize = getCustomUnlockControlPosition(activeSelection, currentPage.poster.scaling.scale);

    return (
      <BaseCustomItemControl
        icon={'icon-lock'}
        controlId={CUSTOM_UNLOCK_CONTROL_ID}
        top={positionAndSize.top}
        left={positionAndSize.left}
        width={CUSTOM_ITEM_CONTROL_WITH_ICON_SIDE}
        height={CUSTOM_ITEM_CONTROL_WITH_ICON_SIDE}
        angle={positionAndSize.angle}
        borderRadius={'50%'}
        isVisible
        onPointerDown={toggleLockStateForSelectedItem}
        controlType={ControlType.CLICK}
      />
    );
  };

  if (!activeItemsData || activeItemsData.length === 0) {
    return null;
  }

  return getUnlockControl();
}
